import React, {useContext,useState} from 'react';

import {MyContext} from '../../contexts/MyContext';

// Importing the Login & Register Componet
import Register from './Register';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

 function Puntos_de_juegos(props) {

 //Modal puntos ganador
const [modalPuntosGanador, setModalPuntosGanador]=useState(false);

//Modal puntos ganador

const abrirCerrarModalPuntosGanador=()=>{
      setModalPuntosGanador(!modalPuntosGanador);
}


 //Modal empate juego
 const [modalEmpateJuego, setModalEmpateJuego]=useState(false);

 //Modal empate juego
 
 const abrirCerrarModalEmpateJuego=()=>{
       setModalEmpateJuego(!modalEmpateJuego);
 }


 //Modal perdedor juego
 const [modalPerdedorJuego, setModalPerdedorJuego]=useState(false);

 //Modal perdedor juego
 
 const abrirCerrarModalPerdedorJuego=()=>{
      setModalPerdedorJuego(!modalPerdedorJuego);
}


//Modal ganador juego w
const [modalGanadorJuegoW, setModalGanadorJuegoW]=useState(false);

//Modal ganador juego w

const abrirCerrarModalGanadorJuegoW=()=>{
     setModalGanadorJuegoW(!modalGanadorJuegoW);
}


//Modal goles juego w
const [modalGolesJuegoW, setModalGolesJuegoW]=useState(false);

//Modal goles juego w

const abrirCerrarModalGolesJuegoW=()=>{
     setModalGolesJuegoW(!modalGolesJuegoW);
}


//Modal contra juego w
const [modalContraJuegoW, setModalContraJuegoW]=useState(false);

//Modal contra juego w

const abrirCerrarModalContraJuegoW=()=>{
     setModalContraJuegoW(!modalContraJuegoW);
}


//Modal contra juego w2
const [modalContraJuegoW2, setModalContraJuegoW2]=useState(false);

//Modal contra juego w2

const abrirCerrarModalContraJuegoW2=()=>{
     setModalContraJuegoW2(!modalContraJuegoW2);
}
    

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;



    if(isAuth)
    {

    return (
        
        
        
            
              <div className="col-sm-12">
                  
    
              <div class="card card-primary">
                  
                  <div class="card-header">
                    <h3 class="card-title">PUNTOS DE JUEGO - PUNTOS POR W</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    


      <div class="row">
        <center> <h5><b>Puntos Por Juego Para Los Equipos</b></h5> </center> 
     
          <div class="col-sm-3">
                <label>Puntos al Ganador del Juego</label>
                <div class="input-group">
          <select  name="puntos_ganador" class="form-control" value={props.getState('puntos_ganador', '')} onChange={props.handleChange}>
<option value='no' selected >.</option>
      <option value="1" disabled style={{color:'gray'}}>1</option>
      <option value="2" disabled style={{color:'gray'}}>2</option>
      <option value="3" selected>3</option>
      <option value="4" disabled style={{color:'gray'}}>4</option>
      <option value="5" disabled style={{color:'gray'}}>5</option>
      
    </select>  

    <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
                  </span>
     
       </div> 
      </div>
      

     


      <div class="col-sm-3">
      <label>Puntos Por Empate del Juego</label>
      <div class="input-group">
      <select name="puntos_empate" class="form-control" value={props.getState('puntos_empate', '')} onChange={props.handleChange}>
<option value='no' >.</option>
      <option value="0" disabled style={{color:'gray'}}>0</option>
      <option value="1" selected>1</option>
      <option value="2" disabled style={{color:'gray'}}>2</option>
      <option value="3" disabled style={{color:'gray'}}>3</option>
      <option value="4" disabled style={{color:'gray'}}>4</option>
      <option value="5" disabled style={{color:'gray'}}>5</option>
      
    </select>  

    <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
                  </span>
     
       </div> 

      </div>


      <div class="col-sm-3">

      <label>Puntos al Perdedor del Juego</label>
      <div class="input-group">
      <select name="puntos_perdedor" class="form-control" value={props.getState('puntos_perdedor', '')} onChange={props.handleChange}>
<option value='.' selected >.</option>
      <option value="-3" disabled style={{color:'gray'}}>-3</option>
      <option value="-2" disabled style={{color:'gray'}}>-2</option>
      <option value="-1" disabled style={{color:'gray'}}>-1</option>
      <option value="0"  selected>0</option>
      <option value="1"  disabled style={{color:'gray'}}>1</option>
      <option value="2"  disabled style={{color:'gray'}}>2</option>
      <option value="3"  disabled style={{color:'gray'}}>3</option>
      
    </select>  
 
    <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPerdedorJuego()}><b>?</b></button>
                  </span>
     
       </div> 
      </div>


    

      
    </div>
    <hr />
    

    <div class="row">
    <center> <h5><b>Puntos Por Juego Y Goles Para Los Equipos En Caso de W</b></h5> </center> 
     
          <div class="col-sm-4">
          
          <label>Puntos al Ganador del Juego por W</label>
          <div class="input-group">
          <select name="puntos_ganadosw" class="form-control" value={props.getState('puntos_ganadosw', '')} onChange={props.handleChange}>
<option value='no' selected >.</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      
      
    </select>  
    <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalGanadorJuegoW()}><b>?</b></button>
                  </span>
     
       </div> 

      </div>
      

     


      <div class="col-sm-4">
      <label>Goles a Favor al Ganador del Juego por W</label>
      <div class="input-group">
      <select name="goles_ganadosw" class="form-control" value={props.getState('goles_ganadosw', '')} onChange={props.handleChange}>
<option value='no' selected >.</option>
<option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>


      </select> 
      <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalGolesJuegoW()}><b>?</b></button>
                  </span>
     
       </div> 
      </div>

      
      </div>

      <br />
      <div class="row">

      <div class="col-sm-4">
      <label>Puntos En Contra Por Perder el Juego por W</label>
      <div class="input-group">

      <select name="puntos_perdidosw" class="form-control" style={{backgroundColor:"#F9C3C3"}} value={props.getState('puntos_perdidosw', '')} onChange={props.handleChange}>
<option value='no' selected >.</option>
      <option value="0">0</option>
      <option value="-1">-1</option>
      <option value="-2">-2</option>
      <option value="-3">-3</option>
      <option value="-4">-4</option>
      <option value="-5">-5</option>


      </select> 
      <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW()}><b>?</b></button>
                  </span>
     
       </div> 

      
      </div>
      

     


      <div class="col-sm-4">
      <label>Goles En Contra al Perdedor del Juego por W</label>
      <div class="input-group">
      <select name="goles_perdidosw" class="form-control" style={{backgroundColor:"#F9C3C3"}} value={props.getState('goles_perdidosw', '')} onChange={props.handleChange}>
<option value='no' selected >.</option>
      <option value="0">0</option>
      <option value="-1">-1</option>
      <option value="-2">-2</option>
      <option value="-3">-3</option>
      <option value="-4">-4</option>
      <option value="-5">-5</option>


      </select> 

      <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW2()}><b>?</b></button>
                  </span>
     
       </div> 

       </div>

      <div class="col-sm-4">
            <br />
      <button className="btn btn-success rounded-circle float-right"   onClick={props.next}><i class="fas fa-save fa-2x"></i></button>
      

     

     
      
      
      
      </div>
      
     

       </div>

      
    







      

    


  </form>
                  </div>
                 
                </div>
    
    
{/* puntos ganador*/}
<Modal isOpen={modalPuntosGanador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Puntos Otorgados Al Equipo Ganador Del Juego.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPuntosGanador()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* empate juego*/}
<Modal isOpen={modalEmpateJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos Por Empate De Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>(Puntos) Otorgados a Cada Equipo por Empate Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEmpateJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* perdedor juego*/}
<Modal isOpen={modalPerdedorJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Perdedor Del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>(Puntos) Otorgados Al Equipo Perdedor Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPerdedorJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* ganador juego W*/}
<Modal isOpen={modalGanadorJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>(Puntos) Otorgados A Favor Al Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGanadorJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* goles juego W*/}
<Modal isOpen={modalGolesJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles a Favor al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>(Goles) Otorgados A Favor Del Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGolesJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* contra juego W*/}
<Modal isOpen={modalContraJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos En Contra Por Perder el Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>(Puntos) Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* contra juego W2*/}
<Modal isOpen={modalContraJuegoW2}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles En Contra al Perdedor del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>(Goles) Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW2()}>Aceptar</button>
 </ModalFooter>
</Modal>
    
          </div>
    
 


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
        
}
 }

export default Puntos_de_juegos