import React, {useContext,useState} from 'react'
import {MyContext} from '../../contexts/MyContext'

//import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'


function Deportistas(props) {

  const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            apellidos:'',
            email:'',
            telefono:'',
            nick:'',
            password:'',
            nacionalidad:'',
            genero:'',
            pais:'',
            departamento:'',
            ciudad:'',
            fechanacimiento:'',
            tdoc:'',
            documento:'',
            tipo_usuario:'deportista',
            rh:'',
            mas_menos:'',
            sistema_salud:'',
            entidad_salud:'',
            nombre_contacto:'',
            numero_contacto:'',              
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

  

   const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        
          <div className="col-sm-12">



          <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">DEPORTISTAS</h3>
              </div>
            
              <div class="card-body ">
              <form onSubmit={submitForm}>
              <div class="card card-primary col-md-12">
                  <div class="row">
                      
                  <label>REGISTRO BÁSICO</label>


                  <div class="col-sm-3">

                    <div class="form-group">
                    
                    <input name="name" required type="text" className="form-control" value={state.userInfo.name} onChange={onChangeValue} placeholder="Nombres"/>
                  </div>
                  </div>

                  <div class="col-sm-3">

                    <div class="form-group">
                    
                    <input type="text" name="apellidos" class="form-control" id="inputSuccess" value={state.userInfo.apellidos} onChange={onChangeValue} placeholder="Apellidos" />
                  </div>
                  </div>


                  <div class="col-sm-3">
              
              <div class="form-group">

              <input type="email" name="email" className="form-control is-valid" required  value={state.userInfo.email} onChange={onChangeValue} placeholder="Email"/>
                </div>
              </div>

              <div class="col-sm-3">
              
                    <div class="form-group">

                    <input type="number" name="telefono" class="form-control" id="inputSuccess"value={state.userInfo.telefono} onChange={onChangeValue}  placeholder="N° celular" />
                      </div>
                    </div>


                    <div class="col-sm-3">
                    <div class="form-group">
                   
                    <div class="input-group">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                        <label class="custom-file-label" for="exampleInputFile">Adjuntar Foto</label>
                      </div>
                      
                    </div>


                  </div>

                    </div>


                    <div class="col-sm-3">

                    <div class="form-group">
                    
                    <input name="nick" required type="text" className="form-control" value={state.userInfo.nick} onChange={onChangeValue} placeholder="Nick"/>
                  </div>
                  </div>

                        <div class="col-sm-3">
                    
                    <div class="form-group">

                    <input type="password" name="password" class="form-control is-valid" required  value={state.userInfo.password} onChange={onChangeValue} placeholder="Contraseña" />
                        </div>
                    </div>


                <div class="col-sm-3">
                
                <div class="form-group">

                <input type="password" class="form-control is-valid" id="inputSuccess" placeholder="Repetir contraseña" />
                </div>
                </div>

  
                    </div>


                  </div>



                  <div class="card card-primary col-md-12">
                  
                  <div class="row">
                  <label>REGISTRO B</label>

                  
                  <div class="col-sm-3">
              
              <div class="form-group">

              <input type="text" name="nacionalidad" class="form-control" id="inputSuccess"value={state.userInfo.nacionalidad} onChange={onChangeValue}  placeholder="Nacionalidad" />
                </div>
              </div>
                   
                 

              <div class="col-sm-2">
                   
                   <select name="genero" class="form-control" value={state.userInfo.genero} onChange={onChangeValue}>
               <option value='no' selected>Seleccionar Genero</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="Sin definir">Sin definir</option>
               
             </select>     

                    </div>

                    </div>

               <div class="row">

                   <label>Lugar Habitual Donde Practica Deporte</label>
                    <div class="col-sm-2">
              
              <div class="form-group">

          <select name="pais" class="form-control" value={state.userInfo.pais} onChange={onChangeValue}>
         <option value='no' selected>Pais</option>
          <option value="Colombia">Colombia</option>
       </select>     
                </div>
              </div>


              <div class="col-sm-2">
                <div class="form-group">
                  
                <select name="departamento" class="form-control" value={state.userInfo.departamento} onChange={onChangeValue}>
<option value='no' selected>Departamento</option>
              <option value="Tolima">Tolima</option>
              
            </select>  

                </div>
              </div>


                  <div class="col-sm-2">
                
                <div class="form-group">
                <select name="ciudad" class="form-control" value={state.userInfo.ciudad} onChange={onChangeValue}>
<option value='no' selected>Ciudad</option>
              <option value="Ibague">Ibague</option>
              
            </select>  
                </div>
              </div>
            
              



              </div>
                    
                   </div>




                
                   <div class="card card-primary col-md-12">

               <div class="row">
               <label>REGISTRO MEDIO</label>

               <div class="col-sm-3">
                   
                   <input type="date" name="fechanacimiento" class="form-control is-warning" id="inputSuccess" value={state.userInfo.fechanacimiento} onChange={onChangeValue} placeholder="Fecha nacimiento" />
                   <h6>Fecha de nacimiento</h6>
                    </div>

                    <div class="col-sm-2">
                    <select name="tdoc" class="form-control" value={state.userInfo.tdoc} onChange={onChangeValue}>
               <option value='no' selected>T.doc</option>
                <option value="Cedula">Cedula</option>
                <option value="DNI">DNI</option>
                <option value="Tarjeta de identidad">Tarjeta de identidad</option>
             </select>     

                    </div>


                    <div class="col-sm-2">
                    <input type="number" name="documento"  className="form-control" id="inputSuccess" value={state.userInfo.documento} onChange={onChangeValue} placeholder="N° documento" />

                    </div>



                </div>
                </div>




                <div class="card card-primary col-md-12">

                <div class="row">
                <label>REGISTRO AVANZADO</label>

                <div class="col-sm-2">
              
              <div class="form-group">

              <input type="text" name="rh" className="form-control"  value={state.userInfo.rh} onChange={onChangeValue} placeholder="RH"/>
                </div>
              </div>


              <div class="col-sm-2">
              
              <div class="form-group">

              <input type="text" name="mas_menos" className="form-control"  value={state.userInfo.mas_menos} onChange={onChangeValue} placeholder="+/-"/>
                </div>
              </div>


              <div class="col-sm-3">
              
              <div class="form-group">

              <input type="text" name="sistema_salud" className="form-control"  value={state.userInfo.sistema_salud} onChange={onChangeValue} placeholder="Sistema de Salud"/>
                </div>
              </div>


              <div class="col-sm-3">
              
              <div class="form-group">

              <input type="text" name="entidad_salud" className="form-control"  value={state.userInfo.entidad_salud} onChange={onChangeValue} placeholder="Entidad Prestadora de Salud "/>
                </div>
              </div>

              <div class="row">
              <div class="col-sm-3">
              
              <div class="form-group">

              <input type="text" name="nombre_contacto" className="form-control"  value={state.userInfo.nombre_contacto} onChange={onChangeValue} placeholder="Nombre de Contacto  "/>
                </div>
              </div>

              <div class="col-sm-3">
              
              <div class="form-group">

              <input type="number" name="numero_contacto" className="form-control"   value={state.userInfo.numero_contacto} onChange={onChangeValue} placeholder="Número de Contacto  "/>
                </div>
              </div>

              </div>


                </div>
                </div>
                  

                

                  



                    <div class="col-sm-12">

                        
                       
<p><b>{errorMsg}{successMsg}</b></p>

   





 </div>
 
 

 <button className="btn btn-primary" type="submit">Registrar Deportista</button>

                  


                </form>
              </div>
             
            </div>


            

      </div>

      </div>
      </div>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Deportistas
