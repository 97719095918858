import React, {useState,useContext} from 'react';

import {Link} from 'react-router-dom';

import '../../App.css';
import {MyContext} from '../../contexts/MyContext';


function Menu(props) {
  const {rootState,logoutUser} = useContext(MyContext);

  
  const [dropdown, setDropdown]=useState(false);
  const {isAuth,theUser,showLogin} = rootState;

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }
    
  return (
    <nav className="navbar  main-sidebar sidebar-light-primary elevation-4 " >
  
  
   <center>
  <a href="#" className="brand-link">
      <img src="dist/img/logo.png" alt="multigobernanza" width="63px" />
      <span className="brand-text font-weight-light"></span>
    </a>   </center>  

   
    <div className=" scrollb ">
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image"  width="170px" height="170px" />
        </div>
        <div className="info">
          <a href="#" className="d-block">{theUser.name}</a>
          
         
            <button className="btn btn-primary" onClick={logoutUser} > Salir <i className="fas fa-sign-out-alt"></i></button>
            
            
            
        </div>
      </div>

      <nav className="mt-2">
    
   
      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      
      {  theUser.tipo_usuario =="super"
      ?<li className="nav-item" >
            <Link to="/Ubicacion" className="nav-link ubicacion">
            
              <i> <img src="dist/img/ubicacion.png" width="35px" /></i>
              <p>
                 &nbsp; Ubicación
               <b className="caret" />
             </p>
              </Link>
          </li>  
          : ""
      }  

          {  theUser.tipo_usuario =="super"
          ?<li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link  deportes " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/deportes.png" width="35px" /></i>
          <p className="deportes">
          &nbsp; Deportes
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Deporte" className="nav-link">
            <i> <img src="dist/img/deportes.png" width="35px" /></i>
            <p>
               &nbsp;Deporte
             <b className="caret" />
           </p>
            </Link>
        </li>
      
   
          </ul>
        </li>
        
        : ""
      } 


     {  theUser.tipo_usuario =="super"
          ?<li className="nav-item" >
            <Link to="/Modulos" className="nav-link modulos">
            
              <i> <img src="dist/img/modulos.png" width="35px" /></i>
              <p className="modulos">
                 &nbsp; Modulos
               <b className="caret " />
             </p>
              </Link>
          </li>   
: ""
} 

 { theUser.tipo_usuario =="super"
          ?<li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link  administradores " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/administrador.png" width="35px" /></i>
          <p className="administradores">
          &nbsp; Administrador
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Register" className="nav-link">
            <i> <img src="dist/img/administrador.png" width="35px" /></i>
            <p>
               &nbsp;Crear Administrador
             <b className="caret" />
           </p>
            </Link>
        </li>


        <li className="nav-item">
            <Link to="/Administrador" className="nav-link">
            <i> <img src="dist/img/administrador.png" width="35px" /></i>
            <p>
               &nbsp;Ver administradores
             <b className="caret" />
           </p>
            </Link>
        </li>
      
   
          </ul>
        </li>

: ""
}

        { theUser.tipo_usuario == "administrador"
         ? <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link  empresas " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/empresas.png" width="35px" /></i>
          <p className="empresas">
          &nbsp; Empresas
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Empresa" className="nav-link">
            <i> <img src="dist/img/empresas.png" width="35px" /></i>
            <p>
               &nbsp;Persona natural
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Ver_empresas" className="nav-link">
            <i> <img src="dist/img/clones.png" width="35px" /></i>
            <p>
               &nbsp;Ver Personas naturales
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Empresajuridica" className="nav-link">
            <i> <img src="dist/img/empresas.png" width="35px" /></i>
            <p>
               &nbsp;Persona juridica
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Empresa" className="nav-link">
            <i> <img src="dist/img/compronline.png" width="35px" /></i>
            <p>
               &nbsp;Ver personas juridicas
             <b className="caret" />
           </p>
            </Link>
        </li>
      
   
          </ul>
        </li>

: ""
}  



{ theUser.tipo_usuario == "administrador"
         ? <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link   modulos" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/ganep.png" width="35px" /></i>
          <p className="modulos">
          &nbsp; Deportistas
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Deportistas" className="nav-link">
            <i> <img src="dist/img/ganep.png" width="35px" /></i>
            <p>
               &nbsp;Crear Deportista
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Ver_deportistas" className="nav-link">
            <i> <img src="dist/img/softciudadano.png" width="35px" /></i>
            <p>
               &nbsp;Ver Deportista
             <b className="caret" />
           </p>
            </Link>
        </li>

   
          </ul>
        </li>

: ""
}  


{  theUser.tipo_usuario =="empresa"
          ?<li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link  deportes " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/torneos.png" width="35px" /></i>
          <p className="deportes">
          &nbsp; Torneos
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Crear_torneo" className="nav-link">
            <i> <img src="dist/img/torneos.png" width="35px" /></i>
            <p>
               &nbsp;Crear torneo
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Torneo_pendiente" className="nav-link">
            <i> <img src="dist/img/torneo_pendiente.png" width="35px" /></i>
            <p>
               &nbsp;Torneo pendiente
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Torneos_activos" className="nav-link">
            <i> <img src="dist/img/torneos_activos.png" width="35px" /></i>
            <p>
               &nbsp;Torneos activos
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Torneos_finalizados" className="nav-link">
            <i> <img src="dist/img/torneos_finalizados.png" width="35px" /></i>
            <p>
               &nbsp;Torneos finalizados
             <b className="caret" />
           </p>
            </Link>
        </li>
      
   
          </ul>
        </li>
        
        : ""
      } 






      </ul>
      

     

      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column">
      
        
      </ul>
    
    </nav>
  </div>

</nav>
       
    )

    
  }

 


export default Menu
