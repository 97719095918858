import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { colourOptions } from './docs/data';

const animatedComponents = makeAnimated();

export default function Pruebas2() {
  return (
     <>
     <div><h6><b>Este modulo se refiere al Orden de los criterios en Caso de empate entre equipos a clasificar, Puede Organizar el Orden Según su Interés</b></h6></div>
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      defaultValue={[]}
      isMulti
      options={colourOptions}
    />
<div class="col-sm-12">
            <br />
      <button className="btn btn-success rounded-circle float-right"  disabled><i class="fas fa-save fa-2x"></i></button>
   
      
      </div>
</>
  );
}