import React, {useContext,useState} from 'react';

import {MyContext} from '../../contexts/MyContext';

// Importing the Login & Register Componet
import Register from './Register';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

 function Goleador(props) {

  // condicion select1 si o no
  const [value2, setValue2] = React.useState();

  // condicion select2 si o no
  const [value3, setValue3] = React.useState();

  // condicion select3 si o no
  const [value4, setValue4] = React.useState();

  // condicion select4 si o no
  const [value5, setValue5] = React.useState();

 //Modal puntos ganador
const [modalPuntosGanador, setModalPuntosGanador]=useState(false);

//Modal puntos ganador

const abrirCerrarModalPuntosGanador=()=>{
      setModalPuntosGanador(!modalPuntosGanador);
}


 //Modal empate juego
 const [modalEmpateJuego, setModalEmpateJuego]=useState(false);

 //Modal empate juego
 
 const abrirCerrarModalEmpateJuego=()=>{
       setModalEmpateJuego(!modalEmpateJuego);
 }


 //Modal perdedor juego
 const [modalPerdedorJuego, setModalPerdedorJuego]=useState(false);

 //Modal perdedor juego
 
 const abrirCerrarModalPerdedorJuego=()=>{
      setModalPerdedorJuego(!modalPerdedorJuego);
}


//Modal ganador juego w
const [modalGanadorJuegoW, setModalGanadorJuegoW]=useState(false);

//Modal ganador juego w

const abrirCerrarModalGanadorJuegoW=()=>{
     setModalGanadorJuegoW(!modalGanadorJuegoW);
}


//Modal goles juego w
const [modalGolesJuegoW, setModalGolesJuegoW]=useState(false);

//Modal goles juego w

const abrirCerrarModalGolesJuegoW=()=>{
     setModalGolesJuegoW(!modalGolesJuegoW);
}


//Modal contra juego w
const [modalContraJuegoW, setModalContraJuegoW]=useState(false);

//Modal contra juego w

const abrirCerrarModalContraJuegoW=()=>{
     setModalContraJuegoW(!modalContraJuegoW);
}


//Modal contra juego w2
const [modalContraJuegoW2, setModalContraJuegoW2]=useState(false);

//Modal contra juego w2

const abrirCerrarModalContraJuegoW2=()=>{
     setModalContraJuegoW2(!modalContraJuegoW2);
}
    

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;



    if(isAuth)
    {

    return (
        
        
        
            
              <div className="col-sm-12">
                  
    
              <div class="card card-primary">
                  
                  <div class="card-header">
                    <h3 class="card-title">GOLEADOR – PORTERO MENOS VENCIDO
</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    


      <div class="row">
        <center> <h6><b>Este modulo se refiere a los criterios para determinar el goleador del torneo y el Portero Menos Vencido.  

</b></h6> </center> 

<br/>
<br/>

          <div class="col-sm-4">
                <label><b>Para El Goleador</b>, Se le tiene en cuenta los goles en Contra o Auto Gol?

</label>
                <div class="input-group">
               {/* <select name="puntos_ganador"
       
         class="form-control" 
         name="puntos_ganador"
         value={props.getState('puntos_ganador', '')} 
         onChange={props.handleChange}
         > */}

<select name="puntos_ganador"
       
       name="puntos_ganador"
       value={value2} class="form-control" onChange={e => setValue2(e.currentTarget.value)}
       >

<option value='no' selected>.</option>
      <option value="SI">SI</option>
      <option value="NO">NO</option>
      
    </select>  
      


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>
      

     


      <div class="col-sm-2">
      { value2==='SI' ? (<div><label>Desde</label>
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empate"
       
       class="form-control" 
       name="puntos_empate"
       value={props.getState('puntos_empate', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 
       </div>
      ):null}
      </div>


      <div class="col-sm-2">
      { value2==='SI' ? (<div> <label>Hasta</label>
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empate2"
       
       class="form-control" 
       name="puntos_empate2"
       value={props.getState('puntos_empate2', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 
       </div> 
       ):null}
      </div>




    

      
    </div>
   
    

   

<hr />
      

      <br />
      <div class="row">

     

      <div class="col-sm-4">
                <label><b>Para El Goleador</b>, Jugadores Trasladados de Otro Equipo Eliminado del Mismo Torneo, Se les tiene en cuenta los Goles Anteriores ?


</label>
                <div class="input-group">
               {/* <select name="puntos_ganadorb1"
       
         name="puntos_ganadorb1"
         value={props.getState('puntos_ganadorb1', '')} 
         onChange={props.handleChange}
> */}

<select name="puntos_ganadorb1"
       
         name="puntos_ganadorb1"
         value={value3} class="form-control" onChange={e => setValue3(e.currentTarget.value)}
>


<option value='no' selected>.</option>
      <option value="SI">SI</option>
      <option value="NO">NO</option>
      
    </select>  
      


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>
      

     


      <div class="col-sm-2">
      { value3==='SI' ? (<div><label>Desde</label>
      <br />
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empateb1"
       
       class="form-control" 
       name="puntos_empateb1"
       value={props.getState('puntos_empateb1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 
       </div> 
       ):null}
      </div>


      <div class="col-sm-2">
      { value3==='SI' ? (<div><label>Hasta</label>
      <br />
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empate2b1"
       
       class="form-control" 
       name="puntos_empate2b1"
       value={props.getState('puntos_empate2b1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

 </div> 
       ):null}

      </div>

      </div>
      
      
      <hr />
      
      <br />

    
      <div class="row"> 
      <div class="col-sm-4">
                <label><b>Para El Portero Menos Vencido </b>, Se le tiene en cuenta Las Estadísticas Desde ?



</label>
                <div class="input-group">
               { /*<select name="puntos_ganadorc1"
       
         class="form-control" 
         name="puntos_ganadorc1"
         value={props.getState('puntos_ganadorc1', '')} 
         onChange={props.handleChange}
> */  }

<select name="puntos_ganadorc1"
       
       class="form-control" 
       name="puntos_ganadorc1"
       value={value4} class="form-control" onChange={e => setValue4(e.currentTarget.value)}
       >


<option value='no' selected>.</option>
      <option value="SI">SI</option>
      <option value="NO">NO</option>
      
    </select>  
      


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>


      
      <div class="col-sm-2">
      { value4==='SI' ? (<div><label>Desde</label>
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empatec1"
       
       class="form-control" 
       name="puntos_empatec1"
       value={props.getState('puntos_empatec1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div> 
       ):null}

      </div>


      <div class="col-sm-2">
      { value4==='SI' ? (<div><label>Hasta</label>
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empate2c1"
       
       class="form-control" 
       name="puntos_empate2c1"
       value={props.getState('puntos_empate2c1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div> 
       ):null}

      </div>

      </div>



      <hr />
      
      <br />

    
      <div class="row"> 

      <div class="col-sm-4">
                <label><b>Para El Portero Menos Vencido </b>, Trasladados de Otro Equipo del Mismo Torneo, Se le Tiene en cuenta La estadística Anterior ?




</label>
                <div class="input-group">
               { /* <select name="puntos_ganadord1"
       
         class="form-control" 
         name="puntos_ganadord1"
         value={props.getState('puntos_ganadord1', '')} 
         onChange={props.handleChange}
> */}

           <select name="puntos_ganadord1"
       
       name="puntos_ganadord1"
       value={value5} class="form-control" onChange={e => setValue5(e.currentTarget.value)}
       >
           
<option value='no' selected>.</option>
      <option value="SI">SI</option>
      <option value="NO">NO</option>
      
    </select>  
      


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
       

      </div>


      
      <div class="col-sm-2">
      { value5==='SI' ? (<div><label>Desde</label>
      <br />
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empated1"
       
       class="form-control" 
       name="puntos_empated1"
       value={props.getState('puntos_empated1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 
       </div> 
       ):null}
      </div>


      <div class="col-sm-2">
      { value5==='SI' ? (<div><label>Hasta</label>
      <br />
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empate2d1"
       
       class="form-control" 
       name="puntos_empate2d1"
       value={props.getState('puntos_empate2d1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div> 
       ):null}
       

      </div>

      <div class="col-sm-4">
            <br />
            <br />
      <button className="btn btn-success rounded-circle float-right"   onClick={props.next}><i class="fas fa-save fa-2x"></i></button>
      
 
      </div>

      </div>

     
      
     

      




       
      
    







      

    


  </form>
                  </div>
                 
                </div>
    
    
{/* puntos ganador*/}
<Modal isOpen={modalPuntosGanador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Ganador Del Juego.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPuntosGanador()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* empate juego*/}
<Modal isOpen={modalEmpateJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos Por Empate De Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Cada Equipo por Empate Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEmpateJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* perdedor juego*/}
<Modal isOpen={modalPerdedorJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Perdedor Del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Perdedor Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPerdedorJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* ganador juego W*/}
<Modal isOpen={modalGanadorJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados A Favor Al Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGanadorJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* goles juego W*/}
<Modal isOpen={modalGolesJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles a Favor al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Otorgados A Favor Del Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGolesJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* contra juego W*/}
<Modal isOpen={modalContraJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos En Contra Por Perder el Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* contra juego W2*/}
<Modal isOpen={modalContraJuegoW2}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles En Contra al Perdedor del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW2()}>Aceptar</button>
 </ModalFooter>
</Modal>
    
          </div>
    
 


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
        
}
 }

export default Goleador