import React, {useEffect, useContext, useState} from 'react'


//logo confenalco

import LogoConfenalco from '../../img/confenalco.jpg';

import LogoBalon from '../../img/balon.png';

import LogoTorneo1 from '../../img/activos.png';

import Reglamento from '../../img/reglamento_torneo_verde.png'
import Programacion from '../../img/programacion_verde.png'
import Grupos from '../../img/grupos.png'
import Programar_juegos from '../../img/programar_juegos_verde.png'
import Auditar_torneo from '../../img/auditar_torneo.png'
import Tabla_posiciones from '../../img/tabla_posiciones.png'

import LogoTorneo from '../../img/torneos.png'


import Editar1 from '../../img/editar4.png';
import Editar2 from '../../img/editar5.png';
import Editar3 from '../../img/editar6.png';
import Editar11 from '../../img/editar11.png';
import Editar12 from '../../img/editar12.png';
import Editar13 from '../../img/editar13.png';
import Editar14 from '../../img/editar14.png';
import Editar15 from '../../img/editar15.png';
import Editar16 from '../../img/editar16.png';
import Editar17 from '../../img/editar17.png';
import Editar18 from '../../img/editar18.png';
import Editar19 from '../../img/editar19.png';
import Editar20 from '../../img/editar20.png';
import Editar8 from '../../img/editar8.png';
import Editar9 from '../../img/editar9.png';
import Editar10 from '../../img/editar10.png';
import Editar2a from '../../img/editar2.png';


import { Steps, Step } from "react-step-builder";


//Acordion
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';




import {MyContext} from '../../contexts/MyContext';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import logo from '../../img/pdf.png'

import axios from 'axios';


import {Dropdown,DropdownItem,DropdownMenu,DropdownToggle} from 'reactstrap'

 function Torneo_activo12(props) {

    //Acordion
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));


  //guardar
  const baseUrlAd="https://www.deportes.controlsas.com/apiPlooy/Solicitar_empresa/"
  const [data, setData]=useState([]);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    guardar_equipos:'',
  });

//fecha de solo año
  const [startDate, setStartDate] = useState(new Date());
   
  //minutos por tiempo
  const [minutes, setMinutes] = useState("");

  //minutos por tiempo
  useEffect(() => {
    if (minutes && (+minutes < 1 || +minutes > 45)) setMinutes("")
  }, [minutes])


  //minutos por tiempo
  const handleChange = ({ target: { value } }) => setMinutes(value);

  //BOTON EDITAR
  const [modoedicion, setModoEdicion] = useState(null) 

  const [dropdown, setDropdown]=useState(false);
  const [dropdown2, setDropdown2]=useState(false);
  const [dropdown3, setDropdown3]=useState(false);
  const [dropdown4, setDropdown4]=useState(false);

 //Modal
  const [modalTiemposJuego, setModalTiemposJuego]=useState(false);
  const [modalMinutosTiempo, setModalMinutosTiempo]=useState(false);
  const [modalGenero, setModalGenero]=useState(false);
  const [modalEdad, setModalEdad]=useState(false);

  const [modalSuperficie, setModalSuperficie]=useState(false);
  const [modalEquipos, setModalEquipos]=useState(false);
  const [modalJugadores, setModalJugadores]=useState(false);
  const [modalFecha, setModalFecha]=useState(false);
  const [modalImagen, setModalImagen]=useState(false);

  //select genero
  const [value, setValue] = React.useState()


  //select edad
  const [value2, setValue2] = React.useState()


  //select edad libre
  const defaultState = {
    numero: "0",
    numero1: "",
    numero2: "",

    numero_cero: "0",
    numero1_uno: "",

  };


  

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }

  const abrirCerrarDropdown2=()=>{
    setDropdown2(!dropdown2);
  }

  const abrirCerrarDropdown3=()=>{
    setDropdown3(!dropdown3);
  }

  const abrirCerrarDropdown4=()=>{
    setDropdown4(!dropdown4);
  }


//Modal

const abrirCerrarModalTiemposJuegos=()=>{
  setModalTiemposJuego(!modalTiemposJuego);
}

const abrirCerrarModalMinutosTiempo=()=>{
  setModalMinutosTiempo(!modalMinutosTiempo);
}

const abrirCerrarModalGenero=()=>{
  setModalGenero(!modalGenero);
}

const abrirCerrarModalEdad=()=>{
  setModalEdad(!modalEdad);
}



const abrirCerrarModalSuperficie=()=>{
  setModalSuperficie(!modalSuperficie);
}

const abrirCerrarModalEquipos=()=>{
  setModalEquipos(!modalEquipos);
}

const abrirCerrarModalJugadores=()=>{
  setModalJugadores(!modalJugadores);
}

const abrirCerrarModalFecha=()=>{
  setModalFecha(!modalFecha);
}

const abrirCerrarModalImagen=()=>{
  setModalImagen(!modalImagen);
}


//guardar
const handleChange_guardar=e=>{
  e.preventDefault();
  const {name, value}=e.target;
  setFrameworkSeleccionado((prevState)=>({
    ...prevState,
    [name]: value
  }))
 console.log(frameworkSeleccionado);
}

//guardar
const peticionPost = async() =>{
 
  var f = new FormData();
  f.append("guardar_equipos", frameworkSeleccionado.guardar_equipos);
  f.append("METHOD", "POST");
  await axios.post(baseUrlAd,f)
  .then(response=>{
   setData(data.concat(response.data));
  // abrirCerrarModalInsertar();
  setModoEdicion(true)
  alert("Información guardada correctamente");
  }).catch(error=>{
    console.log(error);
  })

}

const peticionEditar =()=>{
  alert("Información se ha editado correctamente");
}


// edad libre
function Row({ onChange, onRemove, numero, numero1, numero2 }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-4">
         
         <input
        type="number"
        value={numero1}
        onChange={e => onChange("numero1", e.target.value)}
        class="form-control"
        
      />

</div>

<div class="col-sm-4">
         
         <input
        type="number"
        value={numero2}
        onChange={e => onChange("numero2", e.target.value)}
        class="form-control"
        
      />



      </div>
<br/>

 <br/>

 <div class="col-sm-3">

      <input
        type="number"
        value={numero}
        onChange={(e) => onChange("numero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}


//edad libre
const [rows, setRows] = useState([defaultState]);
const [amount, setAmount] = useState(0);

const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};

const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  let total = 0;
  rows.map(function (num) {
    total = total + parseInt(num.numero);
  });
  console.log(total);
  setAmount(total);
  setRows(rows.concat(defaultState));
};

const handleOnRemove = (index) => {
  setAmount(amount - parseInt(rows[index].numero));
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
console.log(amount);





// edad menores de edad

function Row2({ onChange, onRemove, numero_cero, numero_uno }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-5">
         
         <input
        type="number"
        value={numero_uno}
        onChange={e => onChange("numero_uno", e.target.value)}
        class="form-control"
        
      />

      </div>
<br/>

 <br/>

 <div class="col-sm-5">

      <input
        type="number"
        value={numero_cero}
        onChange={(e) => onChange("numero_cero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}




//edad menos de edad
const [rowss, setRowss] = useState([defaultState]);
const [amounts, setAmounts] = useState(0);

const handleOnChanges = (index, name, value) => {
  const copyRows = [...rowss];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRowss(copyRows);
};

const handleOnAdds = e => {
  e.preventDefault(); //esto previene que el form se mande.
  let total = 0;
  rowss.map(function (num) {
    total = total + parseInt(num.numero_cero);
  });
  console.log(total);
  setAmounts(total);
  setRowss(rowss.concat(defaultState));
};

const handleOnRemoves = (index) => {
  setAmounts(amounts - parseInt(rowss[index].numero_cero));
  const copyRows = [...rowss];
  copyRows.splice(index, 1);
  setRowss(copyRows);
};
console.log(amounts);





 //sumar generos
 const [number, setNumber] = useState({pago_uno: 0, pago_dos: 0})
  const [suma, setSuma] = useState()

  useEffect(()=>{
    const {pago_uno, pago_dos} = number
    setSuma(Number(pago_uno) + Number(pago_dos))
},[number])
  

  const handleInput = (event) => {
    const { name, value } = event.target
    setNumber({...number, [name]: value})
  }


//acordeon
  const [color, setName] = useState([]);


// agregar acordeon
const classes = useStyles();
  
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {

    return (
        
        
       
            
              <div className="col-sm-12">
           
         
             


    
              <div class="card">

              <div>
              <h3 class="card-title" ><button style={{backgroundColor:"transparent", border:"none"}}><img src={Reglamento} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Programacion} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Grupos} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}}><img src={Programar_juegos} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Auditar_torneo} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Tabla_posiciones} width="145px" /></button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <font size="1"><img src={LogoTorneo} width="20px" /> TORNEOS / &nbsp; 
                              <img src={LogoTorneo1} width="20px" /> ACTIVOS 
                             
                             </font> </h3>
              
              </div>
                  
                  <div class="card-header text-center">
                    <h3 class="card-title"><img src={LogoConfenalco} width="45px" /><img src={LogoBalon} width="45px" /> <b>TORNEO CHENCHO 2022 </b>  - Colombia – Tolima – Ibagué – Futbol - F11 – G Natural – Masculino – Dici 2021</h3>
                    <br /><h3 class="card-title"> 
  </h3>
                  </div>

                  
                
                  <div class="card-body">
                      
              {   /* <form >*/ }









  

      {/*Aqui fue */}
     

     
       

    



        <br />      

        <div class="col-sm-12">

<center> <img src={Editar2a} width="845px" /> </center>



</div>


 

      <div class="col-sm-12">

     <center> <img src={Editar11} width="845px" /> </center>



</div>


<div class="col-sm-12">
    

<center>  <img src={Editar12} width="580px" /> </center>

<hr />

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


<center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



<div class="col-sm-12">

<button className="btn btn-success rounded-circle float-right"   onClick={props.next}><i class="fas fa-save fa-2x"></i></button>
</div>







<button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;




  {/*</form>*/}
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
       

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
      
}
 }

 

export default Torneo_activo12