import React, {useEffect, useContext, useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import logo from '../../img/pdf.png'

import axios from 'axios';


import {Dropdown,DropdownItem,DropdownMenu,DropdownToggle} from 'reactstrap'

 function Datos_generales_torneo2_2(props) {

  //guardar
  const baseUrlAd="https://www.deportes.controlsas.com/apiPlooy/Solicitar_empresa/"
  const [data, setData]=useState([]);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    guardar_equipos:'',
  });

  //button1
  const [value2_button, setValue2_button] = useState("no");

  //button2
  const [value3_button, setValue3_button] = useState("no");

//fecha de solo año
  const [startDate, setStartDate] = useState(new Date());
   
  //minutos por tiempo
  const [minutes, setMinutes] = useState("");

  //minutos por tiempo
  useEffect(() => {
    if (minutes && (+minutes < 1 || +minutes > 45)) setMinutes("")
  }, [minutes])


  //minutos por tiempo
  const handleChange = ({ target: { value } }) => setMinutes(value);

  //BOTON EDITAR
  const [modoedicion, setModoEdicion] = useState(null) 

  const [dropdown, setDropdown]=useState(false);
  const [dropdown2, setDropdown2]=useState(false);
  const [dropdown3, setDropdown3]=useState(false);
  const [dropdown4, setDropdown4]=useState(false);

 //Modal
  const [modalTiemposJuego, setModalTiemposJuego]=useState(false);
  const [modalMinutosTiempo, setModalMinutosTiempo]=useState(false);
  const [modalGenero, setModalGenero]=useState(false);
  const [modalEdad, setModalEdad]=useState(false);

  const [modalSuperficie, setModalSuperficie]=useState(false);
  const [modalEquipos, setModalEquipos]=useState(false);
  const [modalJugadores, setModalJugadores]=useState(false);
  const [modalFecha, setModalFecha]=useState(false);
  const [modalImagen, setModalImagen]=useState(false);

  //select genero
  const [value, setValue] = React.useState()


  //select edad
  const [value2, setValue2] = React.useState()


  //select edad libre
  const defaultState = {
    numero: "0",
    numero1: "",
    numero2: "",

    numero_cero: "0",
    numero1_uno: "",

  };


  

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }

  const abrirCerrarDropdown2=()=>{
    setDropdown2(!dropdown2);
  }

  const abrirCerrarDropdown3=()=>{
    setDropdown3(!dropdown3);
  }

  const abrirCerrarDropdown4=()=>{
    setDropdown4(!dropdown4);
  }


//Modal

const abrirCerrarModalTiemposJuegos=()=>{
  setModalTiemposJuego(!modalTiemposJuego);
}

const abrirCerrarModalMinutosTiempo=()=>{
  setModalMinutosTiempo(!modalMinutosTiempo);
}

const abrirCerrarModalGenero=()=>{
  setModalGenero(!modalGenero);
}

const abrirCerrarModalEdad=()=>{
  setModalEdad(!modalEdad);
}



const abrirCerrarModalSuperficie=()=>{
  setModalSuperficie(!modalSuperficie);
}

const abrirCerrarModalEquipos=()=>{
  setModalEquipos(!modalEquipos);
}

const abrirCerrarModalJugadores=()=>{
  setModalJugadores(!modalJugadores);
}

const abrirCerrarModalFecha=()=>{
  setModalFecha(!modalFecha);
}

const abrirCerrarModalImagen=()=>{
  setModalImagen(!modalImagen);
}


//guardar
const handleChange_guardar=e=>{
  e.preventDefault();
  const {name, value}=e.target;
  setFrameworkSeleccionado((prevState)=>({
    ...prevState,
    [name]: value
  }))
 console.log(frameworkSeleccionado);
}

//guardar
const peticionPost = async() =>{
 
  var f = new FormData();
  f.append("guardar_equipos", frameworkSeleccionado.guardar_equipos);
  f.append("METHOD", "POST");
  await axios.post(baseUrlAd,f)
  .then(response=>{
   setData(data.concat(response.data));
  // abrirCerrarModalInsertar();
  setModoEdicion(true)
  alert("Información guardada correctamente");
  }).catch(error=>{
    console.log(error);
  })

}

const peticionEditar =()=>{
  alert("Información se ha editado correctamente");
}


// edad libre
function Row({ onChange, onRemove, numero, numero1, numero2 }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-4">
         
         <input
        type="number"
        value={numero1}
        onChange={e => onChange("numero1", e.target.value)}
        class="form-control"
        
      />

</div>

<div class="col-sm-4">
         
         <input
        type="number"
        value={numero2}
        onChange={e => onChange("numero2", e.target.value)}
        class="form-control"
        
      />



      </div>
<br/>

 <br/>

 <div class="col-sm-3">

      <input
        type="number"
        value={numero}
        onChange={(e) => onChange("numero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}


//edad libre
const [rows, setRows] = useState([defaultState]);
const [amount, setAmount] = useState(0);

const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};

const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  let total = 0;
  rows.map(function (num) {
    total = total + parseInt(num.numero);
  });
  console.log(total);
  setAmount(total);
  setRows(rows.concat(defaultState));
};

const handleOnRemove = (index) => {
  setAmount(amount - parseInt(rows[index].numero));
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
console.log(amount);





// edad menores de edad

function Row2({ onChange, onRemove, numero_cero, numero_uno }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-5">
         
         <input
        type="number"
        value={numero_uno}
        onChange={e => onChange("numero_uno", e.target.value)}
        class="form-control"
        
      />

      </div>
<br/>

 <br/>

 <div class="col-sm-5">

      <input
        type="number"
        value={numero_cero}
        onChange={(e) => onChange("numero_cero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}




//edad menos de edad
const [rowss, setRowss] = useState([defaultState]);
const [amounts, setAmounts] = useState(0);

const handleOnChanges = (index, name, value) => {
  const copyRows = [...rowss];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRowss(copyRows);
};

const handleOnAdds = e => {
  e.preventDefault(); //esto previene que el form se mande.
  let total = 0;
  rowss.map(function (num) {
    total = total + parseInt(num.numero_cero);
  });
  console.log(total);
  setAmounts(total);
  setRowss(rowss.concat(defaultState));
};

const handleOnRemoves = (index) => {
  setAmounts(amounts - parseInt(rowss[index].numero_cero));
  const copyRows = [...rowss];
  copyRows.splice(index, 1);
  setRowss(copyRows);
};
console.log(amounts);





 //sumar generos
 const [number, setNumber] = useState({pago_uno: 0, pago_dos: 0})
  const [suma, setSuma] = useState()

  useEffect(()=>{
    const {pago_uno, pago_dos} = number
    setSuma(Number(pago_uno) + Number(pago_dos))
},[number])
  

  const handleInput = (event) => {
    const { name, value } = event.target
    setNumber({...number, [name]: value})
  }




  
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {

    return (
        
        
       
            
              <div className="col-sm-12">
           
         
             


    
              <div class="card">
                  
                  <div class="card-header">
                    <h3 class="card-title">DATOS GENERALES TORNEO - TORNEO CANCHA CHENCHO</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
              {   /* <form >*/ }


<br/>
    <div class="row">



<div class="col-sm-2">
<h6>Tiempos de Juego</h6> 
      
         <div class="input-group ">
           
 

<input type="number" value="2" className="form-control" readOnly />

                  </div> </div>




                  <div class="col-sm-3">
                  
                  <h6>Minutos Por Tiempo</h6> 
         <div class="input-group ">
          

   <input type="number" value="45" className="form-control"  min = "1" max= "45" readOnly
   name="minutos_juegos"

  onChange={handleChange}
  />

                  </div> </div>




                  <div class="col-sm-3">
                
                 <h6>Genero</h6> 
<div class="input-group">

<input className="form-control"  value="Femenino"  readOnly/>
  </div>

  <br />



  
  </div>
  

<center>
  <div class="col-sm-9">
  { value==='Mixto' ? (<div class="card">

    <div class="card-body">
    <h6> <font size="3">  - Restricción Para deportistas por Genero ( MIXTO ) </font>
</h6> 

<h6><font size="2">De Los <input type="text" value="11"  style={{textAlign:"center"}} readOnly /> Deportistas en Campo Están autorizados: Máximo Por Cada Genero:  </font>
</h6> 

<br />

<h6> <font size="3"> <b> Deportistas Jugando en Cancha. </b></font> </h6>

<br />
 
   

   <div class="row">

   <div class="col-sm-3">
 
<div class="input-group">

<select name="Deportista_femenino" className="form-control" style={{background:"#FBA1A1"}}>
 
<option value='no' selected>Deportista Femenino</option>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
  
</select>  

  </div>
  </div>

  <div class="col-sm-3">

  <div class="input-group">

<select name="Deportista_femenino" className="form-control" style={{background:"#A1E6FB"}}>
 
<option value='no' selected>Deportista Masculino</option>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
  
</select>  

  </div>
  </div>


  <div class="col-sm-3">

  <div class="input-group">

<select name="Deportista_femenino" className="form-control" style={{background:"#A1FBD3"}}>
 
<option value='no' selected>Deportista Otro</option>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
  
</select>  

  </div>
  </div>

  

  <div class="col-sm-2" >

    
  
  <input type="number" onChange={handleInput} class="form-control" name="pago_dos" /*value={number.pago_dos}*/ value="11" readOnly/>
  TOTAL
  </div>

  

  <div class="col-sm-12">
  <button  className="btn btn-success rounded-circle float-right" name="suma"  /* value={suma}*/ disabled > <i class="fas fa-save fa-2x"></i> </button>
  </div>

  </div>


  
  




  </div>
  
  
  </div>
  ):null}


  

  </div>


  </center>









  <div class="col-sm-6">
  <h6>Edad</h6> 
<div class="input-group">

<input className="form-control"  value="Edad Libre (Sin Restricción)"  readOnly/>

  </div>

  <br />



  


  
  </div>


<center>
  <div class="col-sm-7">
  { value2==='Para Mayores de Edad' ? (<div class="card">

    <div class="card-body">
        <center><p><b>Condición Por Fecha de Nacimiento.</b></p> </center>
        <center><p>Recomendado Para Mayores de Edad, Se Tiene en cuenta los Años Cumplidos al Momento de Iniciar El Torneo.
</p> </center>
        
        <div class="col-sm-12">
          
<hr />




          <div class="col-sm-6">
          <font size="3"><b>Cual es la Edad Base o Mínima Cumplida Para Participar </b> </font>
        </div>

        <br/>

        <div class="row">
      <div class="col-sm-5">
        <input type="number" className='btn btn-info'  placeholder="Años" />
        </div> 

        <div class="col-sm-5">
          <p><font size="4">Años Cumplidos en Adelante  </font>                                                             
</p>
        </div>


        </div>

        <br />

       
      

    <div class="col-sm-12">

    <hr />
   
        <p > <font size="3">Excepciones Por Edad: En Este Torneo Pueden Jugar Deportistas con edades Inferiores a La edad Base o Mínima de <input type="number" value="40" readOnly style={{textAlign:"center"}} /> </font> </p>
      
   </div>     
      
       </div>

       <div className="col-sm-6">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="radio1"
            value="no"
            checked={value2_button === "no"}
            onChange={(e) => setValue2_button(e.currentTarget.value)}
          />
          <label className="form-check-label">NO</label>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <input
            className="form-check-input"
            type="radio"
            value="si"
            name="radio1"
            checked={value2_button === "si"}
            onChange={(e) => setValue2_button(e.currentTarget.value)}
          />
          <label className="form-check-label">SI</label>
        </div>
      </div>
     
                    <div class="col-sm-12">

                    {value2_button === "si" && ( 
                       <div>
                    <hr/>
                    
                    <div class="row">
      
                    <div class="col-sm-12">

                      <p>De los  <input type="number" value="11" style={{textAlign:"center"}} readOnly /> Deportistas En Cancha
Relacione la cantidad autorizados según Edad:
</p>

                      </div>

        </div>

        <div class="row">
      
        <div class="col-sm-12" style={{paddingLeft:"380px"}}>
        <b> TOTAL</b>
          </div>

                    <div class="col-sm-3">

                    <input type="text" className="form-control" value="40 Años" style={{textAlign:"center"}} readOnly />

                    
           

                     

                    </div>

                    <div class="col-sm-2">
                      <p>O mayores</p>
                    </div>


                    <div class="col-sm-3">

                    <div class="input-group">

<select name="Deportista_femenino" className="form-control" style={{background:"#A1FBD3"}}>
 
<option value='no' selected>#Deportistas</option>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
  
</select>  

  </div>


  


                    </div>


                    <div class="col-sm-3">

                    <input type="text" value="11" style={{textAlign:"center"}} className="form-control" readOnly />

</div>
</div>

<br/>
<div class="row">

<div class="col-sm-3">
<div class="input-group">

<select name="edad" className="form-control">
 
<option value='no' selected>Edad</option>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
  <option value="11">11</option>
  <option value="12">12</option>
  <option value="13">13</option>
  <option value="14">14</option>
  <option value="15">15</option>
  <option value="16">16</option>
  <option value="17">17</option>
  <option value="18">18</option>
  <option value="19">19</option>
  <option value="20">20</option>
  <option value="21">21</option>
  <option value="22">22</option>
  <option value="23">23</option>
  <option value="24">24</option>
  <option value="25">25</option>
  <option value="6">6</option>
  <option value="27">27</option>
  <option value="28">28</option>
  <option value="29">29</option>
  <option value="30">30</option>
  <option value="31">31</option>
  <option value="32">32</option>
  <option value="33">33</option>
  <option value="34">34</option>
  <option value="35">35</option>
  <option value="36">36</option>
  <option value="37">37</option>
  <option value="38">38</option>
  <option value="39">39</option>
  <option value="40">40</option>
  
</select>  

  </div>

</div>


<div class="col-sm-3">
<div class="input-group">
<select name="Deportista_femenino" className="form-control" style={{background:"#A1FBD3"}}>
 
 <option value='no' selected>#Deportistas</option>
   <option value="1">1</option>
   <option value="2">2</option>
   <option value="3">3</option>
   <option value="4">4</option>
   
 </select>  
  </div>
  </div>

  <div class="col-sm-1">
  <button className='btn btn-info btn-circle ' style={{borderRadius: "100px"}} ><i class="fas fa-plus fa-1x "></i></button>
    </div>

</div>

<div class="col-sm-12" style={{paddingLeft:'510px'}}>

<button className='btn btn-success' disabled>Guardar</button>
</div>





                    </div>
                    )}
        </div>



      

      <br />
   


     
    </div>
  
  
  </div>
  ):null}


  

  </div> 

  </center>




 <center>

  <div class="col-sm-7">
  { value2==='Para Menores de Edad' ? (<div class="card">

    <div class="card-body">
        <center><p><b>Condición Por Año de Nacimiento.</b></p> </center>
        <center><p>Recomendado Para Menores de Edad, Se Tiene en cuenta el Año en que Nació el Deportista.</p> </center>
        
       

     

        <div class="col-sm-12">
          <p><font>Cual es la Categoría o Año de Nacimiento para los participantes de este Torneo.

  </font>                                                             
</p>
        </div>
      

    <div class="col-sm-12">
   
        <p > <font size="4">Año nacimiento </font> </p>
      
        
      
       </div>
       <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      showYearPicker
    
      dateFormat="yyyy"
    />
      
      
<br />
<br />

      <div class="col-sm-12">
     <p> Excepciones Por Edad: En Este Torneo Pueden Jugar Deportistas en categorías o años de Nacimiento Diferentes Nacidos en el Año <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      showYearPicker
      dateFormat="yyyy"
    />? </p>
       
        </div>

 

        <div className="col-sm-6">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="radio1"
            value="no"
            checked={value3_button === "no"}
            onChange={(e) => setValue3_button(e.currentTarget.value)}
          />
          <label className="form-check-label">NO</label>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <input
            className="form-check-input"
            type="radio"
            value="si"
            name="radio1"
            checked={value3_button  === "si"}
            onChange={(e) => setValue3_button(e.currentTarget.value)}
          />
          <label className="form-check-label">SI</label>
        </div>
      </div>



<div class="col-sm-12">
{value3_button === "si" && (  

<div>
                 
          <hr />
 


  <div class="col-sm-12">
  De los  <input date="number" value="11" readOnly style={{textAlign:"center"}} />        Deportistas En Cancha
Relacione la cantidad autorizados según Categoría o Por Fecha de Nacimiento:

  </div>  

<br />
  <div class="row">
      
        <div class="col-sm-12" style={{paddingLeft:"160px"}}>
        <b> TOTAL</b>
          </div>

                    <div class="col-sm-3">

                    <input type="text" className="form-control" value="2008" style={{textAlign:"center"}} readOnly />

                    
           

                     

                    </div>

                 
                    <div class="col-sm-3">

                    <div class="input-group">

<select name="Deportista_femenino" className="form-control" style={{background:"#A1FBD3"}}>
 
<option value='no' selected>#Deportistas</option>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
  
</select>  

  </div>


  


                    </div>


                    <div class="col-sm-3">

                    <input type="text" value="10" style={{textAlign:"center"}} className="form-control" readOnly />

</div>
</div>

<br/>

<div class="row">

<div class="col-sm-4">
<div class="input-group">

<select name="año_nacimiento" className="form-control">
 
<option value='no' selected>Año de nacimiento</option>
  <option value="2008">2008</option>
  <option value="2009">2009</option>
  <option value="2010">2010</option>
  <option value="2011">2011</option>
  <option value="2012">2012</option>
  <option value="2013">2013</option>
  <option value="2014">2014</option>
  <option value="2015">2015</option>
  <option value="2016">2016</option>
  <option value="2017">2017</option>
  <option value="2018">2018</option>
  <option value="2019">2019</option>
  
</select>  

  </div>

</div>


<div class="col-sm-3">
<div class="input-group">
<select name="Deportista_femenino" className="form-control" style={{background:"#A1FBD3"}}>
 
 <option value='no' selected>#Deportistas</option>
   <option value="1">1</option>
   <option value="2">2</option>
   <option value="3">3</option>
   <option value="4">4</option>
   
 </select>  
  </div>
  </div>

  <div class="col-sm-1">
  <button className='btn btn-info btn-circle ' style={{borderRadius: "100px"}} ><i class="fas fa-plus fa-1x "></i></button>
    </div>

</div>

<div class="col-sm-12" style={{paddingLeft:'510px'}}>

<button className='btn btn-success' disabled>Guardar</button>
</div>
  
    </div>
    
    )}

    </div> 





    </div> 
  </div>

  
  ):null}



 
  </div> 


  
  </center>
 
     
      </div>

  
       


      
    


    

    

    <div class="row">


    <div class="col-sm-3">
    <h6>Terreno de Juego</h6> 
<div class="input-group">
<input className="form-control"  value="AR - Arena"  readOnly/>

  </div>
  </div>



    <div class="col-sm-2">
    <h6>Equipos Inscritos</h6>

    <div class="input-group">
    
      <input type="number" name="guardar_equipos" class="form-control" value="10" readOnly   onChange={handleChange_guardar}
       />  
      </div>
      </div>


      



    <div class="col-sm-3">
    <h6>Jugadores Inscritos Por Equipo</h6>

    <div class="input-group">
      <input type="number" name="jugadores_equipo" class="form-control" value="11"  readOnly 
      onChange={props.handleChange} /> 
       
      </div>
      </div>

     
       

    <div class="col-sm-3">
    <h6>Fecha Inicio del Torneo</h6>
    <div class="input-group">

      <input type="text" value='10/07/2022' readOnly name="fecha_inicio" class="form-control"  onChange={props.handleChange} /> 
      

      </div>
      </div>


      </div>
      <br />

      <div class="row">

      <div class="col-sm-4">
      <h6>Imagen Promocional de Torneo</h6>

      <div class="input-group">
                  
                  <input class="form-control" type="file" id="formFile" readOnly readOnly />
                      
                      <span class="input-group-append">
                     
                  </span>

                  </div>

                  </div>


        </div>

    



        <br />      


 

      <div class="col-sm-12">

          



</div>




<button className="btn" style={{background:"#AAF0FF"}} onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
{/*<button className="btn btn-warning"  onClick={props.next} disabled >Guardar <i class="fas fa-arrow-right" ></i></button>*/}
{/*<button className="btn btn-success" onClick={()=>peticionPost()}>Guardar</button>{" "} */}


      

<Modal isOpen={modalTiemposJuego}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'red'}}>!</i> Cuantos tiempos</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cuantos Tiempos de Juego Para Cada Partido</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalTiemposJuegos()}>Aceptar</button>
 </ModalFooter>
</Modal>   




<Modal isOpen={modalMinutosTiempo}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'red'}}>!</i> Minutos por tiempo</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Determina Cuantos Minutos a Jugar Por Cada Tiempo.</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalMinutosTiempo()}>Aceptar</button>
 </ModalFooter>
</Modal>   



<Modal isOpen={modalGenero}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Genero</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Define el genero(s) que parciparan en el torneo por cada equipo. </p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGenero()}>Aceptar</button>
 </ModalFooter>
</Modal>   


<Modal isOpen={modalEdad}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Edad</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Define Las Restricciones o Limitaciones de Edad Para Cada Equipo en el Torneo</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEdad()}>Aceptar</button>
 </ModalFooter>
</Modal>   



<Modal isOpen={modalEquipos}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> N° de equipos</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cuantos Equipos Disputaran el Torneo</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEquipos()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalJugadores}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Jugadores por equipo</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cuantos Jugadores Máximo Inscritos en Planilla Por Equipo</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalJugadores()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalFecha}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Fecha de inicio</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cual Es La Fecha De Inicio Del Torneo.</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalFecha()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalImagen}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Imagen del torneo</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Adjunta Una Imagen Comercial De Tu Torneo: <br />
Imagen: Peso Máximo 14KB – Tamaño 11,3 X 8,1 <br />
Información En la Imagen: <br />
Nombre Del Torneo <br />
2. Ciudad Del Torneo <br />
3. Sub Deporte <br />
4. Genero <br />
5. Superficie De Juego <br />
6. Edad <br />
7. Datos adicionales a convenir entre las partes
</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalImagen()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalSuperficie}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Superfice de juego</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Define el Terreno o Superficie de Juego</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
 
   
  <button className="btn btn-warning" onClick={()=>abrirCerrarModalSuperficie()}>Aceptar</button>

 </ModalFooter>
</Modal> 



  {/*</form>*/}
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
       

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
      
}
 }

 

export default Datos_generales_torneo2_2