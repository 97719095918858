import React, {useContext,useState,useEffect} from 'react';
import {MyContext} from '../../contexts/MyContext';

//cargar mostrar
import axios from 'axios';


//Acordion
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Importing the Login & Register Componet
import Register  from './Register'

import { Steps, Step } from "react-step-builder";
import Datos_generales_torneo from "./Datos_generales_torneo";
import Datos_generales_torneo2 from "./Datos_generales_torneo2";
import Uno_puntos_juegos from "./Uno_puntos_juegos";



import Esquema_torneo from './Esquema_torneo';
import Fase2 from './Fase2';
import Fase3 from './Fase3';
import Fase4 from './Fase4';
import Fase5 from './Fase5';
import Fase6 from './Fase6';
import Fase7 from './Fase7';
import Fase8 from './Fase8';

import Puntos_de_juegos from "./Puntos_de_juegos";
import Puntos_juegos_guardar from './Puntos_juegos_guardar';

import Criterios_desempate from "./Criterios_desempate";
import Criterios_desempate_guardar from './Criterios_desempate_guardar';

import Cambios_planilla from "./Cambios_planilla";

import Reglamento_adicional from "./Reglamento_adicional";
import Reglamento_adicional_guardar from "./Reglamento_adicional_guardar";

import Jugadores_autorizados from "./Jugadores_autorizados";
import Jugadores_autorizados_guardar from "./Jugadores_autorizados_guardar";

import Criterios_tarjetas from "./Criterios_tarjetas";
import Criterios_tarjetas_guardar from "./Criterios_tarjetas_guardar";

import Goleador from "./Goleador";
import Goleador_guardar from "./Goleador_guardar";

import Pruebas2 from './Pruebas2';
import Pruebas3 from './Pruebas3';
import { useScrollTrigger } from '@material-ui/core';
import { CodeSharp } from '@material-ui/icons';


//Acordion
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));


  


function Crear_torneo() {

  //cargar mostrar

  const [color, setName] = useState([]);

  const peticionGet =async() =>{
    await axios.get(`https://www.deportes.controlsas.com/apiPlooy/Solicitar_empresa/`)
    .then((res) => {
       setName(res.data.color);
    }).catch(error=>{
      console.log(error);
    })
  
  }



useEffect(()=>{
    

   const interval = setInterval(() => {
     
      peticionGet();
      
    }, 3000);

  return () => setInterval(interval);

  },[])






 // agregar acordeon
    const classes = useStyles();

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {

        
    return (
      
        <div className="content-wrapper">
 
        <div className="content-header">
          <div className="container-fluid">
          
        <div className={classes.root}>
        
        <Accordion style={{ backgroundColor: color, }}>
        
          
         
          
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} >1. Datos Generales del Torneo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            
         
        <Step component={Datos_generales_torneo} />
        <Step component={Datos_generales_torneo2} />
        <Step component={Uno_puntos_juegos} />
      </Steps>
              
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>2. Esquema del Torneo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography  className={classes.root}>
            <Steps>
            <Step component={Esquema_torneo} />
            <Step component={Fase2} />
            <Step component={Fase3} />
            <Step component={Fase4} />
            <Step component={Fase5} />
            <Step component={Fase6} />
            <Step component={Fase7} />
            <Step component={Fase8} />
      </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading} >3. Jugadores Autorizados En Campo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Jugadores_autorizados} />
          <Step component={Jugadores_autorizados_guardar} />
        
      </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading} >4. Puntos de Juego - Puntos por W</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
           
            <Step component={Puntos_de_juegos} />
          <Step component={Puntos_juegos_guardar} />
        
      </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>5. Criterios de Desempate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
           {/* <Step component={Pruebas2} /> */}
           <Step component={Pruebas3} /> 
            <Step component={Criterios_desempate} />
          <Step component={Criterios_desempate_guardar} />
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography className={classes.heading}>6. Cambios en Planilla</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography className={classes.root}>
            <Steps>
            <Step component={Cambios_planilla} />
          
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


       

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography className={classes.heading}>7. Criterios de Tarjetas</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography className={classes.root}>
            <Steps>
            <Step component={Criterios_tarjetas} />
            <Step component={Criterios_tarjetas_guardar} />
          
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography className={classes.heading}>8. Goleador - Portero Menos Vencido</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Goleador} />
          <Step component={Goleador_guardar} />
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Typography className={classes.heading}>9. Reglamento Adicional</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Reglamento_adicional} />
          <Step component={Reglamento_adicional_guardar} />
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>
        
      </div> 

      </div>
      </div>
      </div>
        /*<div>
          
        <Steps>
        <Step component={Datos_generales_torneo} />
        <Step component={Datos_generales_torneo2} />
        <Step component={Uno_puntos_juegos} />
      </Steps>
      </div>*/

     
                 

    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Crear_torneo
