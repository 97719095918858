import React, {useContext,useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

//logo
import LogoTorneo1 from '../../img/finalizados.png';

import Reglamento from '../../img/reglamento_torneo_verde.png'
import Programacion from '../../img/programacion_verde.png'
import Grupos_gris from '../../img/grupos.png'
import Programar_juegos from '../../img/programar_juegos_verde.png'
import Tabla_posiciones from '../../img/tabla_posiciones_verde.png'

import LogoTorneo from '../../img/torneos.png'

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

//Agregar campo dinamico
const defaultState = {
  nombre: "",
};



//Agregar campo dinamico
function Row({ onChange, onRemove, datos_adicionales}) {
 
  return (
    <div>
      
      <div class="input-group">
      <input name="datos_adicionales"
        value={datos_adicionales}
        className="form-control" id="inputSuccess"
       
       /* style={{ "background-color": !input3 && "#D3F9FF" }}
        value={input3}
        onChange={({ target }) => setInput3(target.value)}*/
        placeholder="Datos adicionales"
      />
       
       <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" ><b>?</b></button>
                  </span>
     
       </div> 
   <br/>
     


    
    </div>
  );
}


 function Torneo_finalizado1(props) {

  //colores inputs y selects
  const [input, setInput] = useState();

  const [input2, setInput2] = useState();

// const [input3, setInput3] = useState();

const [select, setSelect] = useState();

const [select2, setSelect2] = useState();

const [select3, setSelect3] = useState();

const [select4, setSelect4] = useState();

const [select5, setSelect5] = useState();


 //agregar campo dinamico
 const [rows, setRows] = useState([defaultState]);

//Modal organizador
const [modalOrganizador, setModalOrganizador]=useState(false);

 //Modal
 const [modalNombreTorneo, setModalNombreTorneo]=useState(false);

 const [modalDatosAdicionales, setModalDatosAdicionales]=useState(false);

 

 //agregar campo dinamico
const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};


//Modal organizador

const abrirCerrarModalOrganizador=()=>{
  setModalOrganizador(!modalOrganizador);
}

//Modal

const abrirCerrarModalNombreTorneo=()=>{
  setModalNombreTorneo(!modalNombreTorneo);
}

const abrirCerrarModalDatosAdicionales=()=>{
  setModalDatosAdicionales(!modalDatosAdicionales);
}



 //agregar campo dinamico
const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  setRows(rows.concat(defaultState));
};
/*
 //agregar campo dinamico
const handleOnRemove = (index,e) => {
  e.preventDefault(); //esto previene que el form se mande.
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
*/

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {

    return (
        
     
             
      <div className="col-sm-12">
    
              <div class="card card-danger">
                  
             
                <div>
              <h3 class="card-title" ><button style={{backgroundColor:"transparent", border:"none"}}><img src={Reglamento} width="145px" /></button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <font size="1"><img src={LogoTorneo} width="20px" /> TORNEOS / &nbsp; 
                              <img src={LogoTorneo1} width="20px" /> FINALIZADOS 
                             
                             </font> </h3>
              
              </div>
                  
                
                  <div class="card-body">

                  <form>



    <div class="row">

    <div class="col-sm-12" >
     
    <div class="col-sm-3 float-right">

    <input 
       
       className="form-control" 
          
          placeholder="Buscar.."
          maxLength="50"
          style={{ "background-color": !input && "#D3F9FF" }}
          name="organizador"
       
        />

      </div>

<br/>
<br/>
      </div>

    <div class="col-sm-5">

    <div class="input-group">
      
    <input 
       
     className="form-control" 
        
        placeholder="Torneo chencho 2022"
        maxLength="50"
        style={{ "background-color": !input && "#D3F9FF" }}
        name="organizador"
        value={props.getState('organizador', '')} 
        onChange={props.handleChange}
        readOnly
        
       
      />
          
       </div> 

       </div> 
    

     <div class="col-sm-1">
     <div class="input-group">

     <input 
       
       className="form-control" 
          
          placeholder="COL"
          maxLength="50"
          style={{ "background-color": !input && "#D3F9FF" }}
          name="organizador"
          value={props.getState('organizador', '')} 
          onChange={props.handleChange}
          readOnly
          
         
        />
          
        
    
   
        </div>
      </div>
    
    
      <div class="col-sm-1">
        
      <div class="input-group">
      <input 
       
       className="form-control" 
          
          placeholder="TOL"
          maxLength="50"
          style={{ "background-color": !input && "#D3F9FF" }}
          name="organizador"
          value={props.getState('organizador', '')} 
          onChange={props.handleChange}
          readOnly
          
         
        />
        </div>
      </div>


      <div class="col-sm-1">
        
      <div class="input-group">
      <input 
       
       className="form-control" 
          
          placeholder="IBA"
          maxLength="50"
          style={{ "background-color": !input && "#D3F9FF" }}
          name="organizador"
          value={props.getState('organizador', '')} 
          onChange={props.handleChange}
          readOnly
          
         
        />
        </div>
      </div>


      <div class="col-sm-1">
        
      <div class="input-group">
      <input 
       
       className="form-control" 
          
          placeholder="FUT"
          maxLength="50"
          style={{ "background-color": !input && "#D3F9FF" }}
          name="organizador"
          value={props.getState('organizador', '')} 
          onChange={props.handleChange}
          readOnly
          
         
        />
        </div>
      </div>


      <div class="col-sm-1">
        
      <div class="input-group">
      <input 
       
       className="form-control" 
          
          placeholder="F11"
          maxLength="50"
          style={{ "background-color": !input && "#D3F9FF" }}
          name="organizador"
          value={props.getState('organizador', '')} 
          onChange={props.handleChange}
          readOnly
          
         
        />
        </div>
      </div>


      <div class="col-sm-1">
        
      <div class="input-group">
      <input 
       
       className="form-control" 
          
          placeholder="F"
          maxLength="50"
          style={{ "background-color": !input && "#D3F9FF" }}
          name="organizador"
          value={props.getState('organizador', '')} 
          onChange={props.handleChange}
          readOnly
          
         
        />
        </div>
      </div>



      

        <div class="col-sm-1">
        
        <div class="input-group">
        <button className="btn btn-success"  onClick={props.next}>VER</button>
          </div>
        </div>


     
  
      </div>

    

    <br />


    <div class="row">

<div class="col-sm-5">

<div class="input-group">
  
<input 
   
 className="form-control" 
    
    placeholder="Torneo Juvenil chencho 2022"
    maxLength="50"
    style={{ "background-color": !input && "#D3F9FF" }}
    name="organizador"
    value={props.getState('organizador', '')} 
    onChange={props.handleChange}
    readOnly
    
   
  />
      
   </div> 

   </div> 


 <div class="col-sm-1">
 <div class="input-group">

 <input 
   
   className="form-control" 
      
      placeholder="COL"
      maxLength="50"
      style={{ "background-color": !input && "#D3F9FF" }}
      name="organizador"
      value={props.getState('organizador', '')} 
      onChange={props.handleChange}
      readOnly
      
     
    />
      
    


    </div>
  </div>


  <div class="col-sm-1">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="TOL"
      maxLength="50"
      style={{ "background-color": !input && "#D3F9FF" }}
      name="organizador"
      value={props.getState('organizador', '')} 
      onChange={props.handleChange}
      readOnly
      
     
    />
    </div>
  </div>


  <div class="col-sm-1">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="IBA"
      maxLength="50"
      style={{ "background-color": !input && "#D3F9FF" }}
      name="organizador"
      value={props.getState('organizador', '')} 
      onChange={props.handleChange}
      readOnly
      
     
    />
    </div>
  </div>


  <div class="col-sm-1">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="FUT"
      maxLength="50"
      style={{ "background-color": !input && "#D3F9FF" }}
      name="organizador"
      value={props.getState('organizador', '')} 
      onChange={props.handleChange}
      readOnly
      
     
    />
    </div>
  </div>


  <div class="col-sm-1">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="F8"
      maxLength="50"
      style={{ "background-color": !input && "#D3F9FF" }}
      name="organizador"
      value={props.getState('organizador', '')} 
      onChange={props.handleChange}
      readOnly
      
     
    />
    </div>
  </div>


  <div class="col-sm-1">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="F"
      maxLength="50"
      style={{ "background-color": !input && "#D3F9FF" }}
      name="organizador"
      value={props.getState('organizador', '')} 
      onChange={props.handleChange}
      readOnly
      
     
    />
    </div>
  </div>



    <div class="col-sm-1">
    
    <div class="input-group">
    <button className="btn btn-success"  onClick={props.next}>VER</button>
      </div>
    </div>


 

  </div>


     
    
<Modal isOpen={modalNombreTorneo}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Nombre del torneo</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Asigna Un Nombre al Torneo (No debe Contener Mas de 40 Caracteres)</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalNombreTorneo()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* organizador */}
<Modal isOpen={modalOrganizador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Organizador / Sucursal</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Sub Nombre o Sucursal, en el caso de que la empresa tenga un mismo torneo pero distintas sucursales
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalOrganizador()}>Aceptar</button>
 </ModalFooter>
</Modal>



<Modal isOpen={modalDatosAdicionales}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b> Datos adicionales </b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
   <h3><b>Para el caso de zonas geográficas no registradas en la base de datos.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalDatosAdicionales()}>Aceptar</button>
 </ModalFooter>
</Modal>

  </form>
                  </div>
                 
                </div>
    
    
                </div>

             

                
    
    
         
         

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
     
}
 }

export default Torneo_finalizado1