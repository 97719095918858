import React, {useContext,useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import Escudo6 from '../../img/escudo.jpg';

//Agregar campo dinamico
const defaultState = {
  nombre: "",
};



//Agregar campo dinamico
function Row({ onChange, onRemove, datos_adicionales}) {
 
  return (
    <div>
      
      <div class="input-group">
      <input name="datos_adicionales"
        value={datos_adicionales}
        className="form-control" id="inputSuccess"
       
       /* style={{ "background-color": !input3 && "#D3F9FF" }}
        value={input3}
        onChange={({ target }) => setInput3(target.value)}*/
        placeholder="Datos adicionales"
      />
       
       <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" ><b>?</b></button>
                  </span>
     
       </div> 
   <br/>
     


    
    </div>
  );
}


 function Datos_generales_torneo_2_fases(props) {

  //colores inputs y selects
  const [input, setInput] = useState();

  const [input2, setInput2] = useState();

// const [input3, setInput3] = useState();

const [select, setSelect] = useState();

const [select2, setSelect2] = useState();

const [select3, setSelect3] = useState();

const [select4, setSelect4] = useState();

const [select5, setSelect5] = useState();


 //agregar campo dinamico
 const [rows, setRows] = useState([defaultState]);

//Modal organizador
const [modalOrganizador, setModalOrganizador]=useState(false);

 //Modal
 const [modalNombreTorneo, setModalNombreTorneo]=useState(false);

 const [modalDatosAdicionales, setModalDatosAdicionales]=useState(false);

 

 //agregar campo dinamico
const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};


//Modal organizador

const abrirCerrarModalOrganizador=()=>{
  setModalOrganizador(!modalOrganizador);
}

//Modal

const abrirCerrarModalNombreTorneo=()=>{
  setModalNombreTorneo(!modalNombreTorneo);
}

const abrirCerrarModalDatosAdicionales=()=>{
  setModalDatosAdicionales(!modalDatosAdicionales);
}



 //agregar campo dinamico
const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  setRows(rows.concat(defaultState));
};
/*
 //agregar campo dinamico
const handleOnRemove = (index,e) => {
  e.preventDefault(); //esto previene que el form se mande.
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
*/

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {

    return (
        
     
             
      <div className="col-sm-12">
    
              <div class="card">
              
                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    <div class="row">

    <div class="col-sm-3">

    <div class="input-group">
      
    <h6> Programador de Juegos Fase: 1
 </h6>
      
     
       </div> 

       </div> 
    

     <div class="col-sm-3">
     <div class="input-group">

      
          
     <select    class="form-control" 
         name="filtro1"
         >
       <option value='no' selected>.</option>
      <option value="Todos los juegos">Todos los juegos</option>
      <option value="Juegos Programados Cerrados">Juegos Programados Cerrados</option>
      <option value="Juegos Programados Sin Cerrar">Juegos Programados Sin Cerrar</option>
      <option value="Juegos Sin Programar">Juegos Sin Programar </option>
    </select> 
    
   
        </div>
      </div>
    
    
      <div class="col-sm-2">
     <div class="input-group">         
     <select    class="form-control" 
         name="filtro2">
       <option value='no' selected>.</option>
      <option value="Grupo 1">Grupo 1</option>
      <option value="Grupo 2">Grupo 2</option>
      <option value="Grupo 3">Grupo 3</option>
    </select> 
    
   
        </div>
      </div>


      <div class="col-sm-2">
     <div class="input-group">

      
          
     <select    class="form-control" 
         name="filtro3"
         >
       <option value='no' selected>.</option>
      <option value="Todos los equipos">Todos los equipos</option>
      <option value="Juegos Programados Cerrados">Amigos del pino</option>
      <option value="Juegos Programados Sin Cerrar">La rocka</option>
      <option value="Juegos Sin Programar">Jimmy FC </option>
    </select> 
    
   
        </div>
      </div>


      <div class="col-sm-2">
     <div class="input-group">
    <button className='btn btn-info'>BUSCAR <i class="fas fa-cog fa-spin"></i></button>
   
        </div>
      </div>



     
  
      </div>

<br />

      <div class="row">
          
     
        
      <div class="col-sm-3">

<button class="input-group border border-warning" style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6> &nbsp;&nbsp;
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</button> 



    </div>


    <div class="col-sm-1"  style={{paddingLeft:"30px"}}>
     <h6>VS</h6>
    </div>

    <div class="col-sm-3">

<button class="input-group border border-warning" style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Jimmy FC</font></h6> &nbsp;&nbsp;
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</button> 

    </div>

      
    



      <div class="col-sm-2">
     

    <div class="input-group">
      
    <input 
       
     className="form-control" 
        placeholder="Fecha"
        maxLength="50"
      />
    
     
       </div> 

</div>


<div class="col-sm-1">
     

    <div class="input-group">
      
    <input 
       
     className="form-control" 
        placeholder="Hora"
        maxLength="50"
      />
    
     
       </div> 

</div>


<div class="col-sm-1">
     

    <div class="input-group">
      
    <input 
       
     className="form-control" 
        placeholder="Lugar"
        maxLength="50"
      />
    
     
       </div> 

</div>


<div class="col-sm-1">
     

    <div class="input-group">
        <button className='btn btn-info btn-circle' 
            style={{width: "40px",
                backgroundColor:"#9BF566",
                height: "40px",
                padding: "6px",
                borderRadius: "15px",
                textAlign: "center",
                fontSize: "12px",
                border:"none"
                }}><i class="fas fa-save fa-2x"></i></button>
    
     
       </div> 

</div>




         
    </div>

    <br />

    <div class="row">
          
     
        
          <div class="col-sm-3">
    
    <button class="input-group border border-warning" style={{ borderRadius:"10px"}}>
    <img src={Escudo6} width="50px" height="50px" />
    <h6> <font size="3"> Los Amigos Del Pino 2</font></h6> &nbsp;&nbsp;
    <div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>
    
    
    </button> 
    
    
    
        </div>
    
    
        <div class="col-sm-1"  style={{paddingLeft:"30px"}}>
         <h6>VS</h6>
        </div>
    
        <div class="col-sm-3">
    
    <button class="input-group border border-warning" style={{ borderRadius:"10px"}}>
    <img src={Escudo6} width="50px" height="50px" />
    <h6> <font size="3"> Jimmy FC 2</font></h6> &nbsp;&nbsp;
    <div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>
    
    
    </button> 
    
        </div>
    
          
        
    
    
    
          <div class="col-sm-2">
         
    
        <div class="input-group">
          
        <input 
           
         className="form-control" 
         value="11/03/22"
         maxLength="50"
         style={{backgroundColor:"#66D6F5"}}
         readOnly
          />
        
         
           </div> 
    
    </div>
    
    
    <div class="col-sm-1">
         
    
        <div class="input-group">
          
        <input 
           
         className="form-control" 
         value="13:00"
         maxLength="50"
         style={{backgroundColor:"#66D6F5"}}
         readOnly
          />
        
         
           </div> 
    
    </div>
    
    
    <div class="col-sm-1">
         
    
        <div class="input-group">
          
        <input 
           
         className="form-control" 
            value="centro"
            maxLength="50"
            style={{backgroundColor:"#66D6F5"}}
            readOnly
          />
        
         
           </div> 
    
    </div>

    <div class="col-sm-1">
     

    <div class="input-group">
        <button className='btn btn-info btn-circle' 
            style={{width: "40px",
                backgroundColor:"black",
                color:"white",
                height: "40px",
                padding: "6px",
                borderRadius: "15px",
                textAlign: "center",
                fontSize: "12px",
                border:"none"
                }}><i class="fas fa-pencil-alt fa-2x"></i></button>
    
     
       </div> 

</div>
    
    
   
    
    
    
    
             
        </div>

        <br />

        <div class="row">
          
     
        
          <div class="col-sm-3">
    
    <button class="input-group border border-warning" style={{ borderRadius:"10px"}}>
    <img src={Escudo6} width="50px" height="50px" />
    <h6> <font size="3"> Los Amigos Del Pino 3</font></h6> &nbsp;&nbsp;
    <div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>
    
    
    </button> 
    
    
    
        </div>
    
    
        <div class="col-sm-1"  style={{paddingLeft:"30px"}}>
         <h6>VS</h6>
        </div>
    
        <div class="col-sm-3">
    
    <button class="input-group border border-warning" style={{ borderRadius:"10px"}}>
    <img src={Escudo6} width="50px" height="50px" />
    <h6> <font size="3"> Jimmy FC 3</font></h6> &nbsp;&nbsp;
    <div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>
    
    
    </button> 
    
        </div>
    
          
        
    
    
    
          <div class="col-sm-2">
         
    
        <div class="input-group">
          
        <input 
           
         className="form-control" 
         value="10/02/22"
         maxLength="50"
         style={{backgroundColor:"#9BF566"}}
         readOnly
          />
        
         
           </div> 
    
    </div>
    
    
    <div class="col-sm-1">
         
    
        <div class="input-group">
          
        <input 
           
         className="form-control" 
         value="11:00"
         maxLength="50"
         style={{backgroundColor:"#9BF566"}}
         readOnly
          />
        
         
           </div> 
    
    </div>
    
    
    <div class="col-sm-2">
         
    
        <div class="input-group">
          
        <input 
           
         className="form-control" 
            value="Cancha 2"
            maxLength="50"
            style={{backgroundColor:"#9BF566"}}
            readOnly
          />
        
         
           </div> 
    
    </div>

        
             
        </div>      



      

    
<Modal isOpen={modalNombreTorneo}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Nombre del torneo</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Asigna Un Nombre al Torneo (No debe Contener Mas de 40 Caracteres)</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalNombreTorneo()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* organizador */}
<Modal isOpen={modalOrganizador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Organizador / Sucursal</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Sub Nombre o Sucursal, en el caso de que la empresa tenga un mismo torneo pero distintas sucursales
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalOrganizador()}>Aceptar</button>
 </ModalFooter>
</Modal>



<Modal isOpen={modalDatosAdicionales}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b> Datos adicionales </b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
   <h3><b>Para el caso de zonas geográficas no registradas en la base de datos.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalDatosAdicionales()}>Aceptar</button>
 </ModalFooter>
</Modal>

  </form>
                  </div>
                 
                </div>
    
    
                </div>

             

                
    
    
         
         

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
     
}
 }

export default Datos_generales_torneo_2_fases