import React, {useContext} from 'react'
import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register  from './Register'

import { Steps, Step } from "react-step-builder";
import Empresajuridica1 from "./Empresajuridica1";
import Empresajuridica2 from "./Empresajuridica2";
import Empresajuridicaresumen from "./Empresajuridicaresumen";


function Empresajuridica() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            
      <div>
        <Steps>
        <Step component={Empresajuridica1} />
        <Step component={Empresajuridica2} />
        <Step component={Empresajuridicaresumen} />
      </Steps>
      </div>
                 

    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Empresajuridica
