import React, {useContext,useState} from 'react'
import {MyContext} from '../../contexts/MyContext'

//import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'


function Empresa(props) {

  const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            email:'',
            password:'',
            pais:'',
            departamento:'',
            ciudad:'',
            tipo_usuario:'empresa',
            apellidos:'',
            tdoc:'',
            documento:'',
            fechanacimiento:'',
            genero:'',
            telefono:'',
            comercio_natural:'',
            nacionalidad:'',
            direccion:'',
            ciudad_nacionalidad:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

  

   const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        
          <div className="col-sm-12">



          <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">PERSONA NATURAL</h3>
              </div>
            
              <div class="card-body">
              <form onSubmit={submitForm}>
                  <div class="row">
                  <label>En que lugar prestas tus servicios</label>

                    <div class="col-sm-2">
              
                    <div class="form-group">

                <select name="pais" class="form-control" value={state.userInfo.pais} onChange={onChangeValue}>
               <option value='no' selected>Pais</option>
                <option value="Colombia">Colombia</option>
             </select>     
                      </div>
                    </div>


                    <div class="col-sm-2">
                      <div class="form-group">
                        
                      <select name="departamento" class="form-control" value={state.userInfo.departamento} onChange={onChangeValue}>
     <option value='no' selected>Departamento</option>
                    <option value="Tolima">Tolima</option>
                    
                  </select>  

                      </div>
                    </div>
                  
                  
                    <div class="col-sm-2">
                      
                      <div class="form-group">
                      <select name="ciudad" class="form-control" value={state.userInfo.ciudad} onChange={onChangeValue}>
     <option value='no' selected>Ciudad</option>
                    <option value="Ibague">Ibague</option>
                    
                  </select>  
                      </div>
                    </div>



                    <div class="col-sm-3">

                    <div class="form-group">
                    
                    <input name="name" required type="text" className="form-control" value={state.userInfo.name} onChange={onChangeValue} placeholder="Nombres"/>
                  </div>
                  </div>

                  <div class="col-sm-3">

                    <div class="form-group">
                    
                    <input type="text" name="apellidos" class="form-control" id="inputSuccess" value={state.userInfo.apellidos} onChange={onChangeValue} placeholder="Apellidos" />
                  </div>
                  </div>
                
                    </div>


                    <div class="row">
                    <div class="col-sm-2">
                    <select name="tdoc" class="form-control" value={state.userInfo.tdoc} onChange={onChangeValue}>
               <option value='no' selected>T.doc</option>
                <option value="Cedula">Cedula</option>
                <option value="DNI">DNI</option>
                <option value="Tarjeta de identidad">Tarjeta de identidad</option>
             </select>     

                    </div>


                    <div class="col-sm-2">
                    <input type="number" name="documento"  className="form-control" id="inputSuccess" value={state.userInfo.documento} onChange={onChangeValue} placeholder="N° documento" />

                    </div>

                    <div class="col-sm-3">
                    <div class="form-group">
                   
                    <div class="input-group">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                        <label class="custom-file-label" for="exampleInputFile">Adjuntar</label>
                      </div>
                      
                    </div>


  

                  </div>

                  

                    </div>

                   < div class="col-sm-3">
                   <label>Fecha de nacimiento</label>
                   <input type="date" name="fechanacimiento" class="form-control is-warning" id="inputSuccess" value={state.userInfo.fechanacimiento} onChange={onChangeValue} placeholder="Fecha nacimiento" />

                    </div>

                    < div class="col-sm-2">
                   <label>Genero</label>
                   <select name="genero" class="form-control" value={state.userInfo.genero} onChange={onChangeValue}>
               <option value='no' selected>Seleccionar</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="Sin definir">Sin definir</option>
               
             </select>     

                    </div>
                    
                  </div>

                  <br />

                  <div class="row">
      

                    <div class="col-sm-3">
              
                    <div class="form-group">

                    <input type="text" name="nacionalidad" class="form-control" id="inputSuccess"value={state.userInfo.nacionalidad} onChange={onChangeValue}  placeholder="Nacionalidad" />
                      </div>
                    </div>


              <div class="col-sm-4">
              
              <div class="form-group">

              <input type="text" name="direccion" className="form-control" required  value={state.userInfo.direccion} onChange={onChangeValue} placeholder="Dirección"/>
                </div>
              </div>

              <div class="col-sm-3">
              
              <div class="form-group">

              <input type="text" name="ciudad_nacionalidad" className="form-control" required  value={state.userInfo.ciudad_nacionalidad} onChange={onChangeValue} placeholder="Ciudad"/>
                </div>
              </div>


                    </div>


                    <div class="row">
                  <label>Datos Para Validar la Cuenta, Recuperar contraseñas y Contactarte.</label>

                    <div class="col-sm-4">
              
                    <div class="form-group">

                    <input type="number" name="telefono" class="form-control" id="inputSuccess"value={state.userInfo.telefono} onChange={onChangeValue}  placeholder="N° celular principal" />
                      </div>
                    </div>


              <div class="col-sm-4">
              
              <div class="form-group">

              <input type="email" name="email" className="form-control is-valid" required  value={state.userInfo.email} onChange={onChangeValue} placeholder="Email"/>
                </div>
              </div>


                    </div>


                                    


                    <div class="row">
                  
              <div class="col-sm-5">
              
              <div class="form-group">

              <div class="input-group">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                        <label class="custom-file-label" for="exampleInputFile">Sube Tu Foto o Logo Corporativo</label>
                      </div>
                      
                    </div>
                </div>
              </div>


                    </div>



                    <div class="row">
                  <label>Para Establecimiento Comercial P Natural. </label>

                    <div class="col-sm-5">
              
                    <div class="form-group">
                    <input type="text" name="comercio_natural" class="form-control" id="inputSuccess"value={state.userInfo.comercio_natural} onChange={onChangeValue}   placeholder="Nombre comercial o de establecimiento" />
            
                      </div>
                    </div>


              <div class="col-sm-4">
              
              <div class="form-group">

              <div class="input-group">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                        <label class="custom-file-label" for="exampleInputFile">Adjuntar documento RUT</label>
                      </div>
                      
                    </div>
                </div>
              </div>


                    </div>


                    <div class="row">
                  <label><h3>Crear Contraseña</h3>
Mínimo 6 Caracteres, 1 Letra Mayúscula, 
1 Numero
</label>

                    <div class="col-sm-4">
              
                    <div class="form-group">

                    <input type="password" name="password" class="form-control is-valid" required type="password" value={state.userInfo.password} onChange={onChangeValue} placeholder="Contraseña" />
                      </div>
                    </div>


              <div class="col-sm-4">
              
              <div class="form-group">

              <input type="password" class="form-control is-valid" id="inputSuccess" placeholder="Repetir contraseña" />
                </div>
              </div>


                    </div>

                    <div class="col-sm-12">

                        
                       
<p><b>{errorMsg}{successMsg}</b></p>

   





 </div>
 
 



 <button className="btn btn-primary" type="submit">Registrar</button>

                    <label>Recuerda los Datos para Iniciar sesión:
ID: 123456789
PASSWORD: kbBXILBIBX456</label>

                  


                </form>
              </div>
             
            </div>


            

      </div>

      </div>
      </div>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Empresa
