import React, {useContext, useState} from 'react'
import {MyContext} from '../contexts/MyContext'

import '../index.css';
import logo from '../img/logo.gif'; 

function Login(){

    const {toggleNav,loginUser,isLoggedIn} = useContext(MyContext);

    const initialState = {
        userInfo:{
            email:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }



    return(
        <div className="hold-transition login-page FondoLogin">
        <div className="login-box">
        <div className="card card-outline card-primary">
        <div className="card-header text-center">
        <center><img src={logo}  width="120px"/> </center>
       
            </div>

            <div className="card-body">

            <form  onSubmit={submitForm} noValidate>

             <div className="input-group mb-3">
             <input name="email" type="email" required placeholder="Email" className="form-control" value={state.userInfo.email} onChange={onChangeValue} />
              <div className="input-group-append">
               <div className="input-group-text">
          <span className="fas fa-envelope"></span>
         </div>
      </div>
      </div>

      <div className="input-group mb-3">
      <input name="password" type="password"
       className="form-control"
       placeholder="Password" 
       required
       value={state.userInfo.password} onChange={onChangeValue}
       />
      <div className="input-group-append">
        <div className="input-group-text">
          <span className="fas fa-lock"></span>
        </div>
      </div>
    </div>

    <div className="row">
    
      <div className="col-12">
      
      {errorMsg}
    {successMsg}
         
      
        <button
        //onClick={handleLogin}
        //disabled =  {espera}
        type="submit" type="submit" className="btn btn-primary btn-block">INGRESAR</button>
      </div>
     
    </div>

                

                </form>





            </div>
        </div>
        </div>
    </div>
    );
}

export default Login;