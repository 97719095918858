import { indigo } from "@material-ui/core/colors";
import './disabled.css';
import React, { useContext, useState } from "react";

//input dinamico

import Row from "./Row2";

let initialState = {
  first: null,
  arraySelect: [],
  arraySelect2: []
};

function Fase8(props) {
  //input dinamico

  const [rows, setRows] = useState([]);
  const [initialeRow, setInitialRow] = useState({ nombre: "" });
  //SELECT2
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const updateCount = (event) => {
    setInitialRow({ nombre: event.target.value });
  };

  const handleOnChange = (index, value) => {
    const copy = rows.map((e, i) => {
      if (i === index) {
        e.nombre = value;
      }
      return e;
    });
    setRows([...copy]);
  };
  

  ////////////////////////////////////


  const [index, setIndex] = useState(2);

  //division
  const [number, setNumber] = useState();

  //Mostrar 1
  const [modouno, setModoUno] = useState(null);

  //Mostrar 2
  const [mododos, setModoDos] = useState(null);

  //Mostrar 3
  const [modotres, setModoTres] = useState(null);

  //Mostrar 4
  const [modocuatro, setModoCuatro] = useState(null);

  //Mostrar 5
  const [modoboton, setModoBoton] = useState(null);

  //divisores
  const [input, setInput] = useState(global.multi7);
  const [values, setValues] = useState([]);

  //const [numberIni2x, setNumberIni2x] = useState(0)
  //const [tipo_cancha, setTipo_cancha] = useState(0)

  //multiplicacion select -1

  const [input_multi, setInput_multi] = useState();
  const [values_multi, setValues_multi] = useState([]);

   //botonguardar1
   const [boton_guardar1, setBoton_guardar1] = useState(null);

   //botonActivar
   const [boton_activar, setBoton_activar] = useState(true);
 
   //guardar uno
   const [guardar_uno, setGuardar_uno] = useState(true);
 
   //guardar
   const [guardar, setGuardar] = useState(false);
 
   //fase2
   const [fase2, setFase2] = useState(null);

    //select1 deshabilitar
    const [selectDisabled, setSelectDisabled] = useState(false);

    //select2 deshabilitar
    const [selectDisabled2, setSelectDisabled2] = useState(false);
  

  const getMods = (number) => {
    let aux = [];
    for (let x = 1; x <= number; x++) {
      if (number % x === 0) {
        aux.push(x);
      }
    }
    return aux;
  };

  //multiplicacion select -1
  const handleClick_multi = (event) => {
    event.preventDefault();
    if (+input_multi > 0) {
      setValues_multi([
        +input_multi - 1,
        (+input_multi - 1) * 2,
        (+input_multi - 1) * 2 * 2
      ]);
      setModoTres(true);
      // setInput_multi('')
    }
  };

  //modo cuatro
  const handleClick_cuatro = (event) => {
    event.preventDefault();
    setModoCuatro(true);
    setGuardar_uno(false);
    setGuardar(true);
    console.log(global.nuevo7 + "MI NOMBREx" + global.select7  +  "MI NOMBRE2" );
    
 global.multi7 = global.nuevo7 * global.select7;
  
  console.log(global.multi7 + "resultado");
  };

  //divisores
  const handleClick = (e) => {
    e.preventDefault(); //esto previene que el form se mande.
    if (+input > 0 && global.multi5 > 1) {
      if(global.multi5>1){
      const mods = getMods(+input).filter((x) => x !== +input);
      setValues(mods);
      //Activar uno
      setModoUno(true);
    }
  }else{

   
    alert("SOLO SE PUEDE GUARDAR HASTA LA FASE 6");
  }
    // setInput('')
  };

  //modo dos

  const elmododos = (e) => {
    e.preventDefault(); //esto previene que el form se mande.
    //Activar dos
    const { numberIni2 } = number;
    console.log(numberIni2 + "okok2");
    global.select7= numberIni2;

    if (numberIni2 > 0) {
      let inputValue = parseInt(input);
      let twoValue = parseInt(numberIni2);
      let result = inputValue / twoValue;
      setInput_multi(result);

      setModoDos(true);
    }
  };

   


  const division2 = (event) => {
    event.preventDefault(); //esto previene que el form se mande.
    //Activar dos
    const { numberIni3 } = number;
    console.log(numberIni3 + "division2");

   global.division2020= numberIni3;
    console.log(global.division2020 + "divi2020");

    const { name, value } = event.target;

     global.isArray = getArray(numberIni3);
     
     setarraySelect([...arraySelect, global.isArray]);
    setInitialRow({ nombre: initialeRow.nombre * 1 + 1 }) ;
    setRows([...rows, initialeRow]);
    
    setNumber({ ...number, [name]: value });
    setInitialRow({ nombre: event.target.value });

    
  };


  const handleInput_division = (event) => {
    // console.log(numberIni2+"okok")

    const { name, value } = event.target;
    //setSelect(value)
    
    setNumber({ ...number, [name]: value });

   //setInitialRow({ nombre: event.target.value });
  };



  const handleInput_division2 = (event) => {

    

   
    const { numberIni3 } = number;
    console.log(numberIni3 + "division2x");

    const { name, value } = event.target;
    
    setSelect(value)
    
    setNumber({ ...number, [name]: value });
    setSelect(event.target.value);
    setSelectDisabled(true);
    setInitialRow({ nombre: value * 1 + 1});

    
  
   // setSelect(e.target.value)
  /* const { name, value } = event.target;
   console.log(event.target);
   console.log(name);
   console.log(value);
   setNumber({ ...number, [name]: value });*/

  };

  const [arraySelect, setarraySelect] = useState(initialState.arraySelect);
  //select2
  const [arraySelect2, setarraySelect2] = useState(initialState.arraySelect2);

  const [numberIni, setnumberIni] = useState(initialState.first);

  const [numberIni2, setnumberIni2] = useState(initialState.first);
  const [numberIni3, setnumberIni3] = useState(initialState.first);

  const getArray = (value) => {
    let arr = [];
    {
      let reco = Math.round(numberIni - parseInt(value));
      console.log(reco);

      if (parseInt(value) == numberIni) {
        return false;
      }
      Array(reco)
        .fill(1)
        .map((value2, key) => {
          arr.push(parseInt(value) + parseInt(key + 1));
        });
    }
    return arr;
  };




  const setSelect = (value) => {
    //debugger;
      let isArray = getArray(value);
      if (isArray) {
        console.log([...arraySelect, isArray])
        setarraySelect([...arraySelect, isArray]);
        setModoBoton(true);
       
      }
      if (isArray) {
        setInitialRow({ nombre: value});
        setRows([...rows, { nombre: value}]);
      }
     
  };

//segundo array
  const getArray2 = (value) => {
    const numValue2 = parseInt(value, 10);
    const arr2 = [];
    for (let i = 0; i < numValue2 - 1; i++) {
      arr2.push(numValue2 - i - 1);
    }
    if (arr2.length) {
      return arr2;
    }
  };

  //segundo select
  const setSelect_select2 = (value) => {
    let mi=global.division2020;
    let isArray2 = getArray2(value);
    if (isArray2) {
       
      setarraySelect2([...arraySelect2, isArray2]);
     let mi2= mi;
     let mi3= 50;
     
      console.log(mi + "miarray")

      //setInitialRow({ nombre: initialeRow * 1 + 1 });
       // setRows([...rows, { nombre: mi2 * 1 + 1}]);

       console.log(initialeRow.nombre + "NOMBREEEEEE");
       console.log(initialeRow + " SIN NOMBREEEEEE")
     
     setInitialRow({ nombre: initialeRow.nombre * 1 + 1 }) ;
     
      setRows([...rows, initialeRow]);


    }
    
  };

  //console.log(initialeRow.nombre - 1 + " Nuevo")
  console.log(global.nuevo7=initialeRow.nombre - 1)

  //SEGUNDO SELECT
  const handleChange = (index, value) => {
    const tmpSelectedNumbers = [...selectedNumbers];
    tmpSelectedNumbers[index] = value;
    setSelectDisabled2(true)
    setSelectedNumbers(tmpSelectedNumbers);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(values_multi + "tipo de cancha");

    setnumberIni(event.target.numberIni.value -1);
    setnumberIni2(event.target.numberIni2.value - 1);

    
    
  };



  const botonguardar_uno = (e) =>{
    e.preventDefault();
    setFase2(true);
    setGuardar_uno(false);

    console.log(global.nuevo7 + "MI NOMBREx" + global.select7  +  "MI NOMBRE2" );
    
 global.multi7 = global.nuevo7 * global.select7;
  
  console.log(global.multi7 + "resultado");

  }


  const botonguardar = (e) =>{
    e.preventDefault();
    alert("FASE 8 GUARDADA");
    setFase2(true);
    setGuardar(false);

  }

  const resetForm = () => {

    setRows([]);
    setarraySelect([]);
    setarraySelect2([]);

    setSelectDisabled(false);
    setSelectDisabled2(false);

    setModoCuatro(null)
    setModoBoton(null)
    document.getElementById("firstSelect").getElementsByTagName('option')[0].selected = 'selected'
 
  /* 
    setRows([]);
    setarraySelect([]);
    setarraySelect2([]);
    setSelectDisabled(false);
    setSelectDisabled2(false);
*/
   
    
  };

  const resetFormtodo = () => {
      setnumberIni(null);
      setnumberIni2(null);
  
      setRows([]);
      setarraySelect([]);
      setarraySelect2([]);
     
      setSelectDisabled(false);
      setSelectDisabled2(false);
     
      setModoCuatro(null)
    setModoBoton(null)
    document.getElementById("firstSelect").getElementsByTagName('option')[0].selected = 'selected'
    
   
   

    };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div class="row">
           
          <label className="badge badge-info"><h6><b>Torneo Lukas Mayores de 40 Años</b></h6></label>
         
          <div class="row">
         
          <div class="col-sm-4">
          
            <div class="input-group " style={{top:"10px"}}>
      

          <label><font size="2">Cantidad de equipos clasificados a la <b>fase 2:</b></font> &nbsp; </label><input type="text" readonly="readonly"  class="form-control btn btn-warning rounded-circle" style={{width: "75%"}} name="ciudad2" value={global.multi} onChange={props.handleChange} style={{top:"-8px"}}/>
          <br />
  
         
       
          <label><font size="2">Cantidad de equipos clasificados a la <b>fase 3:</b></font> &nbsp; </label><input type="text" readonly="readonly"  class="form-control btn btn-info rounded-circle" style={{width: "75%"}} name="ciudad2" value={global.multi2} onChange={props.handleChange} style={{top:"-8px"}}/>
<br />
<label><font size="2">Cantidad de equipos clasificados a la <b>fase 4:</b></font> &nbsp; </label><input type="text" readonly="readonly"  class="form-control btn btn-seconddary rounded-circle" style={{width: "75%"}} name="ciudad2" value={global.multi3} onChange={props.handleChange} style={{top:"-8px"}}/>
<br />

<label><font size="2">Cantidad de equipos clasificados a la <b>fase 5:</b></font> &nbsp; </label><input type="text" readonly="readonly"  class="form-control btn btn-warning rounded-circle" style={{width: "75%"}} name="ciudad2" value={global.multi4} onChange={props.handleChange} style={{top:"-8px"}}/>
<br />

<label><font size="2">Cantidad de equipos clasificados a la <b>fase 6:</b></font> &nbsp; </label><input type="text" readonly="readonly"  class="form-control btn btn-seconddary rounded-circle" style={{width: "75%"}} name="ciudad2" value={global.multi5} onChange={props.handleChange} style={{top:"-8px"}}/>
<br />

<label><font size="2">Cantidad de equipos clasificados a la <b>fase 7:</b></font> &nbsp; </label><input type="text" readonly="readonly"  class="form-control btn btn-seconddary rounded-circle" style={{width: "75%"}} name="ciudad2" value={global.multi6} onChange={props.handleChange} style={{top:"-8px"}}/>
<br />

<label><font size="2">Cantidad de equipos clasificados a la <b>fase 8:</b></font> &nbsp; </label><input type="text" readonly="readonly"  class="form-control btn btn-seconddary rounded-circle" style={{width: "75%"}} name="ciudad2" value={global.multi7} onChange={props.handleChange} style={{top:"-8px"}}/>
<br />


<br />
    </div>      
</div>


<div class="col-sm-12 badge badge-warning">
      <h6> <b>FASE 8</b></h6>
      </div>
      <br />
     
</div>


          <div class="col-sm-3">
          <br />
            <h6>1 - # Equipos Clasificados</h6>
            <div class="input-group ">
              <input
                placeholder="0"
                type="number"
                readonly="readonly"
                className="form-control"
                value={input}
                onChange={(event) => setInput(event.target.value)}
              />
              <span class="input-group-append">
                <button
                  type="button"
                  class="btn btn-success input-group-append "
                  onClick={handleClick}
                >
                  <i class="far fa-save"></i>
                </button>
              </span>
              <br />
            </div>
          </div>

          {modouno ? (
            <div class="col-sm-3">
               <br />
              <h6>2 - # Grupos</h6>

              <div class="input-group ">
                <select
                  name="numberIni2"
                  class="form-control"
                  onChange={ handleInput_division }
                >
                  <option value="0" selected>
                    Seleccione
                  </option>
                  {values.map((item, index) => (
                    <option key={index} value={item}>
                      {item} 
                    </option>
                  ))}
                </select>

                <br />
                <button
                  type="button"
                  class="btn btn-success input-group-append "
                  onClick={elmododos}
                
                >
                  <i class="far fa-save"></i>
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          {mododos ? (
            <div class="col-sm-3">
               <br />
              <h6>3 - # Equipos por grupos</h6>
              <div class="input-group ">
                <input
                  type="number"
                  name="numberIni"
                  placeholder="0"
                  class="form-control"
                  value={input_multi}
                  onChange={(event) => setInput_multi(event.target.value)}
                  readOnly
                />
                <br />
                <button
                  type="button"
                  class="btn btn-success input-group-append "
                  onClick={handleClick_multi}
                >
                  <i class="far fa-save"></i>
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          {modotres ? (
            <div class="col-sm-3">
               <br />
              <h6>4 - # Juegos por equipos</h6>

              <div class="input-group ">
                <select name="tipo_cancha" class="form-control">
                  <option value="0" selected>
                    Seleccione
                  </option>
                  {values_multi.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>

                <br />

                <button type="submit" className="btn btn-primary">
                  <i class="far fa-save"></i> &nbsp;<i class="fas fa-hand-point-left fa-1x"></i>
                </button>
               
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
      <br />
   
      
     
      <div class="row">
        <div class="col-sm-12">
          {numberIni && (
            <div>
               <h5><b>Criterio Para Equipos Clasificados a la Siguiente Fase</b></h5>
              <label>
                <font size="2">
                  1° Clasificados Por Grupo <br />
                  Clasificarán a Siguiente Fases Los Primeros :&nbsp;{" "}
                </font>
              </label>
              <select
                id="firstSelect"
                disabled={selectDisabled}
              onChange={handleInput_division2}
              name="numberIni3"
               
              >
                <option value="seleccione">Seleccione</option>
                {Array(parseInt(numberIni))
                  .fill(1)
                  .map((value, key) => {
                    return <option value={key + 1}>{key + 1} Equipos</option>;
                  })}
              </select>
              <button
                  type="button"
                  class="btn btn-warning input-group-append "
                  onClick={() => resetForm()}
                >
                  <i class="fas fa-pencil-alt"></i>
                </button>
              <label>
                <font size="2">&nbsp;Equipos De Cada Grupo </font>{" "}
              </label>

             
              {guardar_uno ? (
          
          
          <button
          
            type="button"
            className="btn btn-primary btn-sm float-right "
            onClick={botonguardar_uno}
            
          > GUARDAR  &nbsp;<i class="fas far fa-save fa-2x fa-spin" style={{color:"red"}}></i></button>
        
          ) : (
            <></>
          )}

{guardar ? (
          
          
          <button
          
            type="button"
            className="btn btn-primary btn-sm float-right "
            onClick={botonguardar}
            
          > GUARDAR  &nbsp;<i class="fas far fa-save fa-2x fa-spin" style={{color:"orange"}}></i></button>
        
          ) : (
            <></>
          )}

{fase2 ? (

      <button
       className="btn btn-warning float-right"  onClick={props.next}>
         CLIC FASE 8 &nbsp;<i class="fas fa-sync fa-spin"></i>
      </button> 

) : (
<></>
)}


              
               { /*<div class="col-sm-6"> */}
                
                  <label>
                    <font size="2">
                      {" "}
                      &nbsp;{" "}
                      {modoboton ? (
                        <>
                        
                        <button type="button" class="btn btn-warning  btn-sm"><i class="far fa-lightbulb">!</i></button>
                        
                        
                        <div style={{padding:"5px"}}>
                        <button
                          className="btn btn-primary"
                          onClick={handleClick_cuatro}
                        >
                          +
                        </button>
                        
                        </div>
                        </>
                         ) : (
                            <></>
                          )}

                      {" "}
                    </font>{" "}
                  </label>
               {/* </div> */ }
               
              
              
                {modocuatro ? (
                  <div class="col-sm-10" style={{ top: "-20px" }}>
                   
                   
                    
                
                    {Array(parseInt(numberIni2))
                      .fill(1)
                      .map((value, key2) => {
                        return (
                          <div>
                            {arraySelect[key2] && (
                              <>
                                <label>
                                  <font size="2">
                                    &nbsp; &nbsp;
                                                                         <label>
                                        <font size="2">
                                          {" "}
                                          &nbsp;{" "}
                                           
                                          <div class="row">

                                          <div class="col-sm-9">
                                          <h6>2° Clasificados Por Grupo</h6>
                  <label><font size="2">Clasificarán a Siguiente Fases Los de Mejor Promedio : 
                     {rows.length === 1 && (
                                          <select
            onChange={(e) => handleChange(0, e.target.value)}
            name=""
            id=""
            disabled={false}
          >
            <option value="seleccione">Seleccione</option>
            {Array(parseInt(numberIni2))
              .fill(1)
              .map((value, key) => {
                return (
                  <option value={numberIni2 - key}>{numberIni2 - key} Equipos</option>
                );
              })}
          </select> 
           )}

{rows.length !== 1 && (
                                                <select
                                                  onChange={(e) =>
                                                    handleChange(
                                                      0,
                                                      e.target.value
                                                    )
                                                  }
                                                  name=""
                                                  id=""
                                                  disabled={true}
                                                  value={selectedNumbers[key2]}
                                                >
                                                  <option value="seleccione">
                                                    Seleccione
                                                  </option>
                                                  {Array(parseInt(numberIni2))
                                                    .fill(1)
                                                    .map((value, key) => {
                                                      return (
                                                        <option
                                                          value={
                                                            numberIni2 - key
                                                          }
                                                        >
                                                          {numberIni2 - key}{" "}
                                                          Equipos
                                                        </option>
                                                      );
                                                    })}
                                                </select>
                                              )}{" "}
          
          Ubicados en la
         
          {rows.length === 1 && (
          <div className="col-sm-2" style={{top:"-27px", right:"-135%"}}>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => {
                setSelect_select2(selectedNumbers[0]);
            }}
          >
            +
          </button>
          </div>
          )}

          </font> </label>

          {Array(parseInt(numberIni2))
            .fill(1)
            .map((value, key2) => {
              return (
                <div>
                  {arraySelect2[key2] && (
                    <>
                    <h6>{index + 1}° Clasificados Por Grupo</h6>
                  <label><font size="2">Clasificarán a Siguiente Fases Los de Mejor Promedio : 

                  {key2 === rows.length - 2 && (
                      <select
                        onChange={(e) => handleChange(key2 + 1, e.target.value)}
                        name=""
                        id=""
                        disabled={false}
                      >
                        <option value="seleccione">Seleccione</option>
                        {arraySelect2[key2].map((value, key3) => {
                          return (
                            <option value={arraySelect2[key2][key3]}>
                              {arraySelect2[key2][key3]} Equipos
                            </option>
                          );
                        })}
                      </select>
                       )}

{key2 !==
                                                            rows.length - 2 && (
                                                            <select
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  key2 + 1,
                                                                  e.target.value
                                                                )
                                                              }
                                                              name=""
                                                              id=""
                                                              disabled={true}
                                                              value={ selectedNumbers[key2 + 1]}
                                                            >
                                                              <option value="seleccione">
                                                                Seleccione
                                                              </option>
                                                              {arraySelect2[
                                                                key2
                                                              ].map(
                                                                (
                                                                  value,
                                                                  key3
                                                                ) => {
                                                                  return (
                                                                    <option
                                                                      value={
                                                                        arraySelect2[
                                                                          key2
                                                                        ][key3]
                                                                      }
                                                                    >
                                                                      {
                                                                        arraySelect2[
                                                                          key2
                                                                        ][key3]
                                                                      }{" "}
                                                                      Equipos
                                                                    </option>
                                                                  );
                                                                }
                                                              )}
                                                            </select>
                                                          )}{" "}
                      
                      Ubicados en la
                      { key2 === rows.length - 2 && (

<div className="col-sm-2" style={{top:"-27px", right:"-135%"}}>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                            setSelect_select2(selectedNumbers[key2 + 1]);
                        }}
                      >
                         +
                      </button>
                      <button type="button" class="btn btn-warning "><i class="far fa-lightbulb"></i></button>
</div>
                      )}
                      </font> </label>
                    </>
                  )}
                </div>
              );
            })}


                                          </div>

                                          

                                          <div className="col-sm-2" style={{top:"-2px"}}>


                                          {rows.map((e, index) => (
                                              
                                              <Row
                                                nombre={e.nombre}
                                                index={index}
                                                onChange={(index, value) =>
                                                  handleOnChange(index, value)
                                                }
                                                key={index}
                                              />
                                            ))}
                                         </div>

                                         </div>
          
                                          {" "}
                                        </font>{" "}
                                      </label>
                                  

                                  </font>{" "}
                                </label>
                                <br /> <br />
                              </>
                            )}
                          </div>
                        );
                      })}
                      {numberIni2 && (
        <input onClick={() => resetFormtodo()} type="button"  className="btn btn-warning" value="EDITAR ESTA FASE" />
      )}
                  </div>
                ) : (
                  <></>
                )}
             
            </div>
          )}
        </div>
      </div>
      
      
     
      
    </div>
  );
}

export default Fase8;
