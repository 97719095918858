import React, {useEffect, useContext, useState} from 'react'


//logo confenalco

import LogoConfenalco from '../../../img/confenalco.jpg';

import LogoBalon from '../../../img/balon.png';

import LogoTorneo1 from '../../../img/activos.png';

import Reglamento from '../../../img/reglamento_torneo_verde.png'
import Programacion from '../../../img/programacion_verde.png'
import Grupos from '../../../img/grupos.png'
import Programar_juegos from '../../../img/programar_juegos_verde.png'
import Auditar_torneo from '../../../img/auditar_torneo.png'
import Tabla_posiciones from '../../../img/tabla_posiciones.png'

import LogoTorneo from '../../../img/torneos.png'


import Editar1 from '../../../img/editar1.png';
import Editar2 from '../../../img/editar2_tarjeta.png';
import Editar3 from '../../../img/editar3.png';


import Editar4_tarjeta1 from '../../../img/editar4_tarjeta1.png';
import Editar5 from '../../../img/editar5.png';


//jugadores
import Editar7 from '../../../img/editar7.png';
import Editar7_mina from '../../../img/editar7_mina.png';
import Editar7_murillo from '../../../img/editar7_murillo.png';
import Editar7_ospina from '../../../img/editar7_ospina.png';
import Editar7_dani from '../../../img/editar7_dani.png';
import Editar7_james from '../../../img/editar7_james.png';
import Editar7_barrios from '../../../img/editar7_barrios.png';
import Editar7_juanfer from '../../../img/editar7_juanfer.png';
import Editar7_carrascal from '../../../img/editar7_carrascal.png';
import Editar7_gio from '../../../img/editar7_gio.png';
import Editar7_ibarguen from '../../../img/editar7_ibarguen.png';

//segundo equipo
import Editar7_messi from '../../../img/editar7_messi.png';
import Editar7_davison from '../../../img/editar7_davison.png';
import Editar7_johan from '../../../img/editar7_johan.png';
import Editar7_alexander from '../../../img/editar7_alexander.png';
import Editar7_thiago from '../../../img/editar7_thiago.png';
import Editar7_neymar from '../../../img/editar7_neymar.png';
import Editar7_cardona from '../../../img/editar7_cardona.png';
import Editar7_luis from '../../../img/editar7_luis.png';
import Editar7_chara from '../../../img/editar7_chara.png';
import Editar7_teo from '../../../img/editar7_teo.png';
import Editar7_embape from '../../../img/editar7_embape.png';

import Editar11 from '../../../img/editar11_autogol.png';
import Editar12 from '../../../img/amigospino.png';
import Editar12_lostios from '../../../img/lostios.png';
import Editar13 from '../../../img/editar13_tarjetas1.png';
import Editar14 from '../../../img/editar13_tarjetas2.png';
import Editar15 from '../../../img/editar15.png';
import Editar16 from '../../../img/editar16.png';
import Editar17 from '../../../img/editar17.png';
import Editar18 from '../../../img/editar18.png';
import Editar19 from '../../../img/editar19.png';
import Editar20 from '../../../img/editar20.png';


import Editar2a from '../../../img/editar2_tarjeta.png';
import Tarjeta_amarilla from '../../../img/tarjeta_amarilla.png';
import Tarjeta_amarilla_amarilla_roja from '../../../img/tarjeta_amarilla_amarilla_roja.png';
import Tarjeta_amarilla_roja from '../../../img/tarjeta_amarilla_roja.png';
import Tarjeta_roja from '../../../img/Tarjeta_roja.png';

//Acordion
import { makeStyles } from '@material-ui/core/styles';


import {MyContext} from '../../../contexts/MyContext';


import "react-datepicker/dist/react-datepicker.css";

// Importing the Login & Register Componet
import Register from '../Register'

import axios from 'axios';



 function Tarjetas1(props) {

    //Acordion
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));


  //guardar
  const baseUrlAd="https://www.deportes.controlsas.com/apiPlooy/Solicitar_empresa/"
  const [data, setData]=useState([]);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    guardar_equipos:'',
  });

//fecha de solo año
  const [startDate, setStartDate] = useState(new Date());
   
  //minutos por tiempo
  const [minutes, setMinutes] = useState("");

  //minutos por tiempo
  useEffect(() => {
    if (minutes && (+minutes < 1 || +minutes > 45)) setMinutes("")
  }, [minutes])


  //minutos por tiempo
  const handleChange = ({ target: { value } }) => setMinutes(value);

  //BOTON EDITAR
  const [modoedicion, setModoEdicion] = useState(null) 

  const [dropdown, setDropdown]=useState(false);
  const [dropdown2, setDropdown2]=useState(false);
  const [dropdown3, setDropdown3]=useState(false);
  const [dropdown4, setDropdown4]=useState(false);

 //Modal
  const [modalTiemposJuego, setModalTiemposJuego]=useState(false);
  const [modalMinutosTiempo, setModalMinutosTiempo]=useState(false);
  const [modalGenero, setModalGenero]=useState(false);
  const [modalEdad, setModalEdad]=useState(false);

  const [modalSuperficie, setModalSuperficie]=useState(false);
  const [modalEquipos, setModalEquipos]=useState(false);
  const [modalJugadores, setModalJugadores]=useState(false);
  const [modalFecha, setModalFecha]=useState(false);
  const [modalImagen, setModalImagen]=useState(false);

  //select genero
  const [value, setValue] = React.useState()


  //select edad
  const [value2, setValue2] = React.useState()


  //select edad libre
  const defaultState = {
    numero: "0",
    numero1: "",
    numero2: "",

    numero_cero: "0",
    numero1_uno: "",

  };


  

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }

  const abrirCerrarDropdown2=()=>{
    setDropdown2(!dropdown2);
  }

  const abrirCerrarDropdown3=()=>{
    setDropdown3(!dropdown3);
  }

  const abrirCerrarDropdown4=()=>{
    setDropdown4(!dropdown4);
  }


//Modal

const abrirCerrarModalTiemposJuegos=()=>{
  setModalTiemposJuego(!modalTiemposJuego);
}

const abrirCerrarModalMinutosTiempo=()=>{
  setModalMinutosTiempo(!modalMinutosTiempo);
}

const abrirCerrarModalGenero=()=>{
  setModalGenero(!modalGenero);
}

const abrirCerrarModalEdad=()=>{
  setModalEdad(!modalEdad);
}



const abrirCerrarModalSuperficie=()=>{
  setModalSuperficie(!modalSuperficie);
}

const abrirCerrarModalEquipos=()=>{
  setModalEquipos(!modalEquipos);
}

const abrirCerrarModalJugadores=()=>{
  setModalJugadores(!modalJugadores);
}

const abrirCerrarModalFecha=()=>{
  setModalFecha(!modalFecha);
}

const abrirCerrarModalImagen=()=>{
  setModalImagen(!modalImagen);
}


//guardar
const handleChange_guardar=e=>{
  e.preventDefault();
  const {name, value}=e.target;
  setFrameworkSeleccionado((prevState)=>({
    ...prevState,
    [name]: value
  }))
 console.log(frameworkSeleccionado);
}

//guardar
const peticionPost = async() =>{
 
  var f = new FormData();
  f.append("guardar_equipos", frameworkSeleccionado.guardar_equipos);
  f.append("METHOD", "POST");
  await axios.post(baseUrlAd,f)
  .then(response=>{
   setData(data.concat(response.data));
  // abrirCerrarModalInsertar();
  setModoEdicion(true)
  alert("Información guardada correctamente");
  }).catch(error=>{
    console.log(error);
  })

}

const peticionEditar =()=>{
  alert("Información se ha editado correctamente");
}

//radio
const [valueradio2, setValueradio2] = useState("");


// edad libre
function Row({ onChange, onRemove, numero, numero1, numero2 }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-4">
         
         <input
        type="number"
        value={numero1}
        onChange={e => onChange("numero1", e.target.value)}
        class="form-control"
        
      />

</div>

<div class="col-sm-4">
         
         <input
        type="number"
        value={numero2}
        onChange={e => onChange("numero2", e.target.value)}
        class="form-control"
        
      />



      </div>
<br/>

 <br/>

 <div class="col-sm-3">

      <input
        type="number"
        value={numero}
        onChange={(e) => onChange("numero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}


//edad libre
const [rows, setRows] = useState([defaultState]);
const [amount, setAmount] = useState(0);

const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};

const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  let total = 0;
  rows.map(function (num) {
    total = total + parseInt(num.numero);
  });
  console.log(total);
  setAmount(total);
  setRows(rows.concat(defaultState));
};

const handleOnRemove = (index) => {
  setAmount(amount - parseInt(rows[index].numero));
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
console.log(amount);





// edad menores de edad

function Row2({ onChange, onRemove, numero_cero, numero_uno }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-5">
         
         <input
        type="number"
        value={numero_uno}
        onChange={e => onChange("numero_uno", e.target.value)}
        class="form-control"
        
      />

      </div>
<br/>

 <br/>

 <div class="col-sm-5">

      <input
        type="number"
        value={numero_cero}
        onChange={(e) => onChange("numero_cero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}




//edad menos de edad
const [rowss, setRowss] = useState([defaultState]);
const [amounts, setAmounts] = useState(0);

const handleOnChanges = (index, name, value) => {
  const copyRows = [...rowss];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRowss(copyRows);
};

const handleOnAdds = e => {
  e.preventDefault(); //esto previene que el form se mande.
  let total = 0;
  rowss.map(function (num) {
    total = total + parseInt(num.numero_cero);
  });
  console.log(total);
  setAmounts(total);
  setRowss(rowss.concat(defaultState));
};

const handleOnRemoves = (index) => {
  setAmounts(amounts - parseInt(rowss[index].numero_cero));
  const copyRows = [...rowss];
  copyRows.splice(index, 1);
  setRowss(copyRows);
};
console.log(amounts);





 //sumar generos
 const [number, setNumber] = useState({pago_uno: 0, pago_dos: 0})
  const [suma, setSuma] = useState()

  useEffect(()=>{
    const {pago_uno, pago_dos} = number
    setSuma(Number(pago_uno) + Number(pago_dos))
},[number])
  

  const handleInput = (event) => {
    const { name, value } = event.target
    setNumber({...number, [name]: value})
  }


//acordeon
  const [color, setName] = useState([]);


// agregar acordeon
const classes = useStyles();
  
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {

    return (
        
        
       
            
              <div className="col-sm-12">
           
         
             


    
              <div class="card">

              <div>
              <h3 class="card-title" ><button style={{backgroundColor:"transparent", border:"none"}}><img src={Reglamento} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Programacion} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Grupos} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}}><img src={Programar_juegos} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Auditar_torneo} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Tabla_posiciones} width="145px" /></button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <font size="1"><img src={LogoTorneo} width="20px" /> TORNEOS / &nbsp; 
                              <img src={LogoTorneo1} width="20px" /> ACTIVOS 
                             
                             </font> </h3>
              
              </div>
                  
                  <div class="card-header text-center">
                    <h3 class="card-title"><img src={LogoConfenalco} width="45px" /><img src={LogoBalon} width="45px" /> <b>TORNEO CHENCHO 2022 </b>  - Colombia – Tolima – Ibagué – Futbol - F11 – G Natural – Masculino – Dici 2021</h3>
                    <br /><h3 class="card-title"> 
  </h3>
                  </div>

                  
                
                  <div class="card-body">
                      
              {   /* <form >*/ }









  

      {/*Aqui fue */}
     

     
       

    



        <br />      


 

    {/*  <div class="col-sm-12">

     <center> <img src={Editar1} width="445px" /> </center>



</div>*/}


<div class="col-sm-12">

<center> <img src={Editar2} width="945px" /> </center>

<hr />

</div>


<div class="col-sm-12">
<center> <img src={Editar4_tarjeta1} width="845px" /> </center>
</div>



<div class="col-sm-12">

<center> <input type="radio"  style={{border: "0px",
    width: "3%",
    height: "1em"
}}
name="radio1"
value="no"
checked={valueradio2 === "no"}
onChange={(e) => setValueradio2(e.currentTarget.value)} /> <img src={Editar5} width="580px" /> <input type="radio" 
style={{border: "0px",
width: "3%",
height: "1em"
}}
name="radio1"
value="si"
checked={valueradio2 === "si"}
onChange={(e) => setValueradio2(e.currentTarget.value)} /></center>


</div>



  <div className="col-sm-12">
        {valueradio2 === "no" && (
          <>
    
    <hr/>
<br />
          <center>  <img src={Editar7} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7"
          checked={valueradio2 === "editar7"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_mina} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_mina"
          checked={valueradio2 === "editar7_mina"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_murillo} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_murillo"
          checked={valueradio2 === "editar7_murillo"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          
          <center>  <img src={Editar7_ospina} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_ospina"
          checked={valueradio2 === "editar7_ospina"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_dani} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_dani"
          checked={valueradio2 === "editar7_dani"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_james} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_james"
          checked={valueradio2 === "editar7_james"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_barrios} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_barrios"
          checked={valueradio2 === "editar7_barrios"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_juanfer} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_juanfer"
          checked={valueradio2 === "editar7_juanfer"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_carrascal} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_carrascal"
          checked={valueradio2 === "editar7_carrascal"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_gio} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_gio"
          checked={valueradio2 === "editar7_gio"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          <center>  <img src={Editar7_ibarguen} width="380px" /> <div><input type="radio"  style={{border: "0px",
          width: "3%",
          height: "2em"
          }}
          name="radio1"
          value="editar7_ibarguen"
          checked={valueradio2 === "editar7_ibarguen"}
          onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
          
          
          </>
        )}
      </div>




      
      <div className="col-sm-12">
        {valueradio2 === "si" && (
           <>
    
           <hr/>
       <br />
                 <center>  <img src={Editar7_messi} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }}
                 name="radio1"
                 value="editar7_messi"
                 checked={valueradio2 === "editar7_messi"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 <center>  <img src={Editar7_davison} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }}
                 name="radio1"
                 value="editar7_davison"
                 checked={valueradio2 === "editar7_davison"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 <center>  <img src={Editar7_johan} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }}
                 name="radio1"
                 value="editar7_johan"
                 checked={valueradio2 === "editar7_johan"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 
                 <center>  <img src={Editar7_alexander} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }} name="radio1"
                 value="editar7_alexander"
                 checked={valueradio2 === "editar7_alexander"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
                 
                 <center>  <img src={Editar7_thiago} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }}
                 name="radio1"
                 value="editar7_thiago"
                 checked={valueradio2 === "editar7_thiago"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 <center>  <img src={Editar7_neymar} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }} 
                 name="radio1"
                 value="editar7_neymar"
                 checked={valueradio2 === "editar7_neymar"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 <center>  <img src={Editar7_cardona} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }} 
                 name="radio1"
                 value="editar7_cardona"
                 checked={valueradio2 === "editar7_cardona"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 <center>  <img src={Editar7_luis} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }}
                 name="radio1"
                 value="editar7_luis"
                 checked={valueradio2 === "editar7_luis"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)} /></div></center>
                 
                 <center>  <img src={Editar7_chara} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }} 
                 name="radio1"
                 value="editar7_chara"
                 checked={valueradio2 === "editar7_chara"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 <center>  <img src={Editar7_teo} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }} 
                 name="radio1"
                 value="editar7_teo"
                 checked={valueradio2 === "editar7_teo"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 <center>  <img src={Editar7_embape} width="380px" /> <div><input type="radio"  style={{border: "0px",
                 width: "3%",
                 height: "2em"
                 }}
                 name="radio1"
                 value="editar7_embape"
                 checked={valueradio2 === "editar7_embape"}
                 onChange={(e) => setValueradio2(e.currentTarget.value)}/></div></center>
                 
                 
                 </>
        )}
      </div>



      {/* Equipo 1 */}

      <div className="col-sm-12">
        {valueradio2 === "editar7" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>



    {/* Equipo 1 */}

    <div className="col-sm-12">
        {valueradio2 === "editar7_mina" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_mina} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>           




    {/* Equipo 1 */}

    <div className="col-sm-12">
        {valueradio2 === "editar7_murillo" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_murillo} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>



    {/* Equipo 1 */}

    <div className="col-sm-12">
        {valueradio2 === "editar7_ospina" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_ospina} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>




       {/* Equipo 1 */}

       <div className="col-sm-12">
        {valueradio2 === "editar7_dani" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>
      



    {/* Equipo 1 */}

    <div className="col-sm-12">
        {valueradio2 === "editar7_james" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_james} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>



     {/* Equipo 1 */}

      <div className="col-sm-12">
        {valueradio2 === "editar7_barrios" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_barrios} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>     




    {/* Equipo 1 */}

    <div className="col-sm-12">
        {valueradio2 === "editar7_juanfer" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_juanfer} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>



     {/* Equipo 1 */}

     <div className="col-sm-12">
        {valueradio2 === "editar7_carrascal" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_carrascal} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>




     {/* Equipo 1 */}

      <div className="col-sm-12">
        {valueradio2 === "editar7_gio" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_gio} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div>    




      {/* Equipo 1 */}

      <div className="col-sm-12">
        {valueradio2 === "editar7_ibarguen" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_ibarguen} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>


<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>


 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>



         </>
        )}
      </div> 
      
      
      
      {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_messi" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_messi} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div> 





 {/*equipo 2 */}

 <div className="col-sm-12">
        {valueradio2 === "editar7_davison" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_davison} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div> 




 {/*equipo 2 */}

 <div className="col-sm-12">
        {valueradio2 === "editar7_johan" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_johan} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div> 




  {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_alexander" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_alexander} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div>     



  {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_thiago" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_thiago} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div>



 {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_neymar" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_neymar} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div>



  {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_cardona" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_cardona} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div>



  {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_luis" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_luis} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div>



  {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_chara" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_chara} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div>



  {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_teo" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_teo} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div> 




  {/*equipo 2 */}

<div className="col-sm-12">
        {valueradio2 === "editar7_embape" && (
         <>
             <hr />


<div class="row" style={{justifyContent:"center"}} >
<div class="col-sm-3" >
<center>  <img src={Editar12_lostios} width="180px" /> </center>
</div>
<div class="col-sm-3">
<center>  <img src={Editar7_embape} width="310px" /> </center>
<br />
</div>

</div>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

</div>

<div class="col-sm-12">

 <center><img src={Editar14} width="800px" /> </center>
 </div>

 <center>
<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_amarilla_roja} width="150px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>


<div class="col-sm-2">
<center>  <img src={Tarjeta_amarilla_roja} width="100px" /> <div><input type="radio"  style={{border: "0px",
width: "10%",
height: "2em"
}} /></div></center>

</div>



</div>

</center>


<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Tarjeta_roja} width="50px" /> <div><input type="radio"  style={{border: "0px",
width: "7%",
height: "2em"
}} /></div></center>

</div>



</div>
         </>
        )}
      </div>                                           



<div class="col-sm-12">

<button className="btn btn-success rounded-circle float-right"   onClick={props.next}><i class="fas fa-save fa-2x"></i></button>
</div>






{/*<button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp; */}




  {/*</form>*/}
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
       

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
      
}
 }

 

export default Tarjetas1