import React, {useContext,useState} from 'react'
import {MyContext} from '../../contexts/MyContext'

function Register(){
    const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            email:'',
            password:'',
            tipo_usuario:'administrador',
            nick:'',
            apellidos:'',
            tdoc:'',
            documento:'',
            telefono:'',
            genero:'',
            fechanacimiento:'',
            edad:'',
            modulo:'',
            deporte:'',
            subdeporte:'',
            pais:'',
            departamento:'',
            ciudad:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    return(
        <div className="content-wrapper">
    
        <div className="content-header">
          <div className="container-fluid">
            
              <div className="col-sm-12">
    
    
    
              <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">CREAR ADMINISTRADOR</h3>
                  </div>
                
                  <div class="card-body">
                    <form onSubmit={submitForm}>
                      <div class="row">    
                        <div class="col-sm-4">
                        <div class="form-group">
                  
                        <input type="text"  name="nick" class="form-control" value={state.userInfo.nick} onChange={onChangeValue} placeholder="Nick name" />
                        </div>
                        </div>
    
    
                        <div class="col-sm-4">
                        <div class="form-group">
                        
                        <input name="name" required type="text" className="form-control" value={state.userInfo.name} onChange={onChangeValue} placeholder="Nombres"/>
                      </div>
                          
                          
                        </div>
    
    
    
                        <div class="col-sm-4">
                            
    
                        <div class="form-group">
                        
                        <input type="text" name="apellidos" class="form-control" id="inputSuccess" value={state.userInfo.apellidos} onChange={onChangeValue} placeholder="Apellidos" />
                      </div>
                      </div>
    
                     
                        </div>
    
    
                        <div class="row">
                        <div class="col-sm-2">
                        <select name="tdoc" class="form-control" value={state.userInfo.tdoc} onChange={onChangeValue}>
                   <option value='no' selected>T.doc</option>
                    <option value="Cedula">Cedula</option>
                    <option value="DNI">DNI</option>
                    <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                 </select>     
    
                        </div>
    
    
                        <div class="col-sm-2">
                        <input type="number" name="documento"  className="form-control" id="inputSuccess" value={state.userInfo.documento} onChange={onChangeValue} placeholder="N° documento" />
    
                        </div>
    
                        <div class="col-sm-3">
                        
                        <div class="form-group">
                        <input type="number" name="telefono" class="form-control" id="inputSuccess"value={state.userInfo.telefono} onChange={onChangeValue}  placeholder="Teléfono" />
                          </div>
                      
    
                        </div>

                        < div class="col-sm-2">
                       <select name="genero" class="form-control" value={state.userInfo.genero} onChange={onChangeValue}>
                   <option value='no' selected>Genero</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                    <option value="Sin definir">Sin definir</option>
                   
                 </select>     
    
                        </div>
    
                       < div class="col-sm-3">
                       <label>Fecha de nacimiento</label>
                        <input type="date" name="fechanacimiento" class="form-control is-warning" id="inputSuccess" value={state.userInfo.fechanacimiento} onChange={onChangeValue} placeholder="Fecha nacimiento" />
    
                        </div>
    
                        
                        
                      </div>

                      <div class="row">
                        <div class="col-sm-1">
                        <div class="form-group">
                        <input type="number" name="edad" class="form-control" id="inputSuccess" value={state.userInfo.edad} onChange={onChangeValue} placeholder="Edad" />
                          </div>

                        </div>

                        <div class="col-sm-3">
                        <div class="form-group">
                        <input name="email" className="form-control is-valid" required type="email" value={state.userInfo.email} onChange={onChangeValue} placeholder="Email"/>
                          </div>

                        </div>

                        <div class="col-sm-2">
                        <div class="form-group">
                        <input type="password" name="password" class="form-control is-valid" required type="password" value={state.userInfo.password} onChange={onChangeValue} placeholder="Contraseña" />
                          </div>

                        </div>

                        <div class="col-sm-3">
                        <div class="form-group">
                        <input type="password" class="form-control is-valid" id="inputSuccess" placeholder="Repetir contraseña" />
                          </div>

                        </div>


                        </div>


                        <div class="row">
                        <div class="col-sm-4">
                        <label><i class="fas fa-archive"></i> Modulo:</label>
     <br/>
     <select name="modulo" class="form-control" value={state.userInfo.modulo} onChange={onChangeValue}>
     <option value='no' selected>Seleccione</option>
                    <option value="Torneo">Torneo</option>
                    
                  </select>  

                        </div>

                        <div class="col-sm-4">
                        <label><i class="fas fa-futbol"></i> Deporte:</label>
     <br/>
     <select name="deporte" class="form-control" value={state.userInfo.deporte} onChange={onChangeValue}>
     <option value='no' selected>Seleccione</option>
                    <option value="Futbol">Futbol</option>
                    
                  </select>  
                        </div>

                        <div class="col-sm-4">
                        <label><i class="fab fa-accessible-icon"></i> Subdeporte:</label>
     <br/>
     <select name="subdeporte" class="form-control" value={state.userInfo.subdeporte} onChange={onChangeValue}>
     <option value='no' selected>Seleccione</option>
                    <option value="11x11">11x11</option>
                    <option value="10x10">10x10</option>
                    
                  </select>  
     <br />

                        </div>

                       


                        </div>


                        <div class="row">
                        <div class="col-sm-4">
                        <label><i class="fas fa-globe-americas"></i> Pais:</label>
     <br/>
     <select name="pais" class="form-control" value={state.userInfo.pais} onChange={onChangeValue}>
     <option value='no' selected>Seleccione</option>
                    <option value="Colombia">Colombia</option>
                  
                  </select>  

                        </div>

                        <div class="col-sm-4">
                        <label><i class="fas fa-city"></i> Departamento:</label>
     <br/>
     <select name="departamento" class="form-control" value={state.userInfo.departamento} onChange={onChangeValue}>
     <option value='no' selected>Seleccione</option>
                    <option value="Tolima">Tolima</option>
                  
                  </select>  
                        </div>

                        <div class="col-sm-4">
                        <label><i class="far fa-building"></i> Ciudad:</label>
     <br/>
     <select name="ciudad" class="form-control" value={state.userInfo.ciudad} onChange={onChangeValue}>
     <option value='no' selected>Seleccione</option>
                    <option value="Ibague">Ibague</option>
                    <option value="Espinal">Espinal</option>
                  
                  </select>  
     <br />

                        </div>

                       


                        </div>
    
                      
                    
    
                      
                        <div class="col-sm-12">

                        
                       
                       <p><b>{errorMsg}{successMsg}</b></p>
                      
                          



                      
    
                        </div>
                        
                        
                
                      
                    
                        <button className="btn btn-primary" type="submit">Registrar</button>
                    </form>
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
          </div>
          </div>
        
      </div>
        );
        }

        /*
        <div className="_loginRegister">
            <h1>Sign Up</h1>
            <form onSubmit={submitForm} noValidate>
                <div className="form-control">
                    <label>Full Name</label>
                    <input name="name" required type="text" value={state.userInfo.name} onChange={onChangeValue} placeholder="Enter your name"/>
                </div>
                <div className="form-control">
                    <label>Email</label>
                    <input name="email" required type="email" value={state.userInfo.email} onChange={onChangeValue} placeholder="Enter your email"/>
                </div>
                <div className="form-control">
                    <label>Password</label>
                    <input name="password" required type="password" value={state.userInfo.password} onChange={onChangeValue} placeholder="Enter your password"/>
                </div>
                {errorMsg}
                {successMsg}
                <div className="form-control">
                    <button type="submit">Sign Up</button>
                </div>
            </form>
            <div className="_navBtn">
                <button  onClick={toggleNav}>Login</button>
            </div>
        </div>
    );
}*/

export default Register