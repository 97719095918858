import React, {useContext,useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

//Agregar campo dinamico
const defaultState = {
  nombre: "",
};



//Agregar campo dinamico
function Row({ onChange, onRemove, datos_adicionales}) {
 
  return (
    <div>
      
      <div class="input-group">
      <input name="datos_adicionales"
        value={datos_adicionales}
        className="form-control" id="inputSuccess"
       
       /* style={{ "background-color": !input3 && "#D3F9FF" }}
        value={input3}
        onChange={({ target }) => setInput3(target.value)}*/
        placeholder="Datos adicionales"
      />
       
       <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" ><b>?</b></button>
                  </span>
     
       </div> 
   <br/>
     


    
    </div>
  );
}


 function Datos_generales_torneo_2(props) {

  //colores inputs y selects
  const [input, setInput] = useState();

  const [input2, setInput2] = useState();

// const [input3, setInput3] = useState();

const [select, setSelect] = useState();

const [select2, setSelect2] = useState();

const [select3, setSelect3] = useState();

const [select4, setSelect4] = useState();

const [select5, setSelect5] = useState();


 //agregar campo dinamico
 const [rows, setRows] = useState([defaultState]);

//Modal organizador
const [modalOrganizador, setModalOrganizador]=useState(false);

 //Modal
 const [modalNombreTorneo, setModalNombreTorneo]=useState(false);

 const [modalDatosAdicionales, setModalDatosAdicionales]=useState(false);

 

 //agregar campo dinamico
const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};


//Modal organizador

const abrirCerrarModalOrganizador=()=>{
  setModalOrganizador(!modalOrganizador);
}

//Modal

const abrirCerrarModalNombreTorneo=()=>{
  setModalNombreTorneo(!modalNombreTorneo);
}

const abrirCerrarModalDatosAdicionales=()=>{
  setModalDatosAdicionales(!modalDatosAdicionales);
}



 //agregar campo dinamico
const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  setRows(rows.concat(defaultState));
};
/*
 //agregar campo dinamico
const handleOnRemove = (index,e) => {
  e.preventDefault(); //esto previene que el form se mande.
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
*/

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {

    return (
        
     
             
      <div className="col-sm-12">
    
              <div class="card">
                  
                  <div class="card-header">
                    <h3 class="card-title">DATOS GENERALES TORNEO</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    <div class="row">

    <div class="col-sm-3">

    <div class="input-group">
      
    <input 
       
     className="form-control" 
       
        maxLength="50"
        value="Chencho cancha"
        name="organizador"
        onChange={props.handleChange}
        readOnly
        
       
      />
      
     
       </div> 

       </div> 
    

     <div class="col-sm-3">
     <div class="input-group">

      
          
     <input className="form-control"  value="Futbol"  readOnly/>
    
   
        </div>
      </div>
    
    
      <div class="col-sm-3">
        
      <div class="input-group">
      <input className="form-control"  value="F11"  readOnly/>
        
   
        </div>
      </div>



     
  
      </div>

<br />

      <div class="row">
          
     
        
      <div class="col-sm-3">
      <div class="input-group">

      <input className="form-control"  value="Colombia"  readOnly/>
     
      </div>
      </div>

      <div class="col-sm-3">
      <div class="input-group">
      <input className="form-control" value="Tolima"  readOnly/>
      </div>
      </div>

      
    


      <div class="col-sm-2">
      <div class="input-group">

        <input className="form-control" value="Ibagué"  readOnly/>
      
 

      </div>
      </div>

      <div class="col-sm-4">
     

    <div class="input-group">
      
    <input 
       
     className="form-control" 
        readOnly
        value="Cancha sector bajo"
        maxLength="50"
        name="datos_adicionales"
       
        onChange={props.handleChange}
       
      />
    
     
       </div> 

     

</div>

         
    </div>

    <br />


    <div class="row">

      <div class="col-sm-6">

      <div class="input-group">

          

      
      <input type="text"  className="form-control " id="inputSuccess"
  
        value="Torneo cancha de chencho"
        name="nombre_torneo"
        readOnly
      
        onChange={props.handleChange} />
                 
 
</div>

</div>



</div>

<br/>



<button className="btn " style={{background:"#AAF0FF"}}  onClick={props.next}>SIGUIENTE </button>

      

    
<Modal isOpen={modalNombreTorneo}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Nombre del torneo</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Asigna Un Nombre al Torneo (No debe Contener Mas de 40 Caracteres)</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalNombreTorneo()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* organizador */}
<Modal isOpen={modalOrganizador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Organizador / Sucursal</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Sub Nombre o Sucursal, en el caso de que la empresa tenga un mismo torneo pero distintas sucursales
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalOrganizador()}>Aceptar</button>
 </ModalFooter>
</Modal>



<Modal isOpen={modalDatosAdicionales}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b> Datos adicionales </b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
   <h3><b>Para el caso de zonas geográficas no registradas en la base de datos.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalDatosAdicionales()}>Aceptar</button>
 </ModalFooter>
</Modal>

  </form>
                  </div>
                 
                </div>
    
    
                </div>

             

                
    
    
         
         

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
     
}
 }

export default Datos_generales_torneo_2