import React, {useContext,useState} from 'react'
import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

function Uno_puntos_juegos(props) {

    const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            deporte:'',
            email:'',
            password:'',
            pais:'',
            departamento:'',
            ciudad:'',
            tipo_usuario:'empresaj',
            apellidos:'',
            tdoc:'',
            documento:'',
            fechanacimiento:'',
            genero:'',
            telefono:'',
            comercio_natural:'',
            nacionalidad:'',
            direccion:'',
            ciudad_nacionalidad:'',
            emailc:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
        
            
              <div className="col-sm-12">
    
    
    
              <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">DATOS GENERALES TORNEO - TORNEO PARA MAYORES DE 40 AÑOS</h3>
                  </div>
                
                  <div class="card-body">
                  <form onSubmit={submitForm}>
                      <div class="row">
                     
    
                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                <select name="deporte" class="form-control" value={props.getState('deporte', '')} onChange={onChangeValue} disabled>
                <option value='no' selected>Deporte</option>
                <option value="Futbol">Futbol</option>
                 </select>     
                          </div>
                        </div>
    
    
                        <div class="col-sm-2">
                          <div class="form-group">
                            
                          <select name="subdeporte" class="form-control" value={props.getState('subdeporte', '')} onChange={onChangeValue} disabled>
                          <option value='no' >Subdeporte</option>
      <option value="11x11">11x11</option>
      <option value="10x10">10x10</option>
                        
                      </select>  
    
                          </div>
                        </div>
                      
                      
                    
    
    
                        <label>Datos generales del torneo</label>
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                            
                            <select name="pais" class="form-control" value={props.getState('pais', '')} onChange={onChangeValue} disabled>
                            <option value='no' selected>Pais</option>
                            <option value="Colombia">Colombia</option>
                          
                        </select>  
      
                            </div>
                      </div>
    

      
                     
                      <div class="col-sm-3">
    
                        <div class="form-group">

                        <select name="departamento" class="form-control" value={props.getState('departamento', '')} onChange={onChangeValue} disabled>
                        <option value='no' selected>Departamento</option>
                        <option value="Tolima">Tolima</option>
                          
                        </select>  
                      
                      </div>
                      </div>

                      
                      
                      <div class="col-sm-2">
                      <select name="ciudad" class="form-control" value={props.getState('ciudad', '')} onChange={onChangeValue} disabled>
                      <option value='no' selected>Ciudad</option>
                      <option value="Ibagué">Ibague</option>
                       <option value="Espinal">Espinal</option>
                          
                        </select>  
    
                        </div>


                        <div class="col-sm-3">
                        <input type="number" name="nombre" class="form-control" id="inputSuccess" placeholder="Datos adicionales" value={props.getState('nombre', '')} onChange={onChangeValue} readOnly/>
    
                        </div>
                        </div>
    
    
                        <div class="row">
                        
                    
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
                        <input type="text" name="name_torneo" class="form-control" id="inputSuccess" placeholder="Nombre del torneo" value={props.getState('name_torneo', '')} onChange={onChangeValue} readOnly />
                      </div>
                      </div>
    

       
                        
    
                       
                      </div>
    
                      
    
    
                        <div class="row">
    
    
                        </div>
    
    
                        <div class="row">
                      <label>Torneo para mayores de 40 años</label>
    
                        <div class="col-sm-4">
                  
                        <div class="form-group">
    
                        <select name="tipo_cancha" class="form-control" value={props.getState('tipo_cancha', '')} onChange={onChangeValue} disabled>
                        <option value="Futbol">GN - Grama natural</option>
                    <option value="ST - Sintética">ST - Sintética</option>
                    <option value="AR - Arena">AR - Arena</option>
                    <option value="PY - Playa">PY - Playa</option>
                    <option value="PD - Piso duro">PD - Piso duro</option>
                          
                        </select>  
                          </div>
                        </div>
    
    
                  <div class="col-sm-3">
                  
                  <div class="form-group">
    
                  <input type="text" name="n_equipos" class="form-control" id="inputSuccess" placeholder="N° equipos" value={props.getState('n_equipos', '')} onChange={onChangeValue} readOnly />
                    </div>
                  </div>


                  
                  <div class="col-sm-3">
                  
                  <div class="form-group">
    
                  <input type="text" name="jugadores_equipo" class="form-control" id="inputSuccess" placeholder="Jugadores por equipo" value={props.getState('jugadores_equipo', '')} onChange={onChangeValue} readOnly />
                    </div>
                  </div>
    
    
                        </div>
    
    
                        <div class="row">
                      
                  <div class="col-sm-3">
                  
                  <div class="form-group">
    
                  <input type="date" name="fecha_inicio" class="form-control" id="inputSuccess" placeholder="Fecha de inicio" value={props.getState('fecha_inicio', '')} onChange={onChangeValue} readOnly />
                    </div>
                  </div>


                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <div class="input-group">
                          <div class="custom-file">
                            <input type="file" name="acomercio" class="custom-file-input" id="exampleInputFile" value={props.getState('acomercio', '')} onChange={onChangeValue} disabled />
                            <label class="custom-file-label" for="exampleInputFile">Archivo</label>
                          </div>
                          <div class="input-group-append">
                          </div>
                        </div>
                    </div>
                  </div>

                
    
    
                        </div>
    
    
    

                        <div class="col-sm-12">

                        
                       
<p><b>{errorMsg}{successMsg}</b></p>

   





 </div>
    
     

                        <button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                        
                        <br /><br />
                      
                        
    
                      
    
                      
    
    
                    </form>
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
         


    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Uno_puntos_juegos



  
       /* <div>
          <label>FORMULARIO FINAL</label>
<p>Name: {props.state.name}</p>
<p>Surname: {props.state.surname}</p>
<p>Email: {props.state.email}</p>
<p>Phone: {props.state.phone}</p>
<button onClick={props.prev}>Previous</button>
<button onClick={props.next}>Next</button>
        </div>*/
