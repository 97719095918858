import React, {useContext,useState} from 'react';

import {MyContext} from '../../contexts/MyContext';

// Importing the Login & Register Componet
import Register from './Register';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

 function Cambios_planilla_2(props) {

// condicion select1 si o no
  const [value2, setValue2] = React.useState();

// condicion select2 si o no
  const [value3, setValue3] = React.useState();


 //Modal puntos ganador
const [modalPuntosGanador, setModalPuntosGanador]=useState(false);

//Modal puntos ganador

const abrirCerrarModalPuntosGanador=()=>{
      setModalPuntosGanador(!modalPuntosGanador);
}


 //Modal empate juego
 const [modalEmpateJuego, setModalEmpateJuego]=useState(false);

 //Modal empate juego
 
 const abrirCerrarModalEmpateJuego=()=>{
       setModalEmpateJuego(!modalEmpateJuego);
 }


 //Modal perdedor juego
 const [modalPerdedorJuego, setModalPerdedorJuego]=useState(false);

 //Modal perdedor juego
 
 const abrirCerrarModalPerdedorJuego=()=>{
      setModalPerdedorJuego(!modalPerdedorJuego);
}


//Modal ganador juego w
const [modalGanadorJuegoW, setModalGanadorJuegoW]=useState(false);

//Modal ganador juego w

const abrirCerrarModalGanadorJuegoW=()=>{
     setModalGanadorJuegoW(!modalGanadorJuegoW);
}


//Modal goles juego w
const [modalGolesJuegoW, setModalGolesJuegoW]=useState(false);

//Modal goles juego w

const abrirCerrarModalGolesJuegoW=()=>{
     setModalGolesJuegoW(!modalGolesJuegoW);
}


//Modal contra juego w
const [modalContraJuegoW, setModalContraJuegoW]=useState(false);

//Modal contra juego w

const abrirCerrarModalContraJuegoW=()=>{
     setModalContraJuegoW(!modalContraJuegoW);
}


//Modal contra juego w2
const [modalContraJuegoW2, setModalContraJuegoW2]=useState(false);

//Modal contra juego w2

const abrirCerrarModalContraJuegoW2=()=>{
     setModalContraJuegoW2(!modalContraJuegoW2);
}
    

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;



    if(isAuth)
    {

    return (
        
        
        
            
              <div className="col-sm-12">
                  
    
              <div class="card">
                  
                  <div class="card-header">
                    <h3 class="card-title">CAMBIOS PLANILLA</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    


      <div class="row">
        <center> <h6>Este modulo se refiere a los fechas limite de cierre de inscripción de equipos y jugadores, lo mismo que el criterio para inclusión de jugadores de equipos eliminados y jugadores nuevos para el torneo.
</h6> </center> 

<br/>
<br/>
          <div class="col-sm-3">
                <p>Fecha Limite Cierre de Planillas</p>
                <div class="input-group">
        <div style={{marginTop:"8px"}}></div>
        <input type="text" value='10-08-2022' readOnly  name="puntos_ganador" class="form-control"  onChange={props.handleChange} />


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>
      

     


      


    

      
    </div>
   
    

    <div class="row">
    
     
          <div class="col-sm-4">
              <br/>
          
          <p>Un Deportista de Un Equipo Eliminado, Puede Pasar a Un Equipo Clasificado Del Mismo Torneo?</p>
          <div class="input-group">
        {/*  <div style={{marginTop:"8px"}}>  </div><select name="puntos_ganadosw" class="form-control" value={props.getState('puntos_ganadosw', '')} onChange={props.handleChange}> */}
        <div style={{marginTop:"8px"}}>  </div>
        <input className="form-control"  value="NO"  readOnly/>

    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalGanadorJuegoW()}><b>?</b></button>
</span>*/}
     
       </div> 

      </div>
      

     


      <div class="col-sm-3">
        <br />
          <br />
          <br />
          { value2==='SI' ? (<div> <label>Desde</label>
      <div class="input-group">
      <div style={{marginTop:"8px"}}></div>    
      <select name="goles_ganadosw"
       
         class="form-control" 
         name="goles_ganadosw"
         value={props.getState('goles_ganadosw', '')} 
         onChange={props.handleChange}
         >
<option value='no' selected>.</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
      
    </select>  
      
     
      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalGolesJuegoW()}><b>?</b></button>
</span>*/ }
     
      </div> 


      </div>
      ):null}
      </div>



      <div class="col-sm-3">
      <br />
      <br />
      <br />
      { value2==='SI' ? (<div> <label>Hasta</label>
      <div class="input-group">

      <div style={{marginTop:"8px"}}> </div> 
      <select name="puntos_perdidosw"
       
       class="form-control" 
       name="puntos_perdidosw"
       value={props.getState('puntos_perdidosw', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
<option value="Fase1">Fase1</option>
  <option value="Fase2">Fase2</option>
  <option value="Fase3">Fase3</option>
  <option value="Fase4">Fase4</option>
  <option value="Fase5">Fase5</option>
  <option value="Fase6">Fase6</option>
  <option value="Fase7">Fase7</option>
  <option value="Fase8">Fase8</option>
  <option value="Fase9">Fase9</option>
  <option value="Fase10">Fase10</option>
    
  </select>  
      
     
      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW()}><b>?</b></button>
</span>*/}
     
       </div> 

       </div>
      ):null}
      
      </div>

      
      </div>


      

      <br />
      <div class="row">

     
      <div class="col-sm-4">
      <p>Un Equipo Clasificado Puede Inscribir Un Deportista Nuevo Para el Torneo ? </p>
      <div class="input-group">
      <div style={{marginTop:"8px"}}></div>
      {/*<select name="puntos_ganadosw2" class="form-control" value={props.getState('puntos_ganadosw2', '')} onChange={props.handleChange}>*/}
      <input className="form-control"  value="NO"  readOnly/>


      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW2()}><b>?</b></button>
</span> */ }
     
       </div> 

       </div>


       <div class="col-sm-3">
       <br />
       
       { value3==='SI' ? (<div> <label>Desde </label> 
      <div class="input-group">
      <div style={{marginTop:"8px"}}> </div>

      <select name="mayor_puntaje"
       
       class="form-control" 
       name="mayor_puntaje"
       value={props.getState('mayor_puntaje', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
<option value="Fase1">Fase1</option>
  <option value="Fase2">Fase2</option>
  <option value="Fase3">Fase3</option>
  <option value="Fase4">Fase4</option>
  <option value="Fase5">Fase5</option>
  <option value="Fase6">Fase6</option>
  <option value="Fase7">Fase7</option>
  <option value="Fase8">Fase8</option>
  <option value="Fase9">Fase9</option>
  <option value="Fase10">Fase10</option>
    
  </select>  
      
      

      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW2()}><b>?</b></button>
</span> */ }
     
       </div> 

       </div>
      ):null}
      

       </div>



       <div class="col-sm-3">
           <br />
           { value3==='SI' ? (<div><label>Hasta </label> 
      <div class="input-group">
      <div style={{marginTop:"8px"}}> </div>
      <select name="gol_diferencia_cuestion"
       
       class="form-control" 
       name="gol_diferencia_cuestion"
       value={props.getState('gol_diferencia_cuestion', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
<option value="Fase1">Fase1</option>
  <option value="Fase2">Fase2</option>
  <option value="Fase3">Fase3</option>
  <option value="Fase4">Fase4</option>
  <option value="Fase5">Fase5</option>
  <option value="Fase6">Fase6</option>
  <option value="Fase7">Fase7</option>
  <option value="Fase8">Fase8</option>
  <option value="Fase9">Fase9</option>
  <option value="Fase10">Fase10</option>
    
  </select>  
   
     
       </div> 
       </div>
      ):null}
      
       </div>

     

       </div>

      
    







      

    


  </form>
                  </div>
                 
                </div>
    
    
{/* puntos ganador*/}
<Modal isOpen={modalPuntosGanador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Ganador Del Juego.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPuntosGanador()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* empate juego*/}
<Modal isOpen={modalEmpateJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos Por Empate De Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Cada Equipo por Empate Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEmpateJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* perdedor juego*/}
<Modal isOpen={modalPerdedorJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Perdedor Del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Perdedor Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPerdedorJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* ganador juego W*/}
<Modal isOpen={modalGanadorJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados A Favor Al Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGanadorJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* goles juego W*/}
<Modal isOpen={modalGolesJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles a Favor al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Otorgados A Favor Del Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGolesJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* contra juego W*/}
<Modal isOpen={modalContraJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos En Contra Por Perder el Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* contra juego W2*/}
<Modal isOpen={modalContraJuegoW2}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles En Contra al Perdedor del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW2()}>Aceptar</button>
 </ModalFooter>
</Modal>
    
          </div>
    
 


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
        
}
 }

export default Cambios_planilla_2