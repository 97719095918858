import React, {useContext,useState} from 'react';

import {MyContext} from '../../contexts/MyContext';



// Importing the Login & Register Componet
import Register from './Register';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

 function Criterios_tarjetas_2(props) {

  // condicion select1 si o no
  const [value2, setValue2] = React.useState();

  // condicion select2 si o no
  const [value3, setValue3] = React.useState();

  // condicion select3 si o no
  const [value4, setValue4] = React.useState();

  // condicion select4 si o no
  const [value5, setValue5] = React.useState();

  // condicion select5 si o no
  const [value6, setValue6] = React.useState();

 //Modal puntos ganador
const [modalPuntosGanador, setModalPuntosGanador]=useState(false);

//Modal puntos ganador

const abrirCerrarModalPuntosGanador=()=>{
      setModalPuntosGanador(!modalPuntosGanador);
}


 //Modal empate juego
 const [modalEmpateJuego, setModalEmpateJuego]=useState(false);

 //Modal empate juego
 
 const abrirCerrarModalEmpateJuego=()=>{
       setModalEmpateJuego(!modalEmpateJuego);
 }


 //Modal perdedor juego
 const [modalPerdedorJuego, setModalPerdedorJuego]=useState(false);

 //Modal perdedor juego
 
 const abrirCerrarModalPerdedorJuego=()=>{
      setModalPerdedorJuego(!modalPerdedorJuego);
}


//Modal ganador juego w
const [modalGanadorJuegoW, setModalGanadorJuegoW]=useState(false);

//Modal ganador juego w

const abrirCerrarModalGanadorJuegoW=()=>{
     setModalGanadorJuegoW(!modalGanadorJuegoW);
}


//Modal goles juego w
const [modalGolesJuegoW, setModalGolesJuegoW]=useState(false);

//Modal goles juego w

const abrirCerrarModalGolesJuegoW=()=>{
     setModalGolesJuegoW(!modalGolesJuegoW);
}


//Modal contra juego w
const [modalContraJuegoW, setModalContraJuegoW]=useState(false);

//Modal contra juego w

const abrirCerrarModalContraJuegoW=()=>{
     setModalContraJuegoW(!modalContraJuegoW);
}


//Modal contra juego w2
const [modalContraJuegoW2, setModalContraJuegoW2]=useState(false);

//Modal contra juego w2

const abrirCerrarModalContraJuegoW2=()=>{
     setModalContraJuegoW2(!modalContraJuegoW2);
}
    

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;



    if(isAuth)
    {

    return (
        
        
        
            
              <div className="col-sm-12">
                  
    
              <div class="card">
                  
                  <div class="card-header">
                    <h3 class="card-title">CRITERIOS DE TARJETAS
</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    


      <div class="row">
        <center> <h6>Este modulo se refiere a las Fechas de Sanción Por Recibir tarjetas.  

</h6> </center> 

<br/>
<br/>

          <div class="col-sm-3">
              
                <label><font size="2">Las Tarjetas Amarillas Acumuladas Tienen Sanción?</font>

</label>
                <div class="input-group">
            

<input className="form-control"  value="SI"  readOnly/> 
      

     
       </div> 
      </div>
      

      <div class="col-sm-2">
     
      { value2==='SI' ? (<div> <label><font size="2"># Tarjetas</font></label>
      <br />
      <br />
      <div class="input-group">
      
      <select name="tarjetas1"
       
       class="form-control" 
       name="tarjetas1"
       value={props.getState('tarjetas1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    
    
  </select>  

    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div>
      ):null}
     

      </div>     

      <div class="col-sm-2">
      { value2==='SI' ? (<div>
      <label><font size="2">Fechas de suspención</font></label>
      <br />
      <br />
      <div class="input-group">
      <select name="desdea1"
       
       class="form-control" 
       name="desdea1"
       value={props.getState('desdea1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="1_suspendido">1 juego Suspendido</option>
    <option value="2_suspendido">2 juegos Suspendido</option>
    <option value="3_suspendido">3 juegos Suspendido</option>
    <option value="4_suspendido">4 juegos Suspendido</option>
    <option value="5_suspendido">5 juegos Suspendido</option>
    
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div>
      ):null}
     

      </div>


      <div class="col-sm-2">
      { value2==='SI' ? (<div>
      <label><font size="2">Desde</font></label>
      <br />
      <br />
      <div class="input-group">
      <select name="desde1"
       
       class="form-control" 
       name="desde1"
       value={props.getState('desde1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div>
      ):null}
     

      </div>



      



      <div class="col-sm-2">
      { value2==='SI' ? (<div> <label><font size="2">Hasta</font></label>
      <br />
      <br />
      <div class="input-group">
      <select name="hasta1"
       
       class="form-control" 
       name="hasta1"
       value={props.getState('hasta1', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 
       </div>
      ):null}
     
      </div>




    

      
    </div>
   
    

   

<hr />
      

      
      <div class="row">

     

      <div class="col-sm-3">
                <label><font size="2">Las Tarjetas Amarillas Consecutivas Tienen Sanción ?</font>


</label>
                <div class="input-group">
               


                <input className="form-control"  value="NO"  readOnly/>
      


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>
      

      <div class="col-sm-2">
     
      { value3==='SI' ? (<div> <label><font size="2"># Tarjetas</font></label>
     <br />
     <br />
     <div class="input-group">
     
     <select name="tarjetas2"
       
       class="form-control" 
       name="tarjetas2"
       value={props.getState('tarjetas2', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    
    
  </select>  

   {/*<span class="input-group-append">
                   <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
    
      </div> 
      </div>
      ):null}
     
     </div>

     <div class="col-sm-2">
      { value3==='SI' ? (<div>
      <label><font size="2">Fechas de suspención</font></label>
      <br />
      <br />
      <div class="input-group">
      <select name="desdea2"
       
       class="form-control" 
       name="desdea2"
       value={props.getState('desdea2', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="1_suspendido">1 juego Suspendido</option>
    <option value="2_suspendido">2 juegos Suspendido</option>
    <option value="3_suspendido">3 juegos Suspendido</option>
    <option value="4_suspendido">4 juegos Suspendido</option>
    <option value="5_suspendido">5 juegos Suspendido</option>
    
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div>
      ):null}
     

      </div>



      <div class="col-sm-2">
      { value3==='SI' ? (<div> <label><font size="2">Desde</font></label>
      <br />
      <br />
      <div class="input-group">
      <select name="desde2"
       
       class="form-control" 
       name="desde2"
       value={props.getState('desde2', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div>
      ):null}
     

      </div>


      <div class="col-sm-2">
      { value3==='SI' ? (<div> <label><font size="2">Hasta</font></label>
      <br />
      <br />
      <div class="input-group">
      <select name="hasta2"
       
       class="form-control" 
       name="hasta2"
       value={props.getState('hasta2', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="Fase1">Fase1</option>
    <option value="Fase2">Fase2</option>
    <option value="Fase3">Fase3</option>
    <option value="Fase4">Fase4</option>
    <option value="Fase5">Fase5</option>
    <option value="Fase6">Fase6</option>
    <option value="Fase7">Fase7</option>
    <option value="Fase8">Fase8</option>
    <option value="Fase9">Fase9</option>
    <option value="Fase10">Fase10</option>
    
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

       </div>
      ):null}
      

      </div>

      </div>
      
      
      <hr />
      
      

    
      <div class="row"> 

      <center> <h6>Sanciones Por Tarjetas Rojas = Partidos Sancionados de Manera Consecutiva.  
</h6> </center> 
      


      <div class="col-sm-1">
      <img src="dist/img/tarjeta1.png" alt="plooy" width="80px" />

                <div class="input-group">
                 
       
       </div> 
      </div>


      <div class="col-sm-2">
                <label><font size="2">Fechas Suspendido </font>




</label>
                <div class="input-group">
               

                <input className="form-control"  value="2"  readOnly/>
      


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>



      
      <div class="col-sm-2">
      <div> <label><font size="2">Desde</font></label>
      <br />
      
      <div class="input-group">
      <input className="form-control"  value="Fase2"  readOnly/>
       </div> 

       </div>

      </div>


      <div class="col-sm-2">
     <div><label><font size="2">Hasta</font></label>
      <br />
      <div class="input-group">
      <input className="form-control"  value="Fase3"  readOnly/>


     
       </div> 

       
       </div>

      </div>


    
     

      </div>

<br />

    
      <div class="row"> 

      <div class="col-sm-1">
      <img src="dist/img/tarjeta2.png" alt="plooy" width="50px" />

                <div class="input-group">
                 
       
       </div> 
      </div>


      <div class="col-sm-2">
                <label><font size="2">Fechas Suspendido </font>




</label>
                <div class="input-group">
              

                <input className="form-control"  value="1"  readOnly/> 
      

       </div> 
      </div>

      
      <div class="col-sm-2">
     <div><label><font size="2">Desde</font></label>
      <br />
      <div class="input-group">
      <input className="form-control"  value="Fase1"  readOnly/>

     
       </div> 

       </div>


      </div>


      <div class="col-sm-2">
     <div><label><font size="2">Hasta</font></label>
      <br />
      <div class="input-group">
      <input className="form-control"  value="Fase2"  readOnly/>


  
     
       </div> 

       </div>


      </div>

      

      </div>



<br/>


      <div class="row"> 

      <div class="col-sm-1">
      <img src="dist/img/tarjeta3.png" alt="plooy" width="20px" />

                <div class="input-group">
                 
       
       </div> 
      </div>


      <div class="col-sm-2">
                <label><font size="2">Fechas Suspendido </font>




</label>
                <div class="input-group">
              

                <input className="form-control"  value="3"  readOnly/>
      

     
       </div> 
      </div>

      
      <div class="col-sm-2">
      <div><label><font size="2">Desde</font></label>
      <br />
      <div class="input-group">
      <input className="form-control"  value="Fase5"  readOnly/>


   
     
       </div> 

       </div>


      </div>


      <div class="col-sm-2">
      <div><label><font size="2">Hasta</font></label>
      <br />
      <div class="input-group">
      <input className="form-control"  value="Fase7"  readOnly/>  


     
       </div> 

       </div>

      </div>
     
      <row>
      <div class="col-sm-10">
            

            <label><font size="2">Sanciones Por Tarjeta Roja Para Casos Puntuales: Por Acciones Disciplinarias Extras serán definidas por el organizador.</font>
</label>
      
 
      </div>
      </row>

      

      </div>

     
      
     

      




       
      
    







      

    


  </form>
                  </div>
                 
                </div>
    
    
{/* puntos ganador*/}
<Modal isOpen={modalPuntosGanador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Ganador Del Juego.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPuntosGanador()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* empate juego*/}
<Modal isOpen={modalEmpateJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos Por Empate De Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Cada Equipo por Empate Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEmpateJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* perdedor juego*/}
<Modal isOpen={modalPerdedorJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Perdedor Del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Perdedor Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPerdedorJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* ganador juego W*/}
<Modal isOpen={modalGanadorJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados A Favor Al Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGanadorJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* goles juego W*/}
<Modal isOpen={modalGolesJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles a Favor al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Otorgados A Favor Del Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGolesJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* contra juego W*/}
<Modal isOpen={modalContraJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos En Contra Por Perder el Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* contra juego W2*/}
<Modal isOpen={modalContraJuegoW2}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles En Contra al Perdedor del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW2()}>Aceptar</button>
 </ModalFooter>
</Modal>
    
          </div>
    
 


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
        
}
 }

export default Criterios_tarjetas_2