import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

function Row({ onChange, onRemove, modulo, deporte, subdeporte, pais, departamento, ciudad }) {
    return (
        <div className="form-group">
   <div className="row">
     <label className="btn btn-warning">PERMISOS</label>  
     <br/>
   <div class="col-4">
   <label>Modulo:</label>
       <br/>
   <select name="modulo" class="form-control"  onChange={e => onChange("modulo", e.target.value)} value={modulo}>
        <option value='no' selected>Seleccione</option>
                       <option value="Torneo">Torneo</option>
                       
                     </select>  
                     <br/> 
       </div>

       <div class="col-4">
       <label>Deporte:</label>
       <br/>
       <select name="deporte" class="form-control"  onChange={e => onChange("deporte", e.target.value)} value={deporte}>
        <option value='no' selected>Seleccione</option>
                       <option value="deporte">deporte</option>
                       
                     </select> 
                     <br/>
       </div>

       <div class="col-4">
       <label>Subdeporte:</label>
       <br/>
       <select name="subdeporte" class="form-control"  onChange={e => onChange("subdeporte", e.target.value)} value={subdeporte}>
        <option value='no' selected>Seleccione</option>
                       <option value="11x11">11x11</option>
                       <option value="10x10">10x10</option>
                      
                     </select>  
                     <br/>
       </div>
        
           
       <div class="col-4">
       <label>Pais:</label>
       <br/>
                     <select name="pais" class="form-control"  onChange={e => onChange("pais", e.target.value)} value={pais}>
        <option value='no' selected>Seleccione</option>
                       <option value="Colombia">Colombia</option>
                       
                     </select>  
                     <br/>
        </div>

        <div class="col-4">
        <label>Departamento:</label>
        <br/>
        <select name="departamento" class="form-control"  onChange={e => onChange("departamento", e.target.value)} value={departamento}>
        <option value='no' selected>Seleccione</option>
                       <option value="Tolima">Tolima</option>
                       
                     </select> 
                     <br /> 
                     </div>

        <div class="col-4">
        <label>Ciudad:</label>
     <br/>
           <select name="ciudad" class="form-control"  onChange={e => onChange("ciudad", e.target.value)} value={ciudad}>
        <option value='no' selected>Seleccione</option>
                       <option value="Ibague">Ibague</option>
                       <option value="Espinal">Espinal</option>
                       
                     </select> 
                     <br /> 
                     </div>
           <button className="btn btn-danger" onClick={onRemove}>Eliminar</button>
         </div>
         </div>
         
    )
}

export default Row
