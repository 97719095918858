import React, {useEffect, useContext, useState} from 'react'


//logo confenalco

import LogoConfenalco from '../../img/confenalco.jpg';

import LogoBalon from '../../img/balon.png';

import Reglamento1 from '../../img/reglamento1.png';

import Reglamento2 from '../../img/reglamento2.png';

import Reglamento3 from '../../img/reglamento3.png';

import Reglamento4 from '../../img/reglamento4.png';



import LogoTorneo1 from '../../img/alto.png';

import Reglamento from '../../img/reglamento_torneo_verde.png'
import Programacion from '../../img/programacion.png'
import Grupos_gris from '../../img/grupos_gris.png'

import LogoTorneo from '../../img/torneos.png'


import { Steps, Step } from "react-step-builder";


//Acordion
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';




import {MyContext} from '../../contexts/MyContext';


import "react-datepicker/dist/react-datepicker.css";

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';


import axios from 'axios';


import Datos_generales_torneo_2 from "./Datos_generales_torneo_2";
import Datos_generales_torneo2_2 from "./Datos_generales_torneo2_2";

import Jugadores_autorizados_2 from "./Jugadores_autorizados_2";

import Puntos_de_juegos from "./Puntos_de_juegos";
import Puntos_juegos_guardar from './Puntos_juegos_guardar';

import Criterios_desempate from "./Criterios_desempate";
import Criterios_desempate_guardar from './Criterios_desempate_guardar';

import Cambios_planilla from "./Cambios_planilla";

import Criterios_tarjetas from "./Criterios_tarjetas";
import Criterios_tarjetas_guardar from "./Criterios_tarjetas_guardar";

import Goleador from "./Goleador";
import Goleador_guardar from "./Goleador_guardar";

import Reglamento_adicional from "./Reglamento_adicional";
import Reglamento_adicional_guardar from "./Reglamento_adicional_guardar";
import Pruebas2 from './Pruebas2';

 function Torneo_pendiente3(props) {

    //Acordion
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));


  //guardar
  const baseUrlAd="https://www.deportes.controlsas.com/apiPlooy/Solicitar_empresa/"
  const [data, setData]=useState([]);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    guardar_equipos:'',
  });

//fecha de solo año
  const [startDate, setStartDate] = useState(new Date());
   
  //minutos por tiempo
  const [minutes, setMinutes] = useState("");

  //minutos por tiempo
  useEffect(() => {
    if (minutes && (+minutes < 1 || +minutes > 45)) setMinutes("")
  }, [minutes])


  //minutos por tiempo
  const handleChange = ({ target: { value } }) => setMinutes(value);

  //BOTON EDITAR
  const [modoedicion, setModoEdicion] = useState(null) 

  const [dropdown, setDropdown]=useState(false);
  const [dropdown2, setDropdown2]=useState(false);
  const [dropdown3, setDropdown3]=useState(false);
  const [dropdown4, setDropdown4]=useState(false);

 //Modal
  const [modalTiemposJuego, setModalTiemposJuego]=useState(false);
  const [modalMinutosTiempo, setModalMinutosTiempo]=useState(false);
  const [modalGenero, setModalGenero]=useState(false);
  const [modalEdad, setModalEdad]=useState(false);

  const [modalSuperficie, setModalSuperficie]=useState(false);
  const [modalEquipos, setModalEquipos]=useState(false);
  const [modalJugadores, setModalJugadores]=useState(false);
  const [modalFecha, setModalFecha]=useState(false);
  const [modalImagen, setModalImagen]=useState(false);

  //select genero
  const [value, setValue] = React.useState()


  //select edad
  const [value2, setValue2] = React.useState()


  //select edad libre
  const defaultState = {
    numero: "0",
    numero1: "",
    numero2: "",

    numero_cero: "0",
    numero1_uno: "",

  };


  

//Modal

const abrirCerrarModalTiemposJuegos=()=>{
  setModalTiemposJuego(!modalTiemposJuego);
}

const abrirCerrarModalMinutosTiempo=()=>{
  setModalMinutosTiempo(!modalMinutosTiempo);
}

const abrirCerrarModalGenero=()=>{
  setModalGenero(!modalGenero);
}

const abrirCerrarModalEdad=()=>{
  setModalEdad(!modalEdad);
}



const abrirCerrarModalSuperficie=()=>{
  setModalSuperficie(!modalSuperficie);
}

const abrirCerrarModalEquipos=()=>{
  setModalEquipos(!modalEquipos);
}

const abrirCerrarModalJugadores=()=>{
  setModalJugadores(!modalJugadores);
}

const abrirCerrarModalFecha=()=>{
  setModalFecha(!modalFecha);
}

const abrirCerrarModalImagen=()=>{
  setModalImagen(!modalImagen);
}


//guardar
const handleChange_guardar=e=>{
  e.preventDefault();
  const {name, value}=e.target;
  setFrameworkSeleccionado((prevState)=>({
    ...prevState,
    [name]: value
  }))
 console.log(frameworkSeleccionado);
}

//guardar
const peticionPost = async() =>{
 
  var f = new FormData();
  f.append("guardar_equipos", frameworkSeleccionado.guardar_equipos);
  f.append("METHOD", "POST");
  await axios.post(baseUrlAd,f)
  .then(response=>{
   setData(data.concat(response.data));
  // abrirCerrarModalInsertar();
  setModoEdicion(true)
  alert("Información guardada correctamente");
  }).catch(error=>{
    console.log(error);
  })

}

const peticionEditar =()=>{
  alert("Información se ha editado correctamente");
}


// edad libre
function Row({ onChange, onRemove, numero, numero1, numero2 }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-4">
         
         <input
        type="number"
        value={numero1}
        onChange={e => onChange("numero1", e.target.value)}
        class="form-control"
        
      />

</div>

<div class="col-sm-4">
         
         <input
        type="number"
        value={numero2}
        onChange={e => onChange("numero2", e.target.value)}
        class="form-control"
        
      />



      </div>
<br/>

 <br/>

 <div class="col-sm-3">

      <input
        type="number"
        value={numero}
        onChange={(e) => onChange("numero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}


//edad libre
const [rows, setRows] = useState([defaultState]);
const [amount, setAmount] = useState(0);

const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};

const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  let total = 0;
  rows.map(function (num) {
    total = total + parseInt(num.numero);
  });
  console.log(total);
  setAmount(total);
  setRows(rows.concat(defaultState));
};

const handleOnRemove = (index) => {
  setAmount(amount - parseInt(rows[index].numero));
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
console.log(amount);





// edad menores de edad

function Row2({ onChange, onRemove, numero_cero, numero_uno }) {
  return (
     
    <div className="col-sm-12">

        <div class="row">

        <div class="col-sm-5">
         
         <input
        type="number"
        value={numero_uno}
        onChange={e => onChange("numero_uno", e.target.value)}
        class="form-control"
        
      />

      </div>
<br/>

 <br/>

 <div class="col-sm-5">

      <input
        type="number"
        value={numero_cero}
        onChange={(e) => onChange("numero_cero", e.target.value)}
        class="form-control"
      />
<br/>
</div>

<div class="col-sm-1">

      <button onClick={onRemove} className="btn btn-danger"><i class="fa fa-trash"></i></button>

      </div>
    </div>

    </div>
  );
}




//edad menos de edad
const [rowss, setRowss] = useState([defaultState]);
const [amounts, setAmounts] = useState(0);




 //sumar generos
 const [number, setNumber] = useState({pago_uno: 0, pago_dos: 0})
  const [suma, setSuma] = useState()

  useEffect(()=>{
    const {pago_uno, pago_dos} = number
    setSuma(Number(pago_uno) + Number(pago_dos))
},[number])
  

//acordeon
  const [color, setName] = useState([]);


// agregar acordeon
const classes = useStyles();
  
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {

    return (
        
        
       
            
              <div className="col-sm-12">
           
         
             


    
              <div class="card">

              <div>
              <h3 class="card-title" ><button style={{backgroundColor:"transparent", border:"none"}} ><img src={Reglamento} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} onClick={props.next}><img src={Programacion} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}}><img src={Grupos_gris} width="145px" /></button>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
               <font size="1"><img src={LogoTorneo} width="20px" /> TORNEOS / &nbsp; 
                              <img src={LogoTorneo1} width="20px" /> PENDIENTES 
                             
                             </font> </h3>
              
              </div>
                  
                  <div class="card-header text-center">
                    <h3 class="card-title"><img src={LogoConfenalco} width="45px" /><img src={LogoBalon} width="45px" /> <b>TORNEO MAYORES DE 22 AÑOS </b>  - Colombia – Tolima – Ibagué – Futbol - F11 – G Natural – Masculino – Dici 2021</h3>
                    <br /><h3 class="card-title"> 
  </h3>
                  </div>

                  
                
                  <div class="card-body">
                      
              {   /* <form >*/ }




<div className={classes.root}>
<br/>
        <Accordion style={{ backgroundColor: "#CDFFAA", }}>
        
          
         
          
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} >1. Datos Generales del Torneo</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography className={classes.root}>
            <Steps>
      
        <Step component={Datos_generales_torneo_2} />
        <Step component={Datos_generales_torneo2_2} />
        
      </Steps>
              
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ backgroundColor: "#CDFFAA", }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>2. Esquema del Torneo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography  className={classes.root}>
            Equipos Inscritos: 10 – Grupos: 2 – Equipos Por Grupo: 5
Clasificarán a Siguiente Fase: Los Mejores 2 Equipos De Cada Grupo / Los 2 Mejores Promedios de la Tercera Posición – El Mejor Promedio de la Cuarta Posición, Para Un Total de 5 Equipos.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ backgroundColor: "#CDFFAA", }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading} >3. Jugadores Autorizados En Campo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Jugadores_autorizados_2} />
        
      </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ backgroundColor: "#FFBBAA", }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading} >4. Puntos de Juego - Puntos por W</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Puntos_de_juegos} />
          <Step component={Puntos_juegos_guardar} />
        
      </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ backgroundColor: "#FFBBAA", }} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>5. Criterios de Desempate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Pruebas2} />
            {/*<Step component={Criterios_desempate} />*/}
          <Step component={Criterios_desempate_guardar} />
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ backgroundColor: "#FFBBAA", }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography className={classes.heading}>6. Cambios en Planilla</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography className={classes.root}>
            <Steps>
            <Step component={Cambios_planilla} />
          
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


       

        <Accordion style={{ backgroundColor: "#FFBBAA", }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography className={classes.heading}>7. Criterios de Tarjetas</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography className={classes.root}>
            <Steps>
            <Step component={Criterios_tarjetas} />
            <Step component={Criterios_tarjetas_guardar} />
          
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ backgroundColor: "#FFBBAA", }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography className={classes.heading}>8. Goleador - Portero Menos Vencido</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Goleador} />
          <Step component={Goleador_guardar} />
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ backgroundColor: "#FFBBAA", }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Typography className={classes.heading}>9. Reglamento Adicional</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            <Step component={Reglamento_adicional} />
          <Step component={Reglamento_adicional_guardar} />
          </Steps>
            </Typography>
          </AccordionDetails>
        </Accordion>
        
      </div> 



  


    



        <br />      


 

      <div class="col-sm-12">

          



</div>




<button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;


      

<Modal isOpen={modalTiemposJuego}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'red'}}>!</i> Cuantos tiempos</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cuantos Tiempos de Juego Para Cada Partido</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalTiemposJuegos()}>Aceptar</button>
 </ModalFooter>
</Modal>   




<Modal isOpen={modalMinutosTiempo}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'red'}}>!</i> Minutos por tiempo</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Determina Cuantos Minutos a Jugar Por Cada Tiempo.</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalMinutosTiempo()}>Aceptar</button>
 </ModalFooter>
</Modal>   



<Modal isOpen={modalGenero}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Genero</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Define el genero(s) que parciparan en el torneo por cada equipo. </p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGenero()}>Aceptar</button>
 </ModalFooter>
</Modal>   


<Modal isOpen={modalEdad}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Edad</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Define Las Restricciones o Limitaciones de Edad Para Cada Equipo en el Torneo</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEdad()}>Aceptar</button>
 </ModalFooter>
</Modal>   



<Modal isOpen={modalEquipos}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> N° de equipos</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cuantos Equipos Disputaran el Torneo</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEquipos()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalJugadores}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Jugadores por equipo</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cuantos Jugadores Máximo Inscritos en Planilla Por Equipo</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalJugadores()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalFecha}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Fecha de inicio</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Cual Es La Fecha De Inicio Del Torneo.</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalFecha()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalImagen}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Imagen del torneo</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Adjunta Una Imagen Comercial De Tu Torneo: <br />
Imagen: Peso Máximo 14KB – Tamaño 11,3 X 8,1 <br />
Información En la Imagen: <br />
Nombre Del Torneo <br />
2. Ciudad Del Torneo <br />
3. Sub Deporte <br />
4. Genero <br />
5. Superficie De Juego <br />
6. Edad <br />
7. Datos adicionales a convenir entre las partes
</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalImagen()}>Aceptar</button>
 </ModalFooter>
</Modal> 


<Modal isOpen={modalSuperficie}>
 <ModalHeader><i class="far fa-lightbulb fa-2x" style={{color:'orange'}}>!</i> Superfice de juego</ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<p>Define el Terreno o Superficie de Juego</p>
       
   </div>
 </ModalBody>
 <ModalFooter>
 
   
  <button className="btn btn-warning" onClick={()=>abrirCerrarModalSuperficie()}>Aceptar</button>

 </ModalFooter>
</Modal> 



  {/*</form>*/}
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
       

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
      
}
 }

 

export default Torneo_pendiente3