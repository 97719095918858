import React, {useContext,useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

//Agregar campo dinamico
const defaultState = {
  nombre: "",
};



//Agregar campo dinamico
function Row({ onChange, onRemove, datos_adicionales}) {
 
  return (
    <div>
      
      <div class="input-group">
      <input name="datos_adicionales"
        value={datos_adicionales}
        className="form-control" id="inputSuccess"
       
       /* style={{ "background-color": !input3 && "#D3F9FF" }}
        value={input3}
        onChange={({ target }) => setInput3(target.value)}*/
        placeholder="Datos adicionales"
      />
       
       <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" ><b>?</b></button>
                  </span>
     
       </div> 
   <br/>
     


    
    </div>
  );
}


 function Datos_generales_torneo(props) {

  //colores inputs y selects
  const [input, setInput] = useState();

  const [input2, setInput2] = useState();

// const [input3, setInput3] = useState();

const [select, setSelect] = useState();

const [select2, setSelect2] = useState();

const [select3, setSelect3] = useState();

const [select4, setSelect4] = useState();

const [select5, setSelect5] = useState();


 //agregar campo dinamico
 const [rows, setRows] = useState([defaultState]);

//Modal organizador
const [modalOrganizador, setModalOrganizador]=useState(false);

 //Modal
 const [modalNombreTorneo, setModalNombreTorneo]=useState(false);

 const [modalDatosAdicionales, setModalDatosAdicionales]=useState(false);

 

 //agregar campo dinamico
const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};


//Modal organizador

const abrirCerrarModalOrganizador=()=>{
  setModalOrganizador(!modalOrganizador);
}

//Modal

const abrirCerrarModalNombreTorneo=()=>{
  setModalNombreTorneo(!modalNombreTorneo);
}

const abrirCerrarModalDatosAdicionales=()=>{
  setModalDatosAdicionales(!modalDatosAdicionales);
}



 //agregar campo dinamico
const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  setRows(rows.concat(defaultState));
};
/*
 //agregar campo dinamico
const handleOnRemove = (index,e) => {
  e.preventDefault(); //esto previene que el form se mande.
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
*/

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {

    return (
        
     
             
      <div className="col-sm-12">
    
              <div class="card card-primary">
                  
                  <div class="card-header">
                    <h3 class="card-title">DATOS GENERALES TORNEO</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    <div class="row">

    <div class="col-sm-3">

    <div class="input-group">
      
    <input 
       
     className="form-control" 
        
        placeholder="Organizador / Sucursal"
        maxLength="50"
        style={{ "background-color": !input && "#D3F9FF" }}
        name="organizador"
        value={props.getState('organizador', '')} 
        onChange={props.handleChange}
        
       
      />
      <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalOrganizador()}><b>?</b></button>
                  </span>
     
       </div> 

       </div> 
    

     <div class="col-sm-3">
     <div class="input-group">

      
          
        <select name="deporte"
        style={{ "background-color": !select && "#D3F9FF" }}
         class="form-control" 
         name="deporte"
         value={props.getState('deporte', '')} 
         onChange={props.handleChange}
        /* value={props.getState('deporte', '')} onChange={({ target }) => setSelect(target.value)}*/ >
<option value='no' selected>Deporte</option>
      <option value="Futbol">Futbol</option>
      
    </select>  
    <i class="fa fa-asterisk" aria-hidden="true" style={{color:"red", padding:"10px"}}></i>
   
        </div>
      </div>
    
    
      <div class="col-sm-3">
        
      <div class="input-group">
        <select 
         style={{ "background-color": !select2 && "#D3F9FF" }}
        /*name="subdeporte" */class="form-control"
        /*value={props.getState('subdeporte', '')} onChange={({ target }) => setSelect2(target.value)}*/
        name="subdeporte"
        value={props.getState('subdeporte', '')} 
        onChange={props.handleChange}>
<option value='no' selected>Subdeporte</option>
      <option value="11x11">F11</option>
      <option value="10x10">F10</option>
      <option value="9x9">F9</option>
      <option value="8x8">F8</option>
      <option value="7x7">F7</option>
      <option value="6x6">F6</option>
      <option value="5x5">F5</option>
      <option value="5x5futsal">Futsal</option>
      <option value="5x5micro">Microfutbol</option>
      <option value="5x5banquitas">Banquitas</option>
      <option value="Futbol playa">Futbol playa</option>
      
    </select> 
    <i class="fa fa-asterisk" aria-hidden="true" style={{color:"red", padding:"10px"}}></i> 
   
        </div>
      </div>



     
  
      </div>

<br />

      <div class="row">
          
     
        
      <div class="col-sm-3">
      <div class="input-group">
     
      <select /*name="pais"*/ class="form-control" 
       style={{ "background-color": !select3 && "#D3F9FF" }}
     /*  value={props.getState('pais', '')}  onChange={({ target }) => setSelect3(target.value)}*/
     name="pais"
     value={props.getState('pais', '')} 
     onChange={props.handleChange}>
 <option value='no' selected>Pais</option>
  <option value="Colombia">Colombia</option>
 
</select>
    
<i class="fa fa-asterisk" aria-hidden="true" style={{color:"red", padding:"10px"}}></i> 
      </div>
      </div>

      <div class="col-sm-3">
      <div class="input-group">
      <select /*name="departamento"*/ class="form-control"
      style={{ "background-color": !select4 && "#D3F9FF" }}
      /* value={props.getState('departamento', '')} onChange={({ target }) => setSelect4(target.value)}*/
      name="departamento"
      value={props.getState('departamento', '')} 
      onChange={props.handleChange}>
 <option value='no' selected>Departamento</option>
  <option value="Tolima">Tolima</option>
 
</select>     
<i class="fa fa-asterisk" aria-hidden="true" style={{color:"red", padding:"10px"}}></i> 
      </div>
      </div>

      
    


      <div class="col-sm-2">
      <div class="input-group">
      <select /*name="ciuadad"*/ class="form-control"  
      style={{ "background-color": !select5 && "#D3F9FF" }}
     /* onChange={({ target }) => setSelect5(target.value)}*/
     name="ciudad"
     value={props.getState('ciudad', '')} 
     onChange={props.handleChange}>
 <option value='no' selected>Ciudad</option>
  <option value="Ibagué">Ibague</option>
  <option value="Espinal">Espinal</option>
 
</select>    
 
<i class="fa fa-asterisk" aria-hidden="true" style={{color:"red", padding:"10px"}}></i> 
      </div>
      </div>

      <div class="col-sm-4">
     

          

      
  

    <div class="input-group">
      
    <input 
       
     className="form-control" 
        
        placeholder="Datos adicionales "
        maxLength="50"
        style={{ "background-color": !input && "#D3F9FF" }}
        /*value={input}
        onChange={({ target }) => setInput(target.value)}*/
        name="datos_adicionales"
        value={props.getState('datos_adicionales', '')} 
        onChange={props.handleChange}
       
      />
      <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalDatosAdicionales()}><b>?</b></button>
                  </span>
     
       </div> 

     

</div>

      


    
      
    </div>

    <br />


    <div class="row">

      <div class="col-sm-6">

      <div class="input-group">

          

      
      <input type="text" /*name="name_torneo"*/  className="form-control " id="inputSuccess"
      
      style={{ "background-color": !input2 && "#D3F9FF" }}
       /* value={input2}
        onChange={({ target }) => setInput2(target.value)} */ placeholder="Nombre del torneo" 
        name="nombre_torneo"
        value={props.getState('nombre_torneo', '')} 
        onChange={props.handleChange} />
        
     
      <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalNombreTorneo()}><b>?</b></button>
                  </span>
                 
 
</div>

</div>



</div>

<br/>



<button className="btn btn-success"  onClick={props.next}>GUARDAR </button>

      

    
<Modal isOpen={modalNombreTorneo}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Nombre del torneo</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Asigna Un Nombre al Torneo (No debe Contener Mas de 40 Caracteres)</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalNombreTorneo()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* organizador */}
<Modal isOpen={modalOrganizador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Organizador / Sucursal</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>(Sub) Nombre o Sucursal, para el caso de empresas / Organizadores que tengan un mismo torneo en diferentes sucursales
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalOrganizador()}>Aceptar</button>
 </ModalFooter>
</Modal>



<Modal isOpen={modalDatosAdicionales}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b> Datos adicionales </b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
   <h3><b>Para el caso de zonas geográficas no registradas en la base de datos.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalDatosAdicionales()}>Aceptar</button>
 </ModalFooter>
</Modal>

  </form>
                  </div>
                 
                </div>
    
    
                </div>

             

                
    
    
         
         

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
     
}
 }

export default Datos_generales_torneo