import React,{useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';


function Ver_deportistas(props) {
  

  //const baseUrlAd="https://www.plooy.club/apiFrameworks5/"
  const baseUrlAd="https://www.deportes.controlsas.com/apiPlooy/ver_deportistas/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    pais:'',
    departamento:'',
    ciudad:'',
    name:'',
    apellidos:'',
    tdoc:'',
    documento:'',
    fechanacimiento:'',
    genero:'',
    nacionalidad:'',
    direccion:'',
    ciudad_nacionalidad:'',
    telefono:'',
    comercio_natural:'',
    email:'',
  });

  
  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

  const peticionPost = async() =>{
    var f = new FormData();
    f.append("nick", frameworkSeleccionado.nick);
    f.append("nombres", frameworkSeleccionado.nombres);
    f.append("apellidos", frameworkSeleccionado.apellidos);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("telefono", frameworkSeleccionado.telefono);
    f.append("email", frameworkSeleccionado.email);
    f.append("fechanacimiento", frameworkSeleccionado.fechanacimiento);
    f.append("edad", frameworkSeleccionado.edad);
    f.append("contrasena", frameworkSeleccionado.contrasena);
    f.append("repetircontrasena", frameworkSeleccionado.repetircontrasena);
    f.append("modulo", frameworkSeleccionado.modulo);
    f.append("deporte", frameworkSeleccionado.deporte);
    f.append("subdeporte", frameworkSeleccionado.subdeporte);
    f.append("pais", frameworkSeleccionado.pais);
    f.append("departamento", frameworkSeleccionado.departamento);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("METHOD", "POST_administrador");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPut = async() =>{
    var f = new FormData();
    f.append("pais", frameworkSeleccionado.pais);
    f.append("departamento", frameworkSeleccionado.departamento);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("name", frameworkSeleccionado.name);
    f.append("apellidos", frameworkSeleccionado.apellidos);
    f.append("tdoc", frameworkSeleccionado.tdoc);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("fechanacimiento", frameworkSeleccionado.fechanacimiento);
    f.append("genero", frameworkSeleccionado.genero);
    f.append("nacionalidad", frameworkSeleccionado.nacionalidad);
    f.append("direccion", frameworkSeleccionado.direccion);
    f.append("ciudad_nacionalidad", frameworkSeleccionado.ciudad_nacionalidad);
    f.append("telefono", frameworkSeleccionado.telefono);
    f.append("comercio_natural", frameworkSeleccionado.comercio_natural);
    f.append("email", frameworkSeleccionado.email);
    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
        framework.pais= frameworkSeleccionado.pais;
        framework.departamento= frameworkSeleccionado.departamento;
        framework.ciudad= frameworkSeleccionado.ciudad;
         framework.name= frameworkSeleccionado.name;
         framework.apellidos= frameworkSeleccionado.apellidos;
         framework.tdoc= frameworkSeleccionado.tdoc;
         framework.documento= frameworkSeleccionado.documento;
         framework.fechanacimiento = frameworkSeleccionado.fechanacimiento;
         framework.genero = frameworkSeleccionado.genero;
         framework.nacionalidad = frameworkSeleccionado.nacionalidad;
         framework.direccion = frameworkSeleccionado.direccion;
         framework.ciudad_nacionalidad = frameworkSeleccionado.ciudad_nacionalidad;
         framework.telefono= frameworkSeleccionado.telefono;
         framework.comercio_natural = frameworkSeleccionado.comercio_natural;
         framework.email= frameworkSeleccionado.email;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    peticionGet();

  },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
     // type:"numeric"
    },
    
    {
      title:"EMAIL",
      field: "email"
    },

    {
        title:"CELULAR",
        field: "telefono"
      },

      {
        title:"NICK",
        field: "nick"
      },

      {
        title:'TIPO DE USUARIO',
        field:'tipo_usuario'
      },

    
    
  ];


    return (

        <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h3 className="m-0"></h3>
          </div>
          <div className="col-sm-6">
            
          </div>
        </div>
      </div>
    </div>

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

         
            <div>
              
            <MaterialTable

      
     
columns={columnas}
data={data}
title="DEPORTISTAS"
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

   
    
  {
   icon : 'delete',
   tooltip: 'Eliminar',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }
]}
options={{
  actionsColumnIndex: -1
}}
options={{
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
 },

 headerStyle: { backgroundColor: "rgb(175 174 173)",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white" }
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>

<Modal isOpen={modalInsertar}>
 <ModalHeader><img src="dist/img/administrador.png" width="50px" /> AGREGAR ADMINISTRADOR</ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">
   <div class="col-4">
     <label>Nick name:</label>
     <br/>
     
     <input type="text" className="form-control" placeholder="Nick" name="nick" onChange={handleChange} />
     
  </div>

  <div class="col-8">
     <label>Nombres:</label>
     <br/>
     
     <input type="text" className="form-control" placeholder="Nombres" name="nombres" onChange={handleChange} />
     <br/>
  </div>

  <div class="col-4">
     <label>Apellidos:</label>
     <br/>
     
     <input type="text" className="form-control" placeholder="Apellidos" name="apellidos" onChange={handleChange} />
     
  </div>

  <div class="col-4">
     <label>Documento:</label>
     <br/>
     
     <input type="number" className="form-control" placeholder="Documento" name="documento" onChange={handleChange} />
     
  </div>

  <div class="col-4">
     <label>Teléfono:</label>
     <br/>
     
     <input type="number" className="form-control" placeholder="Telefono" name="telefono" onChange={handleChange} />
     <br />
  </div>

  <div class="col-4">
     <label>Email:</label>
     <br/>
     
     <input type="text" className="form-control" placeholder="Email" name="email" onChange={handleChange} />
     
  </div>

  <div class="col-5">
     <label>Fecha de nacimiento:</label>
     <br/>
     
     <input type="date" className="form-control" placeholder="Fecha nacimiento" name="fechanacimiento" onChange={handleChange} />
     
  </div>

  <div class="col-2">
     <label>Edad:</label>
     <br/>
     
     <input type="Number" className="form-control" name="edad" onChange={handleChange} />
     <br />
  </div>

  <div class="col-5">
     <label>Contraseña:</label>
     <br/>
     
     <input type="password" className="form-control" name="contrasena" onChange={handleChange} />
    
  </div>

  <div class="col-6">
     <label>Repetir contraseña:</label>
     <br/>
     
     <input type="password" className="form-control" name="repetircontrasena" onChange={handleChange} />
     <br />
  </div>
     

  <div class="col-4">
     <label>Modulo:</label>
     <br/>
     <select name="modulo" class="form-control"  onChange={handleChange}>
     <option value='no' selected>Seleccione</option>
                    <option value="Torneo">Torneo</option>
                    
                  </select>  
     
  </div>

  <div class="col-4">
     <label>Deporte:</label>
     <br/>
     <select name="deporte" class="form-control" onChange={handleChange}>
     <option value='no' selected>Seleccione</option>
                    <option value="Futbol">Futbol</option>
                    
                  </select>  
     
  </div>

  <div class="col-4">
     <label>Subdeporte:</label>
     <br/>
     <select name="subdeporte" class="form-control" onChange={handleChange}>
     <option value='no' selected>Seleccione</option>
                    <option value="11x11">11x11</option>
                    <option value="10x10">10x10</option>
                    
                  </select>  
     <br />
  </div>

  <div class="col-4">
     <label>Pais:</label>
     <br/>
     <select name="pais" class="form-control" onChange={handleChange}>
     <option value='no' selected>Seleccione</option>
                    <option value="Colombia">Colombia</option>
                  
                  </select>  
    
  </div>

  <div class="col-4">
     <label>Departamento:</label>
     <br/>
     <select name="departamento" class="form-control" onChange={handleChange}>
     <option value='no' selected>Seleccione</option>
                    <option value="Tolima">Tolima</option>
                  
                  </select>  
    
  </div>

  <div class="col-4">
     <label>Ciudad:</label>
     <br/>
     <select name="ciudad" class="form-control" onChange={handleChange}>
     <option value='no' selected>Seleccione</option>
                    <option value="Ibague">Ibague</option>
                  
                  </select>  
    
  </div>
     
    

     
   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
 </ModalFooter>
</Modal>

<Modal isOpen={modalEditar}>
 <ModalHeader><img src="dist/img/administrador.png" width="50px" /> EDITAR PERSONA NATURAL</ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-4">
     <label>Pais:</label>
     <br/>
     <select name="pais" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.pais}>
     <option value='no' selected>Seleccione</option>
                    <option value="Colombia">Colombia</option>
                    
                  </select>  
     </div>

     <div class="col-4">
     <label>Departamento:</label>
     <br/>
     <select name="departamento" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.departamento}>
     <option value='no' selected>Seleccione</option>
                    <option value="Tolima">Tolima</option>
                    
                  </select>  
     </div>

     <div class="col-4">
     <label>Ciudad:</label>
     <br/>
     <select name="departamento" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.ciudad}>
     <option value='no' selected>Seleccione</option>
                    <option value="Ibague">Ibague</option>
                    
                  </select>  
                  <br />
     </div>
   
   
     <div class="col-4">
     <label>Nombres:</label>
     <br/>
     <input type="text" className="form-control" name="name" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.name} />
     <br/>
     </div>
     <div class="col-8">
     <label>Apellidos:</label>
     <br/>
     <input type="text" className="form-control" name="apellidos" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.apellidos} />
     </div>
     <div class="col-5">
     <label>T.doc:</label>
     <br/>
     <select name="tdoc" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.tdoc}>
     <option value='no' selected>T.doc</option>
                <option value="Cedula">Cedula</option>
                <option value="DNI">DNI</option>
                <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                    
                  </select>  
                  <br />
     </div>
     <div class="col-6">
     <label>Documento:</label>
     <br/>
     <input type="number" className="form-control" name="documento" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.documento} />
     </div>


     <div class="col-sm-5">
     <label>Adjuntar:</label>
                   
                    <div class="input-group">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                        <label class="custom-file-label" for="exampleInputFile">Adjuntar</label>
                      </div>
                      
                    </div>

                   
                    </div>
<div class="col-7">
     <label>Fecha de nacimiento:</label>
     <br/>
     <input type="date" className="form-control" name="fechanacimiento" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.fechanacimiento} />
     <br />
     </div>
    

     < div class="col-sm-4">
                   <label>Genero</label>
                   <select name="genero" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.genero}>
               <option value='no' selected>Seleccionar</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="Sin definir">Sin definir</option>
               
             </select>     
<br />
                    </div>

                    <div class="col-8">
     <label>Nacionalidad:</label>
     <br/>
     <input type="text" className="form-control" name="nacionalidad" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nacionalidad} />
     </div>

     <div class="col-7">
     <label>Direccion:</label>
     <br/>
     <input type="text" className="form-control" name="direccion" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.direccion} />
     </div>

     <div class="col-4">
     <label>Ciudad:</label>
     <br/>
     <input type="text" className="form-control" name="ciudad_nacionalidad" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.ciudad_nacionalidad} />
     <br/>
     </div>

     <div class="col-4">
     <label>N° celular:</label>
     <br/>
     <input type="text" className="form-control" name="telefono" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.telefono} />
     <br/>
     </div>

     <div class="col-4">
     <label>Comercio:</label>
     <br/>
     <input type="text" className="form-control" name="comercio_natural" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.comercio_natural} />
     <br/>
     </div>

     <div class="col-4">
     <label>Email:</label>
     <br/>
     <input type="text" className="form-control" name="email" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email} />
     
     </div>

     
     
    
     
   

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
 </ModalFooter>
</Modal>

<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que deseas eliminar la empresa de {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>

            </div>
       
          </div>

         
        </div>
      

        

      </div>
    </section>

    
    
  </div>
    )
}

export default Ver_deportistas