import React, {useContext} from 'react'

import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

 function Empresajuridica2(props) {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
        <div className="content-wrapper">
    
        <div className="content-header">
          <div className="container-fluid">
            
              <div className="col-sm-12">
    
    
    
              <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">PERSONA JURIDICA</h3>
                  </div>
                
                  <div class="card-body">
                    <form>
                      <div class="row">
                      <label>Datos De la Persona De Contacto, Administrador de la APP.</label>
    
                      
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
    
                       <input name="email" type="text" class="form-control is-valid" id="inputSuccess" placeholder="Nombres" value={props.getState('email', '')} onChange={props.handleChange} />
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-3">
    
                        <div class="form-group">

                        <input name="phone" type="text" class="form-control is-valid" id="inputSuccess" placeholder="Apellidos" value={props.getState('phone', '')} onChange={props.handleChange} />
                        
                      
                      </div>
                      </div>

                      
                


                        <div class="col-sm-3">
                       
                        <input type="date" name="fechanac" class="form-control" id="inputSuccess" value={props.getState('fechanac', '')} onChange={props.handleChange}/> <h6>Fecha de nacimiento</h6>
    
                        </div>
                        </div>
    
    
                        <div class="row">
                        
                        <label>Datos de Contacto</label>
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
                        <input type="number" name="celularc" class="form-control is-valid" id="inputSuccess" placeholder="N° celular" value={props.getState('celularc', '')} onChange={props.handleChange} />
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-4">
    
                        <div class="form-group">
                        
                        <input type="email" name="email" class="form-control" id="inputSuccess" placeholder="Email" value={props.getState('email', '')} onChange={props.handleChange} />
                      </div>
                      </div>

                      
                      
                        
    
                       
                      </div>
    
                      
    
    
                        <div class="row">

                            <label>N° Celular – Email Asociados a la Cuenta PLOOY</label>

                            <div class="col-sm-5">
                  
                        <div class="form-group">
    
                       <p>Datos Únicos Asociados a  tu Cuenta, Autorizados para Validar y Recuperar contraseñas PLOOY.</p>
                          </div>
                        </div>

    
                        <div class="col-sm-1">
                  
                        <div class="form-group">
    
                        <input type="number" name="indic" class="form-control" id="inputSuccess" placeholder="+57"  value={props.getState('indic', '')} onChange={props.handleChange}/>
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="number" name="celulard" class="form-control" id="inputSuccess" placeholder="N° celular" value={props.getState('celulard', '')} onChange={props.handleChange} />
                          </div>
                        </div>

                        <div class="col-sm-4">
                  
                        <div class="form-group">
    
                        <input type="email" name="emaile" class="form-control" id="inputSuccess" placeholder="Email" value={props.getState('emaile', '')} onChange={props.handleChange} />
                          </div>
                        </div>
    
    
                
    
    
                        </div>
    
    
                        <div class="row">
                     
    
                        <div class="col-sm-4">
                  
                        <div class="form-group">
                        <p><b>Crear Contraseña</b> Mínimo 6 Caracteres, 1 Letra Mayúscula, 1 Numero</p>
                        
                          </div>
                        </div>
    
    
                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <input type="password" name="password" class="form-control is-valid" id="inputSuccess" placeholder="Contraseña" value={props.getState('password', '')} onChange={props.handleChange} />
                    </div>
                  </div>

                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <input type="password" name="contrasenan" class="form-control is-valid" id="inputSuccess" placeholder="Repetir contraseña" value={props.getState('contrasenan', '')} onChange={props.handleChange} />
                    </div>
                  </div>
    
    
                        </div>
    
    
                        
    
    
    
                  
    
    
                
    
    
                     
                        <button className="btn btn-primary" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                        <button className="btn btn-warning"  onClick={props.next}> Siguiente <i class="fas fa-arrow-right"></i></button>
                        <br /><br />
                      
                        
    
                      
    
                      
    
    
                    </form>
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
          </div>
          </div>
          </div>


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
}
 }

export default Empresajuridica2








   /*<div>


         <label>FORMULARIO2</label>
      <p><input name="email" value={props.getState('email', '')} onChange={props.handleChange} /></p>
     <p><input name="phone" value={props.getState('phone', '')} onChange={props.handleChange} /></p>
     <button onClick={props.prev}>Previous</button>
      <button onClick={props.next}>Next</button>
        </div>*/