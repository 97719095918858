import React, {useContext,useState,useEffect} from 'react';
import {MyContext} from '../../contexts/MyContext';

//cargar mostrar
import axios from 'axios';

//logo torneo

import LogoTorneo from '../../img/torneos.png'
import Activos from '../../img/finalizados.png'

//Acordion
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Importing the Login & Register Componet
import Register  from './Register'

import { Steps, Step } from "react-step-builder";
import Torneo_finalizado1 from "./Torneo_finalizado1";
import Torneo_finalizado3 from "./Torneo_finalizado3";
import Torneo_activo2 from "./Torneo_activo2";
import Torneo_activo5 from "./Torneo_activo5";
/*import Torneo_activo6 from "./Torneo_activo6";*/
import Torneo_activo8 from "./Torneo_activo8";





//Acordion
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));


  


function Torneos_finalizados() {

  //cargar mostrar

  const [color, setName] = useState([]);

  const peticionGet =async() =>{
    await axios.get(`https://www.deportes.controlsas.com/apiPlooy/Solicitar_empresa/`)
    .then((res) => {
       setName(res.data.color);
    }).catch(error=>{
      console.log(error);
    })
  
  }



useEffect(()=>{
    

   const interval = setInterval(() => {
     
      peticionGet();
      
    }, 3000);

  return () => setInterval(interval);

  },[])






 // agregar acordeon
    const classes = useStyles();

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {

        
    return (
      
        <div className="content-wrapper">
 
        <div className="content-header">
          <div className="container-fluid">
          
        <div className={classes.root}>
        
        <Accordion expanded={true}>
        
          
         
          
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} ><img src={LogoTorneo} width="50px" /><b>Torneos</b> <img src={Activos} width="50px" /><b>Finalizados</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.root}>
            <Steps>
            
           {/* <Step component={Pruebas} />*/}
        <Step component={Torneo_finalizado1} />
        <Step component={Torneo_finalizado3} />
        <Step component={Torneo_activo2} />
        <Step component={Torneo_activo5} />
       {/* <Step component={Torneo_activo6} />  */}
        <Step component={Torneo_activo8} />
        
      </Steps>
              
            </Typography>
          </AccordionDetails>
        </Accordion>
       


        
      </div> 

      </div>
      </div>
      </div>
        
                 

    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Torneos_finalizados
