import React, {useContext,useState} from 'react';

import {MyContext} from '../../contexts/MyContext';

// Importing the Login & Register Componet
import Register from './Register';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

 function Jugadores_autorizados(props) {

 //Modal puntos ganador
const [modalPuntosGanador, setModalPuntosGanador]=useState(false);

//Modal puntos ganador

const abrirCerrarModalPuntosGanador=()=>{
      setModalPuntosGanador(!modalPuntosGanador);
}


 //Modal empate juego
 const [modalEmpateJuego, setModalEmpateJuego]=useState(false);

 //Modal empate juego
 
 const abrirCerrarModalEmpateJuego=()=>{
       setModalEmpateJuego(!modalEmpateJuego);
 }


 //Modal perdedor juego
 const [modalPerdedorJuego, setModalPerdedorJuego]=useState(false);

 //Modal perdedor juego
 
 const abrirCerrarModalPerdedorJuego=()=>{
      setModalPerdedorJuego(!modalPerdedorJuego);
}


//Modal ganador juego w
const [modalGanadorJuegoW, setModalGanadorJuegoW]=useState(false);

//Modal ganador juego w

const abrirCerrarModalGanadorJuegoW=()=>{
     setModalGanadorJuegoW(!modalGanadorJuegoW);
}


//Modal goles juego w
const [modalGolesJuegoW, setModalGolesJuegoW]=useState(false);

//Modal goles juego w

const abrirCerrarModalGolesJuegoW=()=>{
     setModalGolesJuegoW(!modalGolesJuegoW);
}


//Modal contra juego w
const [modalContraJuegoW, setModalContraJuegoW]=useState(false);

//Modal contra juego w

const abrirCerrarModalContraJuegoW=()=>{
     setModalContraJuegoW(!modalContraJuegoW);
}


//Modal contra juego w2
const [modalContraJuegoW2, setModalContraJuegoW2]=useState(false);

//Modal contra juego w2

const abrirCerrarModalContraJuegoW2=()=>{
     setModalContraJuegoW2(!modalContraJuegoW2);
}
    

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;



    if(isAuth)
    {

    return (
        
        
        
            
              <div className="col-sm-12">
                  
    
              <div class="card card-primary">
                  
                  <div class="card-header">
                    <h3 class="card-title">JUGADORES AUTORIZADOS EN CAMPO</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    


      <div class="row">
        <center> <h6><b>Este modulo se refiere al limite Mínimo de Jugadores de Cada Equipo en Cancha Autorizados y Cuantos Cambios de Jugadores Puede Hacer Cada Equipo en Cada Juego.  

</b></h6> </center> 

<br/>
<br/>
<br/>
          <div class="col-sm-6">
                <label>Cuantos Jugadores Mínimo Autorizados en Cancha Por cada equipo para poder participar del Juego ?
</label>
                <div class="input-group">
                <select name="puntos_ganador"
       
         class="form-control" 
         name="puntos_ganador"
         value={props.getState('puntos_ganador', '')} 
         onChange={props.handleChange}
         >
<option value='no' selected>.</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      
    </select>  
      


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>
      

     


      <div class="col-sm-6">
      <label>Cuantos Cambio de Jugadores Puede Hacer Cada Equipo en el Juego.</label>
      <br />
      <br />
      <div class="input-group">
      <select name="puntos_empate"
       
       class="form-control" 
       name="puntos_empate"
       value={props.getState('puntos_empate', '')} 
       onChange={props.handleChange}
       >
<option value='no' selected>.</option>
    <option value="LIBRE">LIBRE(Sin restricción en el n° cambios)</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    <option value="10">10</option>
    <option value="11">11</option>
    
  </select>  


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

      </div>




    

      
    </div>
   
    

   


      

      <br />
      <div class="row">

     


       <div class="col-sm-9">
     
       Si Un Equipo Se presenta al Inicio del juego o durante el Mismo Juego El Equipo que Queda con <b>(  ) </b> o Menos Jugadores, Este Equipo Perderá el Juego Por W


       </div>





      <div class="col-sm-3">
            <br />
            <br />
      <button className="btn btn-success rounded-circle float-right"   onClick={props.next}><i class="fas fa-save fa-2x"></i></button>
      

     

     
      
      
      
      </div>
      
     

       </div>

      
    







      

    


  </form>
                  </div>
                 
                </div>
    
    
{/* puntos ganador*/}
<Modal isOpen={modalPuntosGanador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Ganador Del Juego.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPuntosGanador()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* empate juego*/}
<Modal isOpen={modalEmpateJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos Por Empate De Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Cada Equipo por Empate Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEmpateJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* perdedor juego*/}
<Modal isOpen={modalPerdedorJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Perdedor Del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Perdedor Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPerdedorJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* ganador juego W*/}
<Modal isOpen={modalGanadorJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados A Favor Al Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGanadorJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* goles juego W*/}
<Modal isOpen={modalGolesJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles a Favor al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Otorgados A Favor Del Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGolesJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* contra juego W*/}
<Modal isOpen={modalContraJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos En Contra Por Perder el Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* contra juego W2*/}
<Modal isOpen={modalContraJuegoW2}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles En Contra al Perdedor del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW2()}>Aceptar</button>
 </ModalFooter>
</Modal>
    
          </div>
    
 


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
        
}
 }

export default Jugadores_autorizados