import React, {useEffect, useContext, useState} from 'react'
import {MyContext} from '../../../contexts/MyContext';
import "react-datepicker/dist/react-datepicker.css";

import LogoConfenalco from '../../../img/confenalco.jpg';
import LogoBalon from '../../../img/balon.png';
import LogoTorneo1 from '../../../img/activos.png';
import Reglamento from '../../../img/reglamento_torneo_verde.png'
import Programacion from '../../../img/programacion_verde.png'
import Grupos from '../../../img/grupos.png'
import Programar_juegos from '../../../img/programar_juegos_verde.png'
import Auditar_torneo from '../../../img/auditar_torneo.png'
import Tabla_posiciones from '../../../img/tabla_posiciones.png'
import LogoTorneo from '../../../img/torneos.png'
import Editar1 from '../../../img/editar1.png';
import Asistencia_al_juego from '../../../img/asistencia_al_juego.png';
import Cambio_para_equipo from '../../../img/cambio_para_equipo.png';
import Cambio_para_equipo_ok from '../../../img/cambio_para_equipo_ok.png';
import Quien_marco_gol from '../../../img/quien_marco_gol.png';
import Quien_marco_gol_ok from '../../../img/quien_marco_gol_ok.png';
import Quien_recibio_tarjeta from '../../../img/quien_recibio_tarjeta.png';

import Gol_boton from '../../../img/gol_boton.png';
import Autogol_boton from '../../../img/autogol_boton.png';

import Marca_gol from '../../../img/marca_gol.png';
import Como_fue_el_gol from '../../../img/como_fue_el_gol.png';


import Gol_equipo from '../../../img/gol_equipo.png';

import Editar13_b from '../../../img/editar13_b.png';
import Editar15 from '../../../img/editar15.png';
import Editar16 from '../../../img/editar16.png';
import Editar17 from '../../../img/editar17.png';
import Editar18 from '../../../img/editar18.png';
import Editar19 from '../../../img/editar19.png';
import Editar20 from '../../../img/editar20.png';

import Editar7_si from '../../../img/editar7_si.png';
import Editar7_messi_si from '../../../img/editar7_messi_si.png';
//jugadores
import Editar7 from '../../../img/editar7.png';
import Editar7_messi from '../../../img/editar7_messi.png';
import Editar7_mina from '../../../img/editar7_mina.png';
import Editar7_davison from '../../../img/editar7_davison.png';
import Editar7_murillo from '../../../img/editar7_murillo.png';
import Editar7_johan from '../../../img/editar7_johan.png';
import Editar7_ospina from '../../../img/editar7_ospina.png';
import Editar7_alexander from '../../../img/editar7_alexander.png';
import Editar7_dani from '../../../img/editar7_dani.png';
import Editar7_thiago from '../../../img/editar7_thiago.png';
import Editar7_james from '../../../img/editar7_james.png';
import Editar7_neymar from '../../../img/editar7_neymar.png';
import Editar7_barrios from '../../../img/editar7_barrios.png';
import Editar7_cardona from '../../../img/editar7_cardona.png';
import Editar7_juanfer from '../../../img/editar7_juanfer.png';
import Editar7_luis from '../../../img/editar7_luis.png';
import Editar7_carrascal from '../../../img/editar7_carrascal.png';
import Editar7_chara from '../../../img/editar7_chara.png';
import Editar7_gio from '../../../img/editar7_gio.png';
import Editar7_teo from '../../../img/editar7_teo.png';
import Editar7_ibarguen from '../../../img/editar7_ibarguen.png';
import Editar7_embape from '../../../img/editar7_embape.png';

import Equipo1 from '../../../img/equipo1.png';
import Equipo2 from '../../../img/equipo2.png';

import Botonequipo1 from '../../../img/botonequipo1.png';
import Botonequipo2 from '../../../img/botonequipo2.png';

import Cambio2_imagen from '../../../img/cambio2.png';
import Cambio3 from '../../../img/cambio3.png';

import Editar9 from '../../../img/editar9.png';
import Editar10 from '../../../img/editar10.png';

import Ingresa from '../../../img/ingresa.png';
import Entra2 from '../../../img/entra2.png';
import Sale from '../../../img/sale.png';
import Sale2 from '../../../img/sale2.png';

import Register from '../Register'

function useToggles(init = {}) {
    const [states, setStates] = React.useState(init)
    return [
      states,
      key => event => setStates({...states, [key]: !Boolean(states[key])})
    ]
  }

  //dos
  function useToggles2(init = {}) {
    const [states2, setStates2] = React.useState(init)
    return [
      states2,
      key => event => setStates2({...states2, [key]: !Boolean(states2[key])})
    ]
  }

  //tres
  function useToggles3(init = {}) {
    const [states3, setStates3] = React.useState(init)
    return [
      states3,
      key => event => setStates3({...states3, [key]: !Boolean(states3[key])})
    ]
  }

   //cuatro
   function useToggles4(init = {}) {
    const [states4, setStates4] = React.useState(init)
    return [
      states4,
      key => event => setStates4({...states4, [key]: !Boolean(states4[key])})
    ]
  }

function Auditar(props) {

  const [asistencia, setAsistencia] = useState("no");
  const [menuasistencia, setMenuAsistencia] = useState("si");
  const [cambio, setCambio] = useState("");
  const [cambio2, setCambio2] = useState("");
  const [cambio3, setCambio3] = useState("");
  const [cambio_parte2, setCambioParte2] = useState("");
  const [menucambio, setMenuCambio] = useState("");
  const [menugol, setMenuGol] = useState("");
  const [cambio_equipo1, setCambioEquipo1] = useState("");
  const [cambio_equipo2, setCambioEquipo2] = useState("");
  const [gol0, setGol0] = useState("");
  const [gol1, setGol1] = useState("");
  const [golvolver, setGolVolver] = useState("");
  const [golvolver2, setGolVolver2] = useState("");
  const [golp1, setGolp1] = useState("");
  const [goles_equipo1, setGolesEquipo1] = useState("");
  const [gol_guardar_parte3, setGolGuardarParte3] = useState("");
  const [gol_guardar_parte4, setGolGuardarParte4] = useState("");
  const [gol_guardar_parte5, setGolGuardarParte5] = useState("");
 

 

  const asistenciajuegoclick_si = (e) => {
    setAsistencia(e.target.value="si");
    setMenuAsistencia(e.target.value="si");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="no");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const asistenciajuegoclick_no = (e) => {
    setAsistencia(e.target.value="no")
  }

  const cambioclick_si = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="si");
    setMenuCambio(e.target.value="si")
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const cambioclick_no = (e) => {
    setAsistencia(e.target.value="si");
    setMenuAsistencia(e.target.value="si");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const cambioclick_uno = (e) => {
    setCambio(e.target.value="no");
    setCambioEquipo1(e.target.value="si");
    setCambioEquipo2(e.target.value="no");
    setCambio2(e.target.value="si");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const cambioclick_dos = (e) => {
    setCambio(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="si");
    setCambio2(e.target.value="si");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }


  const cambioclick_si_dos = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="si");
    setMenuCambio(e.target.value="si");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const cambioclick_si_tres = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="si");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="si");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");
    setCambioParte2(e.target.value="si");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const cambioclick_si_button = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="si");
    setMenuCambio(e.target.value="si");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");
    setMenuGol(e.target.value="no");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const golclick_si = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="no");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");

    setMenuGol(e.target.value="si");
    setGol0(e.target.value="si");
    setGol1(e.target.value="si");
    setGolVolver(e.target.value="si");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
    
  }

 
  const golclick_si_button = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="no");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");

    setMenuGol(e.target.value="si");
    setGol0(e.target.value="si");
    setGol1(e.target.value="si");
    setGolVolver(e.target.value="si");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
    
  }

  const gol_uno = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="no");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");

    setMenuGol(e.target.value="si");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="si");
    setGolVolver2(e.target.value="si");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");
  }

  const Golesequipo_uno = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="no");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");

    setMenuGol(e.target.value="si");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="si");
    setGolGuardarParte3(e.target.value="si");
    setGolGuardarParte4(e.target.value="no");
    setGolGuardarParte5(e.target.value="no");

  }
  
  const Golparte4_si = (e) => {
    setAsistencia(e.target.value="no");
    setMenuAsistencia(e.target.value="no");
    setCambio(e.target.value="no");
    setMenuCambio(e.target.value="no");
    setCambio2(e.target.value="no");
    setCambio3(e.target.value="no");
    setCambioParte2(e.target.value="no");
    setCambioEquipo1(e.target.value="no");
    setCambioEquipo2(e.target.value="no");

    setMenuGol(e.target.value="si");
    setGol0(e.target.value="no");
    setGol1(e.target.value="no");
    setGolVolver(e.target.value="no");
    setGolp1(e.target.value="no");
    setGolVolver2(e.target.value="no");
    setGolesEquipo1(e.target.value="no");
    setGolGuardarParte3(e.target.value="no");
    setGolGuardarParte4(e.target.value="si");
    setGolGuardarParte5(e.target.value="si");


    
  }
  


  //checkbox1
  const [checkboxes, toggle] = useToggles({
    uno_uno: false,
    uno_dos: false,
    uno_tres: false,
    uno_cuatro: false,
    uno_cinco: false,
    uno_seis: false,
    uno_siete: false,
    uno_ocho: false,
    uno_nueve: false,
    uno_diez : false,
    uno_once : false,

    dos_uno: false,
    dos_dos: false,
    dos_tres: false,
    dos_cuatro: false,
    dos_cinco: false,
    dos_seis: false,
    dos_siete: false,
    dos_ocho: false,
    dos_nueve: false,
    dos_diez : false,
    dos_once : false,
  })

  const [checkboxes2, toggle2] = useToggles2({
    uno_uno_mostrar: false,
    uno_dos_mostrar: false,
    uno_tres_mostrar: false,
    uno_cuatro_mostrar: false,
    uno_cinco_mostrar: false,
    uno_seis_mostrar: false,
    uno_siete_mostrar: false,
    uno_ocho_mostrar: false,
    uno_nueve_mostrar: false,
    uno_diez_mostrar: false,
    uno_once_mostrar: false,

   dos_uno_mostrar: false,
   dos_dos_mostrar: false,
   dos_tres_mostrar: false,
   dos_cuatro_mostrar: false,
   dos_cinco_mostrar: false,
   dos_seis_mostrar: false,
   dos_siete_mostrar: false,
   dos_ocho_mostrar: false,
   dos_nueve_mostrar: false,
   dos_diez_mostrar: false,
   dos_once_mostrar: false
  })

  const [checkboxes3, toggle3] = useToggles3({
    tres_uno_mostrar: false,
    tres_dos_mostrar: false,
    tres_tres_mostrar: false,
    tres_cuatro_mostrar: false,
    tres_cinco_mostrar: false,
    tres_seis_mostrar: false,
    tres_siete_mostrar: false,
    tres_ocho_mostrar: false,
    tres_nueve_mostrar: false,
    tres_diez_mostrar: false,
    tres_once_mostrar: false,

   cuatro_uno_mostrar: false,
   cuatro_dos_mostrar: false,
   cuatro_tres_mostrar: false,
   cuatro_cuatro_mostrar: false,
   cuatro_cinco_mostrar: false,
   cuatro_seis_mostrar: false,
   cuatro_siete_mostrar: false,
   cuatro_ocho_mostrar: false,
   cuatro_nueve_mostrar: false,
   cuatro_diez_mostrar: false,
   cuatro_once_mostrar: false
  })


  const [checkboxes4, toggle4] = useToggles4({
    cinco_uno_mostrar: false,
    cinco_dos_mostrar: false,
    cinco_tres_mostrar: false,
    cinco_cuatro_mostrar: false,
    cinco_cinco_mostrar: false,
    cinco_seis_mostrar: false,
    cinco_siete_mostrar: false,
    cinco_ocho_mostrar: false,
    cinco_nueve_mostrar: false,
    cinco_diez_mostrar: false,
    cinco_once_mostrar: false,
    cinco_doce_mostrar: false,
    cinco_trece_mostrar: false,
    cinco_catorce_mostrar: false,
    cinco_quince_mostrar: false,
    cinco_diesiseis_mostrar: false,
    cinco_diesisite_mostrar: false,
    cinco_diesiocho_mostrar: false,
    cinco_diesinueve_mostrar: false,
    cinco_veinte_mostrar: false,
    cinco_veinteuno_mostrar: false,
    cinco_veintedos_mostrar: false,
    cinco_veintetres_mostrar: false,
    cinco_veintecuatro_mostrar: false,
    cinco_veintecinco_mostrar: false,
    cinco_veinteseis_mostrar: false,
    cinco_veintesiete_mostrar: false,
    cinco_veinteocho_mostrar: false,
    cinco_veintenueve_mostrar: false,
    cinco_treinta_mostrar: false,
    cinco_treintauno_mostrar: false,
    cinco_treintados_mostrar: false,
    cinco_treintatres_mostrar: false,
    cinco_treintacuatro_mostrar: false,
    cinco_treintacinco_mostrar: false,
    cinco_treintaseis_mostrar: false,
    cinco_trintasiete_mostrar: false,
    cinco_treintaocho_mostrar: false,
    cinco_treintanueve_mostrar: false,
    cinco_cuarenta_mostrar: false,
    cinco_cuarentauno_mostrar: false,
    cinco_cuarentados_mostrar: false,
    cinco_cuarentatres_mostrar: false,
    cinco_cuarentacinco_mostrar: false,
    cinco_cuarentaseis_mostrar: false,
    cinco_cuarentasiete_mostrar: false,
    cinco_cuarentaocho_mostrar: false,
    cinco_cuarentanueve_mostrar: false,
    cinco_cincuenta_mostrar: false,
    cinco_cincuentauno_mostrar: false,
    cinco_cincuentados_mostrar: false,
    cinco_cincuentatres_mostrar: false,
    cinco_cincuentacuatro_mostrar: false,
    cinco_cincuentacinco_mostrar: false,
    cinco_cincuentaseis_mostrar: false,
    cinco_cincuentasiete_mostrar: false,
    cinco_cincuentaocho_mostrar: false,
    cinco_cincuentanueve_mostrar: false,
    cinco_sesenta_mostrar: false,
    cinco_sesentauno_mostrar: false,
    cinco_sesentados_mostrar: false,
    cinco_sesentres_mostrar: false,
    cinco_sesentacuatro_mostrar: false,
    cinco_sesentacinco_mostrar: false,
    cinco_sesentaseis_mostrar: false,
    cinco_sesentasiete_mostrar: false,
    cinco_sesentaocho_mostrar: false,
    cinco_sesentanueve_mostrar: false,
    cinco_setenta_mostrar: false,
    cinco_setentauno_mostrar: false,
    cinco_setentados_mostrar: false,
    cinco_setentatres_mostrar: false,
    cinco_setentacuatro_mostrar: false,
    cinco_setentacinco_mostrar: false,
    cinco_setentaseis_mostrar: false,
    cinco_setentasiete_mostrar: false,
    cinco_setentaocho_mostrar: false,
    cinco_setentanueve_mostrar: false,
    

   seis_uno_mostrar: false,
   seis_dos_mostrar: false,
   seis_tres_mostrar: false,
   seis_cuatro_mostrar: false,
   seis_cinco_mostrar: false,
   seis_seis_mostrar: false,
   seis_siete_mostrar: false,
   seis_ocho_mostrar: false,
   seis_nueve_mostrar: false,
   seis_diez_mostrar: false,
   seis_once_mostrar: false,
   seis_doce_mostrar: false,
   seis_trece_mostrar: false,
   seis_catorce_mostrar: false,
   seis_quince_mostrar: false,
   seis_diesiseis_mostrar: false,
   seis_diesisiete_mostrar: false,
   seis_diesiocho_mostrar: false,
   seis_diesinueve_mostrar: false,
   seis_veinte_mostrar: false,
   seis_veinteuno_mostrar: false,
   seis_veintedos_mostrar: false,
   seis_veintetres_mostrar: false,
   seis_veintecuatro_mostrar: false,
   seis_veintecinco_mostrar: false,
   seis_veinteseis_mostrar: false,
   seis_ventesiete_mostrar: false,
   seis_veinteocho_mostrar: false,
   seis_veintenueve_mostrar: false,
   seis_treinta_mostrar: false,
   seis_treintauno_mostrar: false,
   seis_treintados_mostrar: false,
   seis_treintatres_mostrar: false,
   seis_treintacuatro_mostrar: false,
   seis_treintacinco_mostrar: false,
   seis_treintaseis_mostrar: false,
   seis_treintasiete_mostrar: false,
   seis_treintaocho_mostrar: false,
   seis_treintanueve_mostrar: false,
   seis_cuarenta_mostrar: false,
   seis_cuarentauno_mostrar: false,
   seis_cuarentados_mostrar: false,
   seis_cuarentatres_mostrar: false,
   seis_cuarentacuatro_mostrar: false,
   seis_cuarentacinco_mostrar: false,
   seis_cuarentaseis_mostrar: false,
   seis_cuarentasiete_mostrar: false,
   seis_cuarentaocho_mostrar: false,
   seis_cuarentanueve_mostrar: false,
   seis_cincuenta_mostrar: false,
   seis_cincuentauno_mostrar: false,
   seis_cincuentados_mostrar: false,
   seis_cincuentatres_mostrar: false,
   seis_cincuentacuatro_mostrar: false,
   seis_cincuentacinco_mostrar: false,
   seis_cincuentaseis_mostrar: false,
   seis_cincuentasiete_mostrar: false,
   seis_cincuentaocho_mostrar: false,
   seis_cincuentanueve_mostrar: false,
   seis_sesenta_mostrar: false,
   seis_sesentauno_mostrar: false,
   seis_sesentados_mostrar: false,
   seis_sesentatres_mostrar: false,
   seis_sesentacuatro_mostrar: false,
   seis_sesentacinco_mostrar: false,
   seis_sesentaseis_mostrar: false,
  })

  
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (       
              <div className="col-sm-12">
     
              <div class="card">

              <div>
              <h3 class="card-title" ><button style={{backgroundColor:"transparent", border:"none"}}><img src={Reglamento} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Programacion} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Grupos} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}}><img src={Programar_juegos} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Auditar_torneo} width="145px" /></button>
              <button style={{backgroundColor:"transparent", border:"none"}} ><img src={Tabla_posiciones} width="145px" /></button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <font size="1"><img src={LogoTorneo} width="20px" /> TORNEOS / &nbsp; 
                              <img src={LogoTorneo1} width="20px" /> ACTIVOS 
                             
                             </font> </h3>
              
              </div>
                  
                  <div class="card-header text-center">
                    <h3 class="card-title"><img src={LogoConfenalco} width="45px" />
                    <img src={LogoBalon} width="45px" /> <b>TORNEO CHENCHO 2022 </b> 
                     - Colombia – Tolima – Ibagué – Futbol - F11 – G Natural – Masculino – Dici 2021</h3>
                    <br /><h3 class="card-title"> 
                    </h3>
                  </div>

                   
                  <div class="card-body">
                  <br />      
                  
                  
                  <div className="col-sm-12">
        {menuasistencia === "si" && (
            <div className='row'>
                  <div class="col-sm-12">

                  <center> <img src={Editar1} width="445px" /> </center>

                 </div>

                 <div class="col-sm-12">

              
               <center> <button style={{backgroundColor:"transparent", border:"none"}}
                        onClick={asistenciajuegoclick_si} >
                        <img src={Asistencia_al_juego} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none", opacity: "0.5"}} disabled>
                        <img src={Cambio_para_equipo} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none", opacity: "0.5"}} disabled>
                        <img src={Quien_marco_gol} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none", opacity: "0.5"}} disabled>
                        <img src={Quien_recibio_tarjeta} width="220px"  /> </button>
                        </center>

                <hr />
                </div>
                </div>
                )}
                </div>
               

                <div className="col-sm-12">
        {asistencia === "si" && (
            
<div class="row">
    

<div class="col-sm-6 card">

<center><img src={Equipo1} width="150px"  /></center>
<br/>

<center>  <img src={Editar7} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_1"
checked={checkboxes.uno_uno} onClick={toggle("uno_uno")} /></div></center>

<center>  <img src={Editar7_mina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_2"
checked={checkboxes.uno_dos} onClick={toggle("uno_dos")}/></div></center>

<center>  <img src={Editar7_murillo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_3"
checked={checkboxes.uno_tres} onClick={toggle("uno_tres")}
 /></div></center>


<center>  <img src={Editar7_ospina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_4"
checked={checkboxes.uno_cuatro} onClick={toggle("uno_cuatro")}
/></div></center>

<center>  <img src={Editar7_dani} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_5"
checked={checkboxes.uno_cinco} onClick={toggle("uno_cinco")}
/></div></center>

<center>  <img src={Editar7_james} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_6"
checked={checkboxes.uno_seis} onClick={toggle("uno_seis")}
/></div></center>

<center>  <img src={Editar7_barrios} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_7"
checked={checkboxes.uno_siete} onClick={toggle("uno_siete")}
/></div></center>

<center>  <img src={Editar7_juanfer} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_8"
checked={checkboxes.uno_ocho} onClick={toggle("uno_ocho")}
/></div></center>

<center>  <img src={Editar7_carrascal} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_9"
checked={checkboxes.uno_nueve} onClick={toggle("uno_nueve")}
/></div></center>

<center>  <img src={Editar7_gio} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_10"
checked={checkboxes.uno_diez} onClick={toggle("uno_diez")} /></div></center>

<center>  <img src={Editar7_ibarguen} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check_11"
checked={checkboxes.uno_once} onClick={toggle("uno_once")}
/></div></center>


</div>



{/* Fila dos */}

<div class="col-sm-6 card ">
<center><img src={Equipo2} width="150px"  /></center>
<br/>

<center>  <img src={Editar7_messi} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}} 
name="check2_1"
checked={checkboxes.dos_uno} onClick={toggle("dos_uno")}/>
</div></center>

<center>  <img src={Editar7_davison} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}} 
name="check2_2"
checked={checkboxes.dos_dos} onClick={toggle("dos_dos")} />
</div></center>

<center>  <img src={Editar7_johan} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}} name="check2_3"
checked={checkboxes.dos_tres} onClick={toggle("dos_tres")} />
</div></center>


<center>  <img src={Editar7_alexander} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check2_4"
checked={checkboxes.dos_cuatro} onClick={toggle("dos_cuatro")}
/>
</div></center>

<center>  <img src={Editar7_thiago} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check2_5"
checked={checkboxes.dos_cinco} onClick={toggle("dos_cinco")}
/></div></center>

<center>  <img src={Editar7_neymar} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}} 
name="check2_6"
checked={checkboxes.dos_seis} onClick={toggle("dos_seis")}
/></div></center>

<center>  <img src={Editar7_cardona} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check2_7"
checked={checkboxes.dos_siete} onClick={toggle("dos_siete")}
/></div></center>

<center>  <img src={Editar7_luis} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}} 
name="check2_8"
checked={checkboxes.dos_ocho} onClick={toggle("dos_ocho")}
/></div></center>

<center>  <img src={Editar7_chara} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}} 
name="check2_9"
checked={checkboxes.dos_nueve} onClick={toggle("dos_nueve")}
/></div></center>

<center>  <img src={Editar7_teo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check2_10"
checked={checkboxes.dos_diez} onClick={toggle("dos_diez")}
/></div></center>

<center>  <img src={Editar7_embape} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em"
}}
name="check2_11"
checked={checkboxes.dos_once} onClick={toggle("dos_once")}
/></div></center>



</div>

</div>

        )}
      </div>
                
      <div className="col-sm-12">
        {asistencia === "si" && (
            <>
                <button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                <button className="btn btn-success float-right" onClick={cambioclick_si}>Guardar <i class="fas fa-arrow-right"></i></button>&nbsp;
                </> )}
                </div>


{/* cambio para el equipo */}


<div className="col-sm-12">
        {menucambio === "si" && (
            <div className='row'>
                  <div class="col-sm-12">

                  <center> <img src={Editar1} width="445px" /> </center>

                 </div>

                 <div class="col-sm-12">

              
               <center> <button style={{backgroundColor:"transparent", border:"none"}}
                        onClick={asistenciajuegoclick_si} >
                        <img src={Asistencia_al_juego} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none"}}
                        onClick={cambioclick_si_button}k_si>
                        <img src={Cambio_para_equipo_ok} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none", opacity: "0.5"}} disabled>
                        <img src={Quien_marco_gol} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none", opacity: "0.5"}} disabled>
                        <img src={Quien_recibio_tarjeta} width="220px"  /> </button>
                        </center>

                <hr />
                </div>
                </div>
                )}
                </div>
                <div className="col-sm-12">
              {cambio === "si" && (
           <center> <button style={{backgroundColor:"transparent", border:"none"}}>
            <img src={Botonequipo1} width="220px"
            onClick={cambioclick_uno}  /> </button>

            <button style={{backgroundColor:"transparent", border:"none"}}>
            <img src={Botonequipo2} width="220px" 
            onClick={cambioclick_dos} /> </button>
            </center>
                 )}
                </div>


                <div className="col-sm-12">
        {cambio === "si" && (
            <>
                <button className="btn btn-warning" onClick={cambioclick_no}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                </> )}
                </div>


                <div className="col-sm-12">
        {cambio_equipo1 === "si" && (
          <>


    
          <div className="row">

           <div class="col-sm-5">
           
           <img src={Cambio2_imagen} width="480px" /> 
           </div>
           
           <div class="col-sm-1">
           
           <h6><b>MIN</b></h6>
           <input type="number"  class="form-control"></input>
           
           </div>
           
           
           <div class="col-sm-1">
           
           <h6><b>SEG</b></h6>
           <input type="number"  class="form-control"></input>
           
           </div>

           <div class="col-sm-2">
           
           <center><img src={Equipo1} width="150px"  /></center>

           
           </div>
           
           
           </div>

<div class="row">


    <div class="col-sm-6">
    <center> <img src={Sale} width="160px" /> </center>
    </div>

     <div class="col-sm-6">
    <center> <img src={Ingresa} width="180px" /> </center>
    </div>



</div>

<div class="row">



<div class="col-sm-6 card">
<center><h6><b>TITULARES</b></h6></center>
<br/>
{checkboxes.uno_uno == true ?
<center>  <img src={Editar7_si} width="380px" /> 
<div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_1_mostrar"
checked={checkboxes2.uno_uno_mostrar} onClick={toggle2("uno_uno_mostrar")} /></div>
</center>
: ""}

{checkboxes.uno_dos == true ?
<center>  <img src={Editar7_mina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_mostrar"
checked={checkboxes2.uno_dos_mostrar} onClick={toggle2("uno_dos_mostrar")} /></div></center>
: ""}

{checkboxes.uno_tres == true ?
<center>  <img src={Editar7_murillo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_mostrar"
checked={checkboxes2.uno_tres_mostrar} onClick={toggle2("uno_tres_mostrar")} /></div></center>
: ""}

{checkboxes.uno_cuatro == true ?
<center>  <img src={Editar7_ospina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_4_mostrar"
checked={checkboxes2.uno_cuatro_mostrar} onClick={toggle2("uno_cuatro_mostrar")} /></div></center>
: ""}

{checkboxes.uno_cinco == true ?
<center>  <img src={Editar7_dani} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_5_mostrar"
checked={checkboxes2.uno_cinco_mostrar} onClick={toggle2("uno_cinco_mostrar")} /></div></center>
: ""}

{checkboxes.uno_seis == true ?
<center>  <img src={Editar7_james} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_6_mostrar"
checked={checkboxes2.uno_seis_mostrar} onClick={toggle2("uno_seis_mostrar")} /></div></center>
: ""}

{checkboxes.uno_siete == true ?
<center>  <img src={Editar7_barrios} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_7_mostrar"
checked={checkboxes2.uno_siete_mostrar} onClick={toggle2("uno_siete_mostrar")} /></div></center>
: ""}

{checkboxes.uno_ocho == true ?
<center>  <img src={Editar7_juanfer} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_8_mostrar"
checked={checkboxes2.uno_ocho_mostrar} onClick={toggle2("uno_ocho_mostrar")} /></div></center>
: ""}

{checkboxes.uno_nueve == true ?
<center>  <img src={Editar7_carrascal} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_9_mostrar"
checked={checkboxes2.uno_nueve_mostrar} onClick={toggle2("uno_nueve_mostrar")} /></div></center>
: ""}

{checkboxes.uno_diez == true ?
<center>  <img src={Editar7_gio} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_10_mostrar"
checked={checkboxes2.uno_diez_mostrar} onClick={toggle2("uno_diez_mostrar")} /></div></center>
: ""}

{checkboxes.uno_once == true ?
<center>  <img src={Editar7_ibarguen} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_11_mostrar"
checked={checkboxes2.uno_once_mostrar} onClick={toggle2("uno_once_mostrar")} /></div></center>
: ""}

</div>




    {/* Fila dos */}

    <div class="col-sm-6 card">
    <center><h6><b>SUPLENTES</b></h6></center>
<br/>
{checkboxes.uno_uno == false ?
<center>  <img src={Editar7_si} width="380px" /> 
<div>
<input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_1_mostrar"
checked={checkboxes2.uno_uno_mostrar} onClick={toggle2("uno_uno_mostrar")}
 /></div>
</center>
: ""}

{checkboxes.uno_dos == false ?
<center>  <img src={Editar7_mina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_2_mostrar"
checked={checkboxes2.uno_dos_mostrar} onClick={toggle2("uno_dos_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_tres == false ?
<center>  <img src={Editar7_murillo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_mostrar"
checked={checkboxes2.uno_tres_mostrar} onClick={toggle2("uno_tres_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_cuatro == false ?
<center>  <img src={Editar7_ospina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_4_mostrar"
checked={checkboxes2.uno_cuatro_mostrar} onClick={toggle2("uno_cuatro_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_cinco == false ?
<center>  <img src={Editar7_dani} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_5_mostrar"
checked={checkboxes2.uno_cinco_mostrar} onClick={toggle2("uno_cinco_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_seis == false ?
<center>  <img src={Editar7_james} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_6_mostrar"
checked={checkboxes2.uno_seis_mostrar} onClick={toggle2("uno_seis_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_siete == false ?
<center>  <img src={Editar7_barrios} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_7_mostrar"
checked={checkboxes2.uno_siete_mostrar} onClick={toggle2("uno_siete_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_ocho == false ?
<center>  <img src={Editar7_juanfer} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_8_mostrar"
checked={checkboxes2.uno_ocho_mostrar} onClick={toggle2("uno_ocho_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_nueve == false ?
<center>  <img src={Editar7_carrascal} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_9_mostrar"
checked={checkboxes2.uno_nueve_mostrar} onClick={toggle2("uno_nueve_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_diez == false ?
<center>  <img src={Editar7_gio} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_10_mostrar"
checked={checkboxes2.uno_diez_mostrar} onClick={toggle2("uno_diez_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_once == false ?
<center>  <img src={Editar7_ibarguen} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_11_mostrar"
checked={checkboxes2.uno_once_mostrar} onClick={toggle2("uno_once_mostrar")}
/></div></center>
: ""}

</div>



</div>

</>
                 )}
                </div>


                <div className="col-sm-12">
        {cambio_equipo2 === "si" && (
            <>
            <div className="row">

<div class="col-sm-5">

<img src={Cambio2_imagen} width="480px" /> 
</div>

<div class="col-sm-1">

<h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-1">

<h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>

<div class="col-sm-2">

<center><img src={Equipo2} width="150px"  /></center>


</div>


</div>

<div class="row">


    <div class="col-sm-6">
    <center> <img src={Sale} width="160px" /> </center>
    </div>

     <div class="col-sm-6">
    <center> <img src={Ingresa} width="180px" /> </center>
    </div>



</div>

<div class="row">



<div class="col-sm-6 card">
<center><h6><b>TITULARES</b></h6></center>
<br/>


            {checkboxes.dos_uno == true ?
            <center>  <img src={Editar7_messi_si} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_1_mostrar"
            checked={checkboxes2.dos_uno_mostrar} onClick={toggle2("dos_uno_mostrar")} /></div></center>
            : ""}

            
           {checkboxes.dos_dos == true ?
            <center>  <img src={Editar7_davison} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_2_mostrar"
            checked={checkboxes2.dos_dos_mostrar} onClick={toggle2("dos_dos_mostrar")} /></div></center>
            : ""}
            
            {checkboxes.dos_tres == true ?
            <center>  <img src={Editar7_johan} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_3_mostrar"
            checked={checkboxes2.dos_tres_mostrar} onClick={toggle2("dos_tres_mostrar")} />
            </div></center>
            : ""}
            
            {checkboxes.dos_cuatro == true ?
            <center>  <img src={Editar7_alexander} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_4_mostrar"
            checked={checkboxes2.dos_cuatro_mostrar} onClick={toggle2("dos_cuatro_mostrar")} /></div></center>
             : ""}
            
            {checkboxes.dos_cinco == true ?
            <center>  <img src={Editar7_thiago} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_5_mostrar"
            checked={checkboxes2.dos_cinco_mostrar} onClick={toggle2("dos_cinco_mostrar")} /></div></center>
            : ""}
            
            {checkboxes.dos_seis == true ?
            <center>  <img src={Editar7_neymar} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_6_mostrar"
            checked={checkboxes2.dos_seis_mostrar} onClick={toggle2("dos_seis_mostrar")} /></div></center>
            : ""}
            
            {checkboxes.dos_siete == true ?
            <center>  <img src={Editar7_cardona} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_7_mostrar"
            checked={checkboxes2.dos_siete_mostrar} onClick={toggle2("dos_siete_mostrar")} /></div></center>
            : ""}
            
            {checkboxes.dos_ocho == true ?
            <center>  <img src={Editar7_luis} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_8_mostrar"
            checked={checkboxes2.dos_ocho_mostrar} onClick={toggle2("dos_ocho_mostrar")} /></div></center>
             : ""}

            {checkboxes.dos_nueve == true ?
            <center>  <img src={Editar7_chara} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_9_mostrar"
            checked={checkboxes2.dos_nueve_mostrar} onClick={toggle2("dos_nueve_mostrar")} /></div></center>
            : ""}
            
            {checkboxes.dos_diez == true ?
            <center>  <img src={Editar7_teo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_10_mostrar"
            checked={checkboxes2.dos_diez_mostrar} onClick={toggle2("dos_diez_mostrar")} /></div></center>
            : ""}
            
            {checkboxes.dos_once == true ?
            <center>  <img src={Editar7_embape} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
            width: "3%",
            height: "2em",
            }}
            name="check_2_11_mostrar"
            checked={checkboxes2.dos_once_mostrar} onClick={toggle2("dos_once_mostrar")} /></div></center>
            : ""}

          </div>


     
        {/* Fila dos */}

    <div class="col-sm-6 card">
<center><h6><b>SUPLENTES</b></h6></center>
<br/>
{checkboxes.dos_uno == false ?
<center> <img src={Editar7_messi_si} width="380px" /> 
<div>
<input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_1_mostrar"
checked={checkboxes2.dos_uno_mostrar} onClick={toggle2("dos_uno_mostrar")}
 /></div>
</center>
: ""}

{checkboxes.dos_dos == false ?
<center>  <img src={Editar7_davison} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_2_mostrar"
checked={checkboxes2.dos_dos_mostrar} onClick={toggle2("dos_dos_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_tres == false ?
<center>  <img src={Editar7_johan} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_3_mostrar"
checked={checkboxes2.dos_tres_mostrar} onClick={toggle2("dos_tres_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_cuatro == false ?
<center>  <img src={Editar7_alexander} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_4_mostrar"
checked={checkboxes2.dos_cuatro_mostrar} onClick={toggle2("dos_cuatro_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_cinco == false ?
<center>  <img src={Editar7_thiago} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_5_mostrar"
checked={checkboxes2.dos_cinco_mostrar} onClick={toggle2("dos_cinco_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_seis == false ?
<center>  <img src={Editar7_neymar} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_6_mostrar"
checked={checkboxes2.dos_seis_mostrar} onClick={toggle2("dos_seis_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_siete == false ?
<center>  <img src={Editar7_cardona} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_7_mostrar"
checked={checkboxes2.dos_siete_mostrar} onClick={toggle2("dos_siete_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_ocho == false ?
<center>  <img src={Editar7_luis} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_8_mostrar"
checked={checkboxes2.dos_ocho_mostrar} onClick={toggle2("dos_ocho_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_nueve == false ?
<center>  <img src={Editar7_chara} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_9_mostrar"
checked={checkboxes2.dos_nueve_mostrar} onClick={toggle2("dos_nueve_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_diez == false ?
<center>  <img src={Editar7_teo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_10_mostrar"
checked={checkboxes2.dos_diez_mostrar} onClick={toggle2("dos_diez_mostrar")}
 /></div></center>
: ""}

{checkboxes.dos_once == false ?
<center>  <img src={Editar7_embape} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_2_11_mostrar"
checked={checkboxes2.dos_once_mostrar} onClick={toggle2("dos_once_mostrar")}
 /></div></center>
: ""}

</div>

          </div>
                </>
                )}
                </div>





                <div className="col-sm-12">
        {cambio2 === "si" && (

            
            <>
                <button className="btn btn-warning" onClick={cambioclick_si_dos}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                <button className="btn btn-success float-right" onClick={cambioclick_si_tres}>Guardar <i class="fas fa-arrow-right"></i></button>&nbsp;
                </> )}
                </div>

                 {/* cambio para el equipo  parte 2*/}  
                 {cambio_parte2 === "si" && (
                 <div class="row">

    
           
           

           
          
               
      
                <div class="col-sm-6 card">
        {/* Equipo 1 titulares */}        
                <br/>
{checkboxes2.uno_uno_mostrar == true && checkboxes.uno_uno == true  ?       
             <center> <img src={Equipo1} width="120px"  /><img src={Editar7_si} width="380px" />  <img src={Sale2} width="50px" /> 
             <div>
            </div>
             </center>
               : ""}


{checkboxes2.uno_dos_mostrar == true && checkboxes.uno_dos == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_mina} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_tres_mostrar == true && checkboxes.uno_tres == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_murillo} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_cuatro_mostrar == true && checkboxes.uno_cuatro == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_ospina} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_cinco_mostrar == true && checkboxes.uno_cinco == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_dani} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_seis_mostrar == true && checkboxes.uno_seis == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_james} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_siete_mostrar == true && checkboxes.uno_siete == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_barrios} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_ocho_mostrar == true && checkboxes.uno_ocho == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_juanfer} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_nueve_mostrar == true && checkboxes.uno_nueve == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_carrascal} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.uno_diez_mostrar == true && checkboxes.uno_diez == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_gio} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}
              
{checkboxes2.uno_once_mostrar == true && checkboxes.uno_once == true  ? 
                    <center> <img src={Equipo1} width="120px"  /><img src={Editar7_ibarguen} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}


{/* Equipo 2 titulares */}

{checkboxes2.dos_uno_mostrar == true && checkboxes.dos_uno == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_messi_si} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_dos_mostrar == true && checkboxes.dos_dos == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_davison} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_tres_mostrar == true && checkboxes.dos_tres == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_johan} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_cuatro_mostrar == true && checkboxes.dos_cuatro == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_alexander} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_cinco_mostrar == true && checkboxes.dos_cinco == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_thiago} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_seis_mostrar == true && checkboxes.dos_seis == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_neymar} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_siete_mostrar == true && checkboxes.dos_siete == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_cardona} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_ocho_mostrar == true && checkboxes.dos_ocho == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_luis} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_nueve_mostrar == true && checkboxes.dos_nueve == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_chara} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_diez_mostrar == true && checkboxes.dos_diez == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_teo} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

{checkboxes2.dos_once_mostrar == true && checkboxes.dos_once == true  ? 
                    <center> <img src={Equipo2} width="120px"  /><img src={Editar7_embape} width="380px" />  <img src={Sale2} width="50px" /> 
                    <div>
                    </div>
                    </center>
                    : ""}

              </div>

<div class="col-sm-6 card">
     {/* Equipo 1 suplentes */}  
     <br/>
{checkboxes2.uno_uno_mostrar == true && checkboxes.uno_uno == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_si} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_dos_mostrar == true && checkboxes.uno_dos == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_mina} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_tres_mostrar == true && checkboxes.uno_tres == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_murillo} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_cuatro_mostrar == true && checkboxes.uno_cuatro == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_ospina} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_cinco_mostrar == true && checkboxes.uno_cinco == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_dani} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_seis_mostrar == true && checkboxes.uno_seis == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_james} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_siete_mostrar == true && checkboxes.uno_siete == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_barrios} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_ocho_mostrar == true && checkboxes.uno_ocho == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_juanfer} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_nueve_mostrar == true && checkboxes.uno_nueve == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_carrascal} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_diez_mostrar == true && checkboxes.uno_diez == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_gio} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.uno_once_mostrar == true && checkboxes.uno_once == false  ?
              <center><img src={Equipo1} width="120px"  /> <img src={Editar7_ibarguen} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

 {/* Equipo 2 suplentes */}

 {checkboxes2.dos_uno_mostrar == true && checkboxes.dos_uno == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_messi_si} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_dos_mostrar == true && checkboxes.dos_dos == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_davison} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_tres_mostrar == true && checkboxes.dos_tres == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_johan} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_cuatro_mostrar == true && checkboxes.dos_cuatro == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_alexander} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_cinco_mostrar == true && checkboxes.dos_cinco == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_thiago} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_seis_mostrar == true && checkboxes.dos_seis == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_neymar} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_siete_mostrar == true && checkboxes.dos_siete == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_cardona} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_ocho_mostrar == true && checkboxes.dos_ocho == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_luis} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_nueve_mostrar == true && checkboxes.dos_nueve == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_chara} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_diez_mostrar == true && checkboxes.dos_diez == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_teo} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

{checkboxes2.dos_once_mostrar == true && checkboxes.dos_once == false  ?
              <center><img src={Equipo2} width="120px"  /> <img src={Editar7_embape} width="380px" />  <img src={Entra2} width="50px" /> 
              <div>
             </div>
              </center>
               : ""}

</div>  


                
                    
                
                </div>
  )}


<div className="col-sm-12">
        {cambio3 === "si" && (

            
            <>
                <button className="btn btn-warning" onClick={cambioclick_si_dos}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                <button className="btn btn-success float-right" onClick={golclick_si}>Guardar <i class="fas fa-arrow-right"></i></button>&nbsp;
                </> )}
                </div>

{/*Quien marco el gol */}

<div className="col-sm-12">
        {menugol === "si" && (
            <div className='row'>
                  <div class="col-sm-12">

                  <center> <img src={Editar1} width="445px" /> </center>

                 </div>

                 <div class="col-sm-12">

              
               <center> <button style={{backgroundColor:"transparent", border:"none"}}
                        onClick={asistenciajuegoclick_si} >
                        <img src={Asistencia_al_juego} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none"}}
                        onClick={cambioclick_si_tres}>
                        <img src={Cambio_para_equipo_ok} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none"}}
                        onClick={golclick_si_button}>
                        <img src={Quien_marco_gol_ok} width="220px"  /> </button>

                        <button style={{backgroundColor:"transparent", border:"none", opacity: "0.5"}} disabled>
                        <img src={Quien_recibio_tarjeta} width="220px"  /> </button>
                        </center>

                <hr />
                </div>
                </div>
                )}
                </div>

                <div className="col-sm-12">
              {gol0 === "si" && (
           <center> <button style={{backgroundColor:"transparent", border:"none"}}>
            <img src={Gol_boton} width="220px"
            onClick={gol_uno}  /> </button>

            <button style={{backgroundColor:"transparent", border:"none"}}>
            <img src={Autogol_boton} width="220px" 
            onClick={cambioclick_dos} /> </button>
            </center>
                 )}
                </div>

       
        <div className="col-sm-12">
        {golvolver === "si" && (
            <>
                <button className="btn btn-warning" onClick={cambioclick_si_tres}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                </> )}
                </div>

           {/* Parte 2 quien marco el gol */}    

           <div className="col-sm-12">
              {golp1 === "si" && (
                  <>
                  <center> <img src={Gol_equipo} width="820px" /></center>
           <center> <button style={{backgroundColor:"transparent", border:"none"}}>
            <img src={Botonequipo1} width="220px"
            onClick={Golesequipo_uno}  /> </button>

            <button style={{backgroundColor:"transparent", border:"none"}}>
            <img src={Botonequipo2} width="220px" 
            onClick={cambioclick_dos} /> </button>
            </center>
            </>
                 )}
                 
                </div> 

                <div className="col-sm-12">
        {golvolver2 === "si" && (
            <>
                <button className="btn btn-warning" onClick={golclick_si}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                </> )}
                </div>


                 {/* Parte 3 quien marco el gol */}  

                 <div className="col-sm-12">
        {goles_equipo1 === "si" && (
            <>
               
               <div class="row">
<center><img src={Equipo1} width="150px" /></center>
<center><img src={Marca_gol} width="800px" /></center>

<div class="col-sm-6 card">
<center><h6><b>TITULARES</b></h6></center>
<br/>
{checkboxes.uno_uno == true ?
<center>  <img src={Editar7_si} width="380px" /> 
<div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_1_mostrar"
checked={checkboxes3.tres_uno_mostrar} onClick={toggle3("tres_uno_mostrar")} /></div>
</center>
: ""}

{checkboxes.uno_dos == true ?
<center>  <img src={Editar7_mina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_2_mostrar"
checked={checkboxes3.tres_dos_mostrar} onClick={toggle3("tres_dos_mostrar")} /></div></center>
: ""}

{checkboxes.uno_tres == true ?
<center>  <img src={Editar7_murillo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_3_mostrar"
checked={checkboxes3.tres_tres_mostrar} onClick={toggle3("tres_tres_mostrar")} /></div></center>
: ""}

{checkboxes.uno_cuatro == true ?
<center>  <img src={Editar7_ospina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_4_mostrar"
checked={checkboxes3.tres_cuatro_mostrar} onClick={toggle3("tres_cuatro_mostrar")} /></div></center>
: ""}

{checkboxes.uno_cinco == true ?
<center>  <img src={Editar7_dani} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_5_mostrar"
checked={checkboxes3.tres_cinco_mostrar} onClick={toggle3("tres_cinco_mostrar")} /></div></center>
: ""}

{checkboxes.uno_seis == true ?
<center>  <img src={Editar7_james} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_6_mostrar"
checked={checkboxes3.tres_seis_mostrar} onClick={toggle3("tres_seis_mostrar")} /></div></center>
: ""}

{checkboxes.uno_siete == true ?
<center>  <img src={Editar7_barrios} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_7_mostrar"
checked={checkboxes3.tres_siete_mostrar} onClick={toggle3("tres_siete_mostrar")} /></div></center>
: ""}

{checkboxes.uno_ocho == true ?
<center>  <img src={Editar7_juanfer} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_8_mostrar"
checked={checkboxes3.tres_ocho_mostrar} onClick={toggle3("tres_ocho_mostrar")} /></div></center>
: ""}

{checkboxes.uno_nueve == true ?
<center>  <img src={Editar7_carrascal} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_9_mostrar"
checked={checkboxes3.tres_nueve_mostrar} onClick={toggle3("tres_nueve_mostrar")} /></div></center>
: ""}

{checkboxes.uno_diez == true ?
<center>  <img src={Editar7_gio} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_10_mostrar"
checked={checkboxes3.tres_diez_mostrar} onClick={toggle3("tres_diez_mostrar")} /></div></center>
: ""}

{checkboxes.uno_once == true ?
<center>  <img src={Editar7_ibarguen} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_11_mostrar"
checked={checkboxes3.tres_once_mostrar} onClick={toggle3("tres_once_mostrar")} /></div></center>
: ""}

</div>




    {/* Fila dos */}

    <div class="col-sm-6 card">
    <center><h6><b>SUPLENTES</b></h6></center>
<br/>
{checkboxes.uno_uno == false ?
<center>  <img src={Editar7_si} width="380px" /> 
<div>
<input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}}
name="check_3_1_mostrar"
checked={checkboxes3.cuatro_uno_mostrar} onClick={toggle3("cuatro_uno_mostrar")}
 /></div>
</center>
: ""}

{checkboxes.uno_dos == false ?
<center>  <img src={Editar7_mina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_2_mostrar"
checked={checkboxes3.cuatro_dos_mostrar} onClick={toggle3("cuatro_dos_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_tres == false ?
<center>  <img src={Editar7_murillo} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_3_mostrar"
checked={checkboxes3.cuatro_tres_mostrar} onClick={toggle3("cuatro_tres_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_cuatro == false ?
<center>  <img src={Editar7_ospina} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_4_mostrar"
checked={checkboxes3.cuatro_cuatro_mostrar} onClick={toggle3("cuatro_cuatro_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_cinco == false ?
<center>  <img src={Editar7_dani} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_5_mostrar"
checked={checkboxes3.cuatro_cinco_mostrar} onClick={toggle3("cuatro_cinco_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_seis == false ?
<center>  <img src={Editar7_james} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_6_mostrar"
checked={checkboxes3.cuatro_seis_mostrar} onClick={toggle3("cuatro_seis_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_siete == false ?
<center>  <img src={Editar7_barrios} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_7_mostrar"
checked={checkboxes3.cuatro_siete_mostrar} onClick={toggle3("cuatro_siete_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_ocho == false ?
<center>  <img src={Editar7_juanfer} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_8_mostrar"
checked={checkboxes3.cuatro_ocho_mostrar} onClick={toggle3("cuatro_ocho_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_nueve == false ?
<center>  <img src={Editar7_carrascal} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_9_mostrar"
checked={checkboxes3.cuatro_nueve_mostrar} onClick={toggle3("cuatro_nueve_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_diez == false ?
<center>  <img src={Editar7_gio} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_10_mostrar"
checked={checkboxes3.cuatro_diez_mostrar} onClick={toggle3("cuatro_diez_mostrar")}
/></div></center>
: ""}

{checkboxes.uno_once == false ?
<center>  <img src={Editar7_ibarguen} width="380px" /> <div><input type="checkbox"  style={{border: "0px",
width: "3%",
height: "2em",
}} 
name="check_3_11_mostrar"
checked={checkboxes3.cuatro_once_mostrar} onClick={toggle3("cuatro_once_mostrar")}
/></div></center>
: ""}

</div>



</div>


                </> )}
                </div>


                <div className="col-sm-12">
        {gol_guardar_parte3 === "si" && (
            <>
                <button className="btn btn-warning" onClick={gol_uno}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                <button className="btn btn-success float-right" onClick={Golparte4_si}>Guardar <i class="fas fa-arrow-right"></i></button>&nbsp;
                </> )}
                </div>
                 
        {/* Parte 4 quien marco gol */}
            
                  <div className="col-sm-12">
        {gol_guardar_parte4 === "si" && (
            <>
           <center> <img src={Equipo1} width="170px"  />  </center> 
            <div class="row">

    
           
           

             
          
               
      
<div class="col-sm-12 card">


{/* Equipo 1 titulares */}        
<br/>
{checkboxes3.tres_uno_mostrar == true && checkboxes.uno_uno == true  ?       
<center> <img src={Editar7_si} width="380px" /> 


<center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_1_mostrar"
checked={checkboxes4.cinco_uno_mostrar} onClick={toggle4("cinco_uno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_2_mostrar"
checked={checkboxes4.cinco_dos_mostrar} onClick={toggle4("cinco_dos_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_3_mostrar"
checked={checkboxes4.cinco_3_mostrar} onClick={toggle4("cinco_tres_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_4_mostrar"
checked={checkboxes4.cinco_cuatro_mostrar} onClick={toggle4("cinco_cuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_5_mostrar"
checked={checkboxes4.cinco_cinco_mostrar} onClick={toggle4("cinco_cinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_6_mostrar"
checked={checkboxes4.cinco_seis_mostrar} onClick={toggle4("cinco_seis_mostrar")}
/></div></center>

</div>



</div>

<div>
</div>
<hr/>
</center>

: ""}


{checkboxes3.tres_dos_mostrar == true && checkboxes.uno_dos == true  ? 
    <center><img src={Editar7_mina} width="380px" />  
    <div>
        
   
<center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_7_mostrar"
checked={checkboxes4.cinco_siete_mostrar} onClick={toggle4("cinco_siete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_8_mostrar"
checked={checkboxes4.cinco_ocho_mostrar} onClick={toggle4("cinco_ocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_9_mostrar"
checked={checkboxes4.cinco_nueve_mostrar} onClick={toggle4("cinco_nueve_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_10_mostrar"
checked={checkboxes4.cinco_diez_mostrar} onClick={toggle4("cinco_diez_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_11_mostrar"
checked={checkboxes4.cinco_once_mostrar} onClick={toggle4("cinco_once_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_12_mostrar"
checked={checkboxes4.cinco_doce_mostrar} onClick={toggle4("cinco_doce_mostrar")}
/></div></center>

</div>



</div>

</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_tres_mostrar == true && checkboxes.uno_tres == true  ? 
    <center><img src={Editar7_murillo} width="380px" /> 
    <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_13_mostrar"
checked={checkboxes4.cinco_trece_mostrar} onClick={toggle4("cinco_trece_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_14_mostrar"
checked={checkboxes4.cinco_catorce_mostrar} onClick={toggle4("cinco_catorce_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_15_mostrar"
checked={checkboxes4.cinco_quince_mostrar} onClick={toggle4("cinco_quince_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_16_mostrar"
checked={checkboxes4.cinco_diesiseis_mostrar} onClick={toggle4("cinco_diesiseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_18_mostrar"
checked={checkboxes4.cinco_diesisiete_mostrar} onClick={toggle4("cinco_diesisiete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_12_mostrar"
checked={checkboxes4.cinco_diesiocho_mostrar} onClick={toggle4("cinco_diesiocho_mostrar")}
/></div></center>

</div>



</div>

<hr/>
</center>
    : ""}

{checkboxes3.tres_cuatro_mostrar == true && checkboxes.uno_cuatro == true  ? 
    <center><img src={Editar7_ospina} width="380px" /> 
    <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_19_mostrar"
checked={checkboxes4.cinco_diesinueve_mostrar} onClick={toggle4("cinco_diesinueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_20_mostrar"
checked={checkboxes4.cinco_veinte_mostrar} onClick={toggle4("cinco_veinte_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_21_mostrar"
checked={checkboxes4.cinco_veinteuno_mostrar} onClick={toggle4("cinco_veinteuno_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_22_mostrar"
checked={checkboxes4.cinco_veintedos_mostrar} onClick={toggle4("cinco_veintedos_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_23_mostrar"
checked={checkboxes4.cinco_veintetres_mostrar} onClick={toggle4("cinco_veintetres_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_24_mostrar"
checked={checkboxes4.cinco_veintecuatro_mostrar} onClick={toggle4("cinco_veintecuatro_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_cinco_mostrar == true && checkboxes.uno_cinco == true  ? 
    <center> <img src={Editar7_dani} width="380px" /> 
     <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_25_mostrar"
checked={checkboxes4.cinco_veintecinco_mostrar} onClick={toggle4("cinco_veintecinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_26_mostrar"
checked={checkboxes4.cinco_veinteseis_mostrar} onClick={toggle4("cinco_veinteseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_27_mostrar"
checked={checkboxes4.cinco_veintesiete_mostrar} onClick={toggle4("cinco_veintesiete_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_28_mostrar"
checked={checkboxes4.cinco_veinteocho_mostrar} onClick={toggle4("cinco_veinteocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_29_mostrar"
checked={checkboxes4.cinco_veintenueve_mostrar} onClick={toggle4("cinco_veintenueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_30_mostrar"
checked={checkboxes4.cinco_treinta_mostrar} onClick={toggle4("cinco_treinta_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_seis_mostrar == true && checkboxes.uno_seis == true  ? 
    <center><img src={Editar7_james} width="380px" />  
    <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_31_mostrar"
checked={checkboxes4.cinco_treintauno_mostrar} onClick={toggle4("cinco_treintauno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_32_mostrar"
checked={checkboxes4.cinco_treintados_mostrar} onClick={toggle4("cinco_treintados_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_33_mostrar"
checked={checkboxes4.cinco_treintatres_mostrar} onClick={toggle4("cinco_treintatres_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_34_mostrar"
checked={checkboxes4.cinco_treintacuatro_mostrar} onClick={toggle4("cinco_treintacuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_35_mostrar"
checked={checkboxes4.cinco_treintacinco_mostrar} onClick={toggle4("cinco_treintacinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_36_mostrar"
checked={checkboxes4.cinco_treintaseis_mostrar} onClick={toggle4("cinco_treintaseis_mostrar")}
/></div></center>

</div>

</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_siete_mostrar == true && checkboxes.uno_siete == true  ? 
    <center> <img src={Editar7_barrios} width="380px" /> 
   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_37_mostrar"
checked={checkboxes4.cinco_treintasiete_mostrar} onClick={toggle4("cinco_treintasiete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_38_mostrar"
checked={checkboxes4.cinco_treintaocho_mostrar} onClick={toggle4("cinco_treintaocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_39_mostrar"
checked={checkboxes4.cinco_treintanueve_mostrar} onClick={toggle4("cinco_treintanueve_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_40_mostrar"
checked={checkboxes4.cinco_cuarenta_mostrar} onClick={toggle4("cinco_cuarenta_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_41_mostrar"
checked={checkboxes4.cinco_cuarentauno_mostrar} onClick={toggle4("cinco_cuarentauno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_42_mostrar"
checked={checkboxes4.cinco_cuarentados_mostrar} onClick={toggle4("cinco_cuarentados_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_ocho_mostrar == true && checkboxes.uno_ocho == true  ? 
    <center><img src={Editar7_juanfer} width="380px" />  
    <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_43_mostrar"
checked={checkboxes4.cinco_cuarentatres_mostrar} onClick={toggle4("cinco_cuarentatres_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_44_mostrar"
checked={checkboxes4.cinco_cuarentacuatro_mostrar} onClick={toggle4("cinco_cuarentacuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_45_mostrar"
checked={checkboxes4.cinco_cuarentacinco_mostrar} onClick={toggle4("cinco_cuarentacinco_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_46_mostrar"
checked={checkboxes4.cinco_cuarentaseis_mostrar} onClick={toggle4("cinco_cuarentaseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_47_mostrar"
checked={checkboxes4.cinco_cuarentasiete_mostrar} onClick={toggle4("cinco_cuarentasiete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_48_mostrar"
checked={checkboxes4.cinco_cuarentaocho_mostrar} onClick={toggle4("cinco_cuarentaocho_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_nueve_mostrar == true && checkboxes.uno_nueve == true  ? 
    <center> <img src={Editar7_carrascal} width="380px" />  
   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_49_mostrar"
checked={checkboxes4.cinco_cuarentanueve_mostrar} onClick={toggle4("cinco_cuarentanueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_50_mostrar"
checked={checkboxes4.cinco_cincuenta_mostrar} onClick={toggle4("cinco_cincuenta_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_51_mostrar"
checked={checkboxes4.cinco_cincuentauno_mostrar} onClick={toggle4("cinco_cincuentauno_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_52_mostrar"
checked={checkboxes4.cinco_cincuentados_mostrar} onClick={toggle4("cinco_cincuentados_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_53_mostrar"
checked={checkboxes4.cinco_cincuentatres_mostrar} onClick={toggle4("cinco_cincuentatres_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_54_mostrar"
checked={checkboxes4.cinco_cincunetacuatro_mostrar} onClick={toggle4("cinco_cincuentacuatro_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_diez_mostrar == true && checkboxes.uno_diez == true  ? 
    <center><img src={Editar7_gio} width="380px" />  
    <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_55_mostrar"
checked={checkboxes4.cinco_cincuentacinco_mostrar} onClick={toggle4("cinco_cincuentacinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_56_mostrar"
checked={checkboxes4.cinco_cincuentaseis_mostrar} onClick={toggle4("cinco_cincuentaseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_57_mostrar"
checked={checkboxes4.cinco_cincuentasiete_mostrar} onClick={toggle4("cinco_cincunetasiete_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_58_mostrar"
checked={checkboxes4.cinco_cincuentaocho_mostrar} onClick={toggle4("cinco_cincuentaocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_59_mostrar"
checked={checkboxes4.cinco_cincuentanueve_mostrar} onClick={toggle4("cinco_cincuentanueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_60_mostrar"
checked={checkboxes4.cinco_sesenta_mostrar} onClick={toggle4("cinco_sesenta_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
    : ""}

{checkboxes3.tres_once_mostrar == true && checkboxes.uno_once == true  ? 
    <center> <img src={Editar7_ibarguen} width="380px" /> 
    <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_60_mostrar"
checked={checkboxes4.cinco_sesentauno_mostrar} onClick={toggle4("cinco_sesentauno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_61_mostrar"
checked={checkboxes4.cinco_sesentauno_mostrar} onClick={toggle4("cinco_sesentauno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_62_mostrar"
checked={checkboxes4.cinco_sesentados_mostrar} onClick={toggle4("cinco_sesentados_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_63_mostrar"
checked={checkboxes4.cinco_sesentatres_mostrar} onClick={toggle4("cinco_sesentatres_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_64_mostrar"
checked={checkboxes4.cinco_sesentacuatro_mostrar} onClick={toggle4("cinco_sesentacuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_5_65_mostrar"
checked={checkboxes4.cinco_sesentacinco_mostrar} onClick={toggle4("cinco_sesentacinco_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
    : ""}



{/* Equipo 1 suplentes */}  

{checkboxes3.cuatro_uno_mostrar == true && checkboxes.uno_uno == false  ?
              <center> <img src={Editar7_si} width="380px" /> 
              <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_1_mostrar"
checked={checkboxes4.seis_uno_mostrar} onClick={toggle4("seis_uno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_2_mostrar"
checked={checkboxes4.seis_dos_mostrar} onClick={toggle4("seis_dos_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_3_mostrar"
checked={checkboxes4.seis_tres_mostrar} onClick={toggle4("seis_tres_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_4_mostrar"
checked={checkboxes4.seis_cuatro_mostrar} onClick={toggle4("seis_cuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_5_mostrar"
checked={checkboxes4.seis_cinco_mostrar} onClick={toggle4("seis_cinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_6_mostrar"
checked={checkboxes4.seis_seis_mostrar} onClick={toggle4("seis_seis_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
 : ""}

{checkboxes3.cuatro_dos_mostrar == true && checkboxes.uno_dos == false  ?
              <center><img src={Editar7_mina} width="380px" /> 
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_7_mostrar"
checked={checkboxes4.seis_siete_mostrar} onClick={toggle4("seis_siete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_8_mostrar"
checked={checkboxes4.seis_ocho_mostrar} onClick={toggle4("seis_ocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_9_mostrar"
checked={checkboxes4.seis_nueve_mostrar} onClick={toggle4("seis_nueve_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_10_mostrar"
checked={checkboxes4.seis_diez_mostrar} onClick={toggle4("seis_diez_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_11_mostrar"
checked={checkboxes4.seis_once_mostrar} onClick={toggle4("seis_once_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_12_mostrar"
checked={checkboxes4.seis_doce_mostrar} onClick={toggle4("seis_doce_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
  : ""}

{checkboxes3.cuatro_tres_mostrar == true && checkboxes.uno_tres == false  ?
              <center><img src={Editar7_murillo} width="380px" /> 
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_13_mostrar"
checked={checkboxes4.seis_trece_mostrar} onClick={toggle4("seis_trece_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_14_mostrar"
checked={checkboxes4.seis_catorce_mostrar} onClick={toggle4("seis_catorce_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_15_mostrar"
checked={checkboxes4.seis_quince_mostrar} onClick={toggle4("seis_quince_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_16_mostrar"
checked={checkboxes4.seis_diesiseis_mostrar} onClick={toggle4("seis_diesiseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_17_mostrar"
checked={checkboxes4.seis_diesisiete_mostrar} onClick={toggle4("seis_diesisiete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_18_mostrar"
checked={checkboxes4.seis_diesiocho_mostrar} onClick={toggle4("seis_diesiocho_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
               : ""}

{checkboxes3.cuatro_cuatro_mostrar == true && checkboxes.uno_cuatro == false  ?
              <center><img src={Editar7_ospina} width="380px" />
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_19_mostrar"
checked={checkboxes4.seis_diesinueve_mostrar} onClick={toggle4("seis_diesinueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_20_mostrar"
checked={checkboxes4.seis_veinte_mostrar} onClick={toggle4("seis_veinte_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_21_mostrar"
checked={checkboxes4.seis_veinteuno_mostrar} onClick={toggle4("seis_veinteuno_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_22_mostrar"
checked={checkboxes4.seis_veintedos_mostrar} onClick={toggle4("seis_veintedos_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_23_mostrar"
checked={checkboxes4.seis_veintetres_mostrar} onClick={toggle4("seis_ventetres_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_24_mostrar"
checked={checkboxes4.seis_veintecuatro_mostrar} onClick={toggle4("seis_veintecuatro_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
: ""}

{checkboxes3.cuatro_cinco_mostrar == true && checkboxes.uno_cinco == false  ?
              <center> <img src={Editar7_dani} width="380px" /> 
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_25_mostrar"
checked={checkboxes4.seis_veintecinco_mostrar} onClick={toggle4("seis_veintecinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_26_mostrar"
checked={checkboxes4.seis_veinteseis_mostrar} onClick={toggle4("seis_veinteseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_27_mostrar"
checked={checkboxes4.seis_veintesiete_mostrar} onClick={toggle4("seis_veintesiete_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_28_mostrar"
checked={checkboxes4.seis_veinteocho_mostrar} onClick={toggle4("seis_veinteocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_29_mostrar"
checked={checkboxes4.seis_veintenueve_mostrar} onClick={toggle4("seis_veintenueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_30_mostrar"
checked={checkboxes4.seis_treinta_mostrar} onClick={toggle4("seis_treinta_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
: ""}

{checkboxes3.cuatro_seis_mostrar == true && checkboxes.uno_seis == false  ?
              <center><img src={Editar7_james} width="380px" /> 
                  <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_31_mostrar"
checked={checkboxes4.seis_treintauno_mostrar} onClick={toggle4("seis_treintauno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_32_mostrar"
checked={checkboxes4.seis_treintados_mostrar} onClick={toggle4("seis_treintados_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_33_mostrar"
checked={checkboxes4.seis_treintatres_mostrar} onClick={toggle4("seis_treintatres_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_34_mostrar"
checked={checkboxes4.seis_treintacuatro_mostrar} onClick={toggle4("seis_treintacuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_35_mostrar"
checked={checkboxes4.seis_treintacinco_mostrar} onClick={toggle4("seis_treintacinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_36_mostrar"
checked={checkboxes4.seis_treintaseis_mostrar} onClick={toggle4("seis_treintaseis_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
: ""}

{checkboxes3.cuatro_siete_mostrar == true && checkboxes.uno_siete == false  ?
              <center><img src={Editar7_barrios} width="380px" /> 
                  <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_37_mostrar"
checked={checkboxes4.seis_treintasiete_mostrar} onClick={toggle4("seis_treintasiete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_38_mostrar"
checked={checkboxes4.seis_treintaocho_mostrar} onClick={toggle4("seis_treintaocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_39_mostrar"
checked={checkboxes4.seis_treintanueve_mostrar} onClick={toggle4("seis_treintanueve_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_40_mostrar"
checked={checkboxes4.seis_cuarenta_mostrar} onClick={toggle4("seis_cuarenta_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_41_mostrar"
checked={checkboxes4.seis_cuarentauno_mostrar} onClick={toggle4("seis_cuarentauno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_42_mostrar"
checked={checkboxes4.seis_cuarentados_mostrar} onClick={toggle4("seis_cuarentados_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
: ""}

{checkboxes3.cuatro_ocho_mostrar == true && checkboxes.uno_ocho == false  ?
              <center> <img src={Editar7_juanfer} width="380px" />  
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_43_mostrar"
checked={checkboxes4.seis_cuarentatres_mostrar} onClick={toggle4("seis_cuarentatres_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_44_mostrar"
checked={checkboxes4.seis_cuarentacuatro_mostrar} onClick={toggle4("seis_cuarentacuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_45_mostrar"
checked={checkboxes4.seis_cuarentacinco_mostrar} onClick={toggle4("seis_cuarentacinco_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_46_mostrar"
checked={checkboxes4.seis_cuarentaseis_mostrar} onClick={toggle4("seis_cuarentaseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_47_mostrar"
checked={checkboxes4.seis_cuarentasiete_mostrar} onClick={toggle4("seis_cuarentasiete_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_48_mostrar"
checked={checkboxes4.seis_cuarentaocho_mostrar} onClick={toggle4("seis_cuarentaocho_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
               : ""}

{checkboxes3.cuatro_nueve_mostrar == true && checkboxes.uno_nueve == false  ?
              <center> <img src={Editar7_carrascal} width="380px" />
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_49_mostrar"
checked={checkboxes4.seis_cuarentanueve_mostrar} onClick={toggle4("seis_cuarentanueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_50_mostrar"
checked={checkboxes4.seis_cincuenta_mostrar} onClick={toggle4("seis_cincuenta_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_51_mostrar"
checked={checkboxes4.seis_cincuentauno_mostrar} onClick={toggle4("seis_cincuentauno_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_52_mostrar"
checked={checkboxes4.seis_cincuentados_mostrar} onClick={toggle4("seis_cincuentados_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_53_mostrar"
checked={checkboxes4.seis_cincuentatres_mostrar} onClick={toggle4("seis_cincuentatres_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_54_mostrar"
checked={checkboxes4.seis_cincuentacuatro_mostrar} onClick={toggle4("seis_cincuentacuatro_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
 : ""}

{checkboxes3.cuatro_diez_mostrar == true && checkboxes.uno_diez == false  ?
              <center> <img src={Editar7_gio} width="380px" /> 
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_55_mostrar"
checked={checkboxes4.seis_cincuentacinco_mostrar} onClick={toggle4("seis_cincuentacinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_56_mostrar"
checked={checkboxes4.seis_cincuentasesis_mostrar} onClick={toggle4("seis_cincuentaseis_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_57_mostrar"
checked={checkboxes4.seis_cincuentasiete_mostrar} onClick={toggle4("seis_cincuentasiete_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_58_mostrar"
checked={checkboxes4.seis_cincuentaocho_mostrar} onClick={toggle4("seis_cincuentaocho_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_59_mostrar"
checked={checkboxes4.seis_cincuentanueve_mostrar} onClick={toggle4("seis_cincuentanueve_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_60_mostrar"
checked={checkboxes4.seis_sesenta_mostrar} onClick={toggle4("seis_sesenta_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
               : ""}

{checkboxes3.cuatro_once_mostrar == true && checkboxes.uno_once == false  ?
              <center><img src={Editar7_ibarguen} width="380px" />   
                   <center>
<br/>

<div className="row">

    <div class="col-sm-3">

 <img src={Editar13_b} width="280px" /> 
 </div>

<div class="col-sm-2">
    
    <h6><b>MIN</b></h6>
<input type="number"  class="form-control"></input>

</div>


<div class="col-sm-2">
    
    <h6><b>SEG</b></h6>
<input type="number"  class="form-control"></input>

</div>


</div>
<div className="row" style={{justifyContent:"center"}}>

<img src={Como_fue_el_gol} width="150px" />

<div class="col-sm-3">
<center>  <img src={Editar15} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_61_mostrar"
checked={checkboxes4.seis_sesentauno_mostrar} onClick={toggle4("seis_sesentauno_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar16} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_62_mostrar"
checked={checkboxes4.seis_sesentados_mostrar} onClick={toggle4("seis_sesentados_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar17} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_63_mostrar"
checked={checkboxes4.seis_sesentatres_mostrar} onClick={toggle4("seis_sesentatres_mostrar")}
/></div></center>

</div>



</div>

</center>

<div className="row" style={{justifyContent:"center"}}>

    

<div class="col-sm-3">
<center>  <img src={Editar18} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_64_mostrar"
checked={checkboxes4.seis_sesentacuatro_mostrar} onClick={toggle4("seis_sesentacuatro_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar19} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_65_mostrar"
checked={checkboxes4.seis_sesentacinco_mostrar} onClick={toggle4("seis_sesentacinco_mostrar")}
/></div></center>

</div>


<div class="col-sm-3">
<center>  <img src={Editar20} width="220px" /> <div><input type="checkbox"  style={{border: "0px",
width: "8%",
height: "2em",
}} 
name="check_6_66_mostrar"
checked={checkboxes4.seis_sesentaseis_mostrar} onClick={toggle4("seis_sesentaseis_mostrar")}
/></div></center>

</div>


</div>
<hr/>
</center>
 : ""}


    </div>
    </div>

                </> )}
                </div>

                <div className="col-sm-12">
        {gol_guardar_parte5 === "si" && (
            <>
                <button className="btn btn-warning" onClick={Golesequipo_uno}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                <button className="btn btn-success float-right" onClick={Golparte4_si}>Guardar <i class="fas fa-arrow-right"></i></button>&nbsp;
                </> )}
                </div>

                  </div>

              
                 
               
                 
                </div>
                
                
          </div>
    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;      
      
}
 }

export default Auditar