import React, { createContext,Component } from "react";
import axios from 'axios'
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
   /* baseURL: 'https://www.plooy.club/php-login-registration-api/',*/
   baseURL: 'https://www.deportes.controlsas.com/apiPlooy/usuarios/',

});

class MyContextProvider extends Component{
    constructor(){
        super();
        this.isLoggedIn();
    }

    // Root State
    state = {
        showLogin:true,
        isAuth:false,
        theUser:null,
    }
    
    // Toggle between Login & Signup page
    toggleNav = () => {
        const showLogin = !this.state.showLogin;
        this.setState({
            ...this.state,
            showLogin
        })
    }

    // On Click the Log out button
    logoutUser = () => {
        localStorage.removeItem('loginToken');
        this.setState({
            ...this.state,
            isAuth:false
        })
    }

    registerUser = async (user) => {

        // Sending the user registration request
        const register = await Axios.post('register.php',{
            name:user.name,
            apellidos:user.apellidos,
            email:user.email,
            telefono:user.telefono,
            nick:user.nick,
            password:user.password,
            nacionalidad:user.nacionalidad,
            genero:user.genero,
            pais:user.pais,
            departamento:user.departamento,
            ciudad:user.ciudad,
            fechanacimiento:user.fechanacimiento,
            tdoc:user.tdoc,
            documento:user.documento,
            tipo_usuario:user.tipo_usuario,
            edad:user.edad,
            modulo:user.modulo,
            deporte:user.deporte,
            subdeporte:user.subdeporte,     
            comercio_natural:user.comercio_natural,
            direccion:user.direccion,
            ciudad_nacionalidad:user.ciudad_nacionalidad,
            emailc:user.emailc,
            rh:user.rh,
            mas_menos:user.mas_menos,
            sistema_salud:user.sistema_salud,
            entidad_salud:user.entidad_salud,
            nombre_contacto:user.nombre_contacto,
            numero_contacto:user.numero_contacto, 
        });

        return register.data;
    }


    loginUser = async (user) => {

        // Sending the user Login request
        const login = await Axios.post('login.php',{
            email:user.email,
            password:user.password
        });
        return login.data;
    }

    // Checking user logged in or not
    isLoggedIn = async () => {
        const loginToken = localStorage.getItem('loginToken');

        // If inside the local-storage has the JWT token
        if(loginToken){

            //Adding JWT token to axios default header
            Axios.defaults.headers.common['Authorization'] = 'bearer '+loginToken;

            // Fetching the user information
            const {data} = await Axios.get('user-info.php');

            // If user information is successfully received
            if(data.success && data.user){
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUser:data.user
                });
            }

        }
    }

    render(){
        const contextValue = {
            rootState:this.state,
            toggleNav:this.toggleNav,
            isLoggedIn:this.isLoggedIn,
            registerUser:this.registerUser,
            loginUser:this.loginUser,
            logoutUser:this.logoutUser
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;