import React, {useContext,useState} from 'react';

import {MyContext} from '../../contexts/MyContext';

// Importing the Login & Register Componet
import Register from './Register';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

 function Criterios_desempate(props) {

 //Modal puntos ganador
const [modalPuntosGanador, setModalPuntosGanador]=useState(false);

//Modal puntos ganador

const abrirCerrarModalPuntosGanador=()=>{
      setModalPuntosGanador(!modalPuntosGanador);
}


 //Modal empate juego
 const [modalEmpateJuego, setModalEmpateJuego]=useState(false);

 //Modal empate juego
 
 const abrirCerrarModalEmpateJuego=()=>{
       setModalEmpateJuego(!modalEmpateJuego);
 }


 //Modal perdedor juego
 const [modalPerdedorJuego, setModalPerdedorJuego]=useState(false);

 //Modal perdedor juego
 
 const abrirCerrarModalPerdedorJuego=()=>{
      setModalPerdedorJuego(!modalPerdedorJuego);
}


//Modal ganador juego w
const [modalGanadorJuegoW, setModalGanadorJuegoW]=useState(false);

//Modal ganador juego w

const abrirCerrarModalGanadorJuegoW=()=>{
     setModalGanadorJuegoW(!modalGanadorJuegoW);
}


//Modal goles juego w
const [modalGolesJuegoW, setModalGolesJuegoW]=useState(false);

//Modal goles juego w

const abrirCerrarModalGolesJuegoW=()=>{
     setModalGolesJuegoW(!modalGolesJuegoW);
}


//Modal contra juego w
const [modalContraJuegoW, setModalContraJuegoW]=useState(false);

//Modal contra juego w

const abrirCerrarModalContraJuegoW=()=>{
     setModalContraJuegoW(!modalContraJuegoW);
}


//Modal contra juego w2
const [modalContraJuegoW2, setModalContraJuegoW2]=useState(false);

//Modal contra juego w2

const abrirCerrarModalContraJuegoW2=()=>{
     setModalContraJuegoW2(!modalContraJuegoW2);
}
    

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;



    if(isAuth)
    {

    return (
        
        
        
            
              <div className="col-sm-12">
                  
    
              <div class="card card-primary">
                  
                  <div class="card-header">
                    <h3 class="card-title">CRITERIOS DESEMPATE</h3>
                  </div>

                  
                
                  <div class="card-body">
                      
                  <form>


<br/>
    


      <div class="row">
        <center> <h6><b>Este modulo se refiere al Orden de los criterios en Caso de empate entre equipos a clasificar, Puede Combinar el Orden Según su Interés.
</b></h6> </center> 

<br/>
<br/>
          <div class="col-sm-3">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mayor Numero de Puntos Total</label>
                <div class="input-group">
        <div style={{marginTop:"8px"}}> <p>1) &nbsp;</p> </div><input type="number"  name="puntos_ganador" class="form-control" value={props.getState('puntos_ganador', '')} onChange={props.handleChange} />


  {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPuntosGanador()}><b>?</b></button>
    </span> */}
     
       </div> 
      </div>
      

     


      <div class="col-sm-3">
      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mayor Gol Diferencia</label>
      <div class="input-group">
      <div style={{marginTop:"8px"}}> <p>2) &nbsp;</p> </div><input type="number" name="puntos_empate" class="form-control" value={props.getState('puntos_empate', '')} onChange={props.handleChange} />


    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalEmpateJuego()}><b>?</b></button>
</span> */}
     
       </div> 

      </div>


      <div class="col-sm-3">

      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mayor Goles a Favor</label>
      <div class="input-group">
      <div style={{marginTop:"8px"}}> <p>3) &nbsp;</p> </div> <input type="number" name="puntos_perdedor" class="form-control" value={props.getState('puntos_perdedor', '')} onChange={props.handleChange} />
 
    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalPerdedorJuego()}><b>?</b></button>
</span>*/}
     
       </div> 
      </div>


    

      
    </div>
   
    

    <div class="row">
    
     
          <div class="col-sm-3">
              <br/>
          
          <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Menor Goles en Contra</label>
          <div class="input-group">
          <div style={{marginTop:"8px"}}> <p>4) &nbsp;</p> </div><input type="number" name="puntos_ganadosw" class="form-control" value={props.getState('puntos_ganadosw', '')} onChange={props.handleChange} />

    {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalGanadorJuegoW()}><b>?</b></button>
</span>*/}
     
       </div> 

      </div>
      

     


      <div class="col-sm-3">
          <br />
      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mayor Partidos Ganados</label>
      <div class="input-group">
      <div style={{marginTop:"8px"}}> <p>5) &nbsp;</p> </div><input name="goles_ganadosw" class="form-control" value={props.getState('goles_ganadosw', '')} onChange={props.handleChange} />

      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalGolesJuegoW()}><b>?</b></button>
</span>*/ }
     
       </div> 
      </div>



      <div class="col-sm-3">
      <br />
      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mayor Partidos Empatados</label>
      <div class="input-group">

      <div style={{marginTop:"8px"}}> <p>6) &nbsp;</p> </div> <input type="number" name="puntos_perdidosw" class="form-control"  value={props.getState('puntos_perdidosw', '')} onChange={props.handleChange} />

      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW()}><b>?</b></button>
</span>*/}
     
       </div> 

      
      </div>

      
      </div>


      

      <br />
      <div class="row">

     
      <div class="col-sm-3">
      <center><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Menor Numero de Juegos Perdidos </label> </center>
      <div class="input-group">
      <div style={{marginTop:"8px"}}> <p>7) &nbsp;</p> </div><input type="number" name="goles_perdidosw" class="form-control" value={props.getState('goles_perdidosw', '')} onChange={props.handleChange} />


      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW2()}><b>?</b></button>
</span> */ }
     
       </div> 

       </div>


       <div class="col-sm-3">
      <center><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mayor Puntaje de Equipos en Cuestión </label> </center>
      <div class="input-group">
      <div style={{marginTop:"8px"}}> <p>8) &nbsp;</p> </div><input type="number" name="mayor_puntaje" class="form-control" value={props.getState('mayor_puntaje', '')} onChange={props.handleChange} />


      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW2()}><b>?</b></button>
</span> */ }
     
       </div> 

       </div>



       <div class="col-sm-3">
      <center><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mayor Gol de Diferencia de Equipos en Cuestión </label> </center>
      <div class="input-group">
      <div style={{marginTop:"8px"}}> <p>9) &nbsp;</p> </div><input type="number" name="gol_diferencia_cuestion" class="form-control" value={props.getState('gol_diferencia_cuestion', '')} onChange={props.handleChange} />


      {/*<span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" onClick={()=>abrirCerrarModalContraJuegoW2()}><b>?</b></button>
</span> */ }
     
       </div> 

       </div>



      <div class="col-sm-3">
            <br />
            <br />
      <button className="btn btn-success rounded-circle float-right"   onClick={props.next}><i class="fas fa-save fa-2x"></i></button>
      

     

     
      
      
      
      </div>
      
     

       </div>

      
    







      

    


  </form>
                  </div>
                 
                </div>
    
    
{/* puntos ganador*/}
<Modal isOpen={modalPuntosGanador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Ganador Del Juego.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPuntosGanador()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* empate juego*/}
<Modal isOpen={modalEmpateJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos Por Empate De Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Cada Equipo por Empate Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalEmpateJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* perdedor juego*/}
<Modal isOpen={modalPerdedorJuego}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Perdedor Del Juego</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados Al Equipo Perdedor Del Juego.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalPerdedorJuego()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* ganador juego W*/}
<Modal isOpen={modalGanadorJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Otorgados A Favor Al Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGanadorJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* goles juego W*/}
<Modal isOpen={modalGolesJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles a Favor al Ganador del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Otorgados A Favor Del Equipo Ganador Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalGolesJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>



{/* contra juego W*/}
<Modal isOpen={modalContraJuegoW}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Puntos En Contra Por Perder el Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Puntos Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* contra juego W2*/}
<Modal isOpen={modalContraJuegoW2}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Goles En Contra al Perdedor del Juego por W</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Los Goles Restados (o) En Contra Al Equipo Perdedor Del Juego Por W.</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalContraJuegoW2()}>Aceptar</button>
 </ModalFooter>
</Modal>
    
          </div>
    
 


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
        
}
 }

export default Criterios_desempate