const Row = (props) => {
  const { onChange, onRemove, nombre, index } = props;
  console.log(props);
  return (
    <div>
      <h6> </h6>
      <br/>
      
      
      <input
      disabled
        value={nombre  * 1 + 1 +  " Posición"}
        onChange={(e) => onChange(index, e.target.value)} 
        placeholder="Decrementar"
      />


      
    </div>
  );
};
export default Row;