import React, {useContext} from 'react'

import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

 function Empresajuridica1(props) {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {

    return (
        
        
        <div className="content-wrapper">
    
        <div className="content-header">
          <div className="container-fluid">
            
              <div className="col-sm-12">
    
    
    
              <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">PERSONA JURIDICA</h3>
                  </div>
                
                  <div class="card-body">
                    <form>
                      <div class="row">
                      <label>En que lugar Prestas tus Servicios</label>
    
                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                <select name="pais" class="form-control" value={props.getState('pais', '')} onChange={props.handleChange} >
                   <option value='no' selected>Pais</option>
                    <option value="Colombia">Colombia</option>
                 </select>     
                          </div>
                        </div>
    
    
                        <div class="col-sm-2">
                          <div class="form-group">
                            
                          <select name="departamento" class="form-control" value={props.getState('departamento', '')} onChange={props.handleChange}>
         <option value='no' selected>Departamento</option>
                        <option value="Tolima">Tolima</option>
                        
                      </select>  
    
                          </div>
                        </div>
                      
                      
                        <div class="col-sm-2">
                          
                          <div class="form-group">
                          <select name="ciudad" class="form-control" value={props.getState('ciudad', '')} onChange={props.handleChange}>
         <option value='no' selected>Ciudad</option>
                        <option value="Ibague">Ibague</option>
                        
                      </select>  
                          </div>
                        </div>
    
    
                        <label>Datos de representante legal</label>
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
    
                       <input name="name" type="text" class="form-control is-valid" id="inputSuccess" placeholder="Nombres" value={props.getState('name', '')} onChange={props.handleChange} />
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-3">
    
                        <div class="form-group">

                        <input name="surname" type="text" class="form-control is-valid" id="inputSuccess" placeholder="Apellidos" value={props.getState('surname', '')} onChange={props.handleChange} />
                        
                      
                      </div>
                      </div>

                      
                      
                      <div class="col-sm-2">
                        <select name="tdocp" class="form-control" value={props.getState('tdocp', '')} onChange={props.handleChange}>
                   <option value='no' selected>T.doc</option>
                    <option value="CEDULA">CEDULA</option>
                    <option value="DNI">DNI</option>
                    <option value="Tarjeta de extrangeria">Tarjeta de extrangeria</option>
                 </select>     
    
                        </div>


                        <div class="col-sm-3">
                        <input type="number" name="documento" class="form-control is-valid" id="inputSuccess" placeholder="N° documento" value={props.getState('documento', '')} onChange={props.handleChange} />
    
                        </div>
                        </div>
    
    
                        <div class="row">
                        
                        <label>Datos de la empresa</label>
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
                        <input type="text" name="empresa" class="form-control is-valid" id="inputSuccess" placeholder="Nombre de la empresa" value={props.getState('empresa', '')} onChange={props.handleChange} />
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-3">
    
                        <div class="form-group">
                        
                        <input type="text" name="comercial" class="form-control is-valid" id="inputSuccess" placeholder="Nombre comercial" value={props.getState('comercial', '')} onChange={props.handleChange} />
                      </div>
                      </div>

                      
                      
                      <div class="col-sm-2">
                        <select name="tdoce" class="form-control" value={props.getState('tdoce', '')} onChange={props.handleChange}>
                   <option value='no' selected>T.doc</option>
                    <option value="NIT">NIT</option>
                    <option value="DNI">DNI</option>
                 </select>     
    
                        </div>


                        <div class="col-sm-2">
                        <input type="number" name="identificacion" class="form-control is-valid" id="inputSuccess" placeholder="N° identificación"  value={props.getState('identificacion', '')} onChange={props.handleChange} />
    
                        </div>

                        <div class="col-sm-2">
                        <input type="number" name="cv" class="form-control" id="inputSuccess" placeholder="CV"  value={props.getState('cv', '')} onChange={props.handleChange} />
    
                        </div>
       
                        
    
                       
                      </div>
    
                      
    
    
                        <div class="row">
    
                        <div class="col-sm-5">
                  
                        <div class="form-group">
    
                        <input type="text" name="establecimiento" class="form-control is-valid" id="inputSuccess" placeholder="Nombre comercial o de establecimiento" value={props.getState('establecimiento', '')} onChange={props.handleChange} />
                          </div>
                        </div>
    
    
                  <div class="col-sm-4">

                      <label for="formFile" class="form-label">Adjuntar documento RUT</label>
                      <input class="form-control" type="file" id="formFile" />
                  
                                      
                  </div>
    
    
                        </div>
    
    
                        <div class="row">
                      <label>Datos Para Validar la Cuenta, Recuperar contraseñas y Contactarte.</label>
    
                        <div class="col-sm-4">
                  
                        <div class="form-group">
    
                        <input type="number" name="celularp" class="form-control is-valid" id="inputSuccess" placeholder="N° celular principal" value={props.getState('celularp', '')} onChange={props.handleChange} />
                          </div>
                        </div>
    
    
                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <input type="email" name="emailp" class="form-control is-valid" id="inputSuccess" placeholder="Email" value={props.getState('emailp', '')} onChange={props.handleChange} />
                    </div>
                  </div>
    
    
                        </div>
    
    
                        <div class="row">
                      
                  <div class="col-sm-3">
                  
                 
                      <input class="form-control" type="file" id="formFile" />
                      <label>Adjuntar RUT</label>
                  </div>


                  <div class="col-sm-4">
                  
                  <input class="form-control" type="file" id="formFile" />
                      <label>Adjuntar Camara de comercio</label>
                  </div>

                  <div class="col-sm-5">
                  
                  <div class="form-group">
    
                  <input type="text" name="direccion" class="form-control is-valid" id="inputSuccess" placeholder="Dirección" value={props.getState('direccion', '')} onChange={props.handleChange} />
                    </div>
                  </div>
    
    
                        </div>
    
    
    
                        <div class="row">
                     
    
                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="ciudada" class="form-control is-valid" id="inputSuccess" placeholder="Ciudad" value={props.getState('ciudada', '')} onChange={props.handleChange} />
                          </div>
                        </div>


                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="codigoa" class="form-control is-valid" id="inputSuccess" placeholder="Codigo postal" value={props.getState('codigoa', '')} onChange={props.handleChange}/>
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="number" name="telefono1a" class="form-control is-valid" id="inputSuccess" placeholder="N° teléfono 1" value={props.getState('telefono1a', '')} onChange={props.handleChange} />
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="number" name="telefono2a" class="form-control is-valid" id="inputSuccess" placeholder="N° teléfono 2" value={props.getState('telefono2a', '')} onChange={props.handleChange}/>
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="email1a" class="form-control is-valid" id="inputSuccess" placeholder="Email 1" value={props.getState('email1a', '')} onChange={props.handleChange} />
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="email2a" class="form-control is-valid" id="inputSuccess" placeholder="Email 2" value={props.getState('email2a', '')} onChange={props.handleChange} />
                          </div>
                        </div>
    
    
                  
    
    
                        </div>
    
    
                     

                        <button className="btn btn-warning"  onClick={props.next}>Siguiente <i class="fas fa-arrow-right"></i></button>
                        <br /><br />
                      
                        
    
                      
    
                      
    
    
                    </form>
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
          </div>
          </div>
          </div>


    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
        /*<div>
            <label>FORMULARIO1</label>
     <p> <input name="name" value={props.getState('name', '')} onChange={props.handleChange} /></p>
    <p><input name="surname" value={props.getState('surname', '')} onChange={props.handleChange} /></p>
    <button onClick={props.prev}>Previous</button>
<button onClick={props.next}>Next</button>
        </div>*/
}
 }

export default Empresajuridica1