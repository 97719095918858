import React,{useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

const categorias = [
  {
    "nombre" : "Tolima",
    "articulos" : ["Alvarado" , "Anzoátegui", "Cajamarca", "Coello", "Espinal", "Flandes", "Ibague", "Piedras", "Rovira", "San Luis" ,
  "Valle De San Juan", "Alpujarra", "Dolores", "Guamo", "Prado", "Purificación", "Saldaña", "Suárez", "Casabianca", "Herveo", "Lerida",
  "Libano", "Murillo", "Santa Isabel", "Venadillo", "Villahermosa"]


  
  
  },
 
 ]

function Ubicacion() {
  

  const baseUrlAd="https://www.deportes.controlsas.com/apiPlooy/ubicacion/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id_ubicacion:'',
    pais:'Colombia',
    departamento:'Tolima',
    ciudad:''
  });

  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

  const peticionPost = async() =>{
    var f = new FormData();
    f.append("pais", frameworkSeleccionado.pais);
    f.append("departamento", frameworkSeleccionado.departamento);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }
 

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrlAd,f, {params: {id_ubicacion: frameworkSeleccionado.id_ubicacion}})
    .then(response=>{
     setData(data.filter(framework=>framework.id_ubicacion!==frameworkSeleccionado.id_ubicacion));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

 /* const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }*/


  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEliminar():
    abrirCerrarModalEliminar()
  }




  useEffect(()=>{
    peticionGet();

  },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id_ubicacion"
      
     // type:"numeric"
    },
    {
      title:'PAIS',
      field:'pais'
    },
    {
      title:"DEPARTAMENTO",
      field: "departamento"
    },
    {
      title:"CIUDAD",
      field: "ciudad"
    },
    
    
  ];


    return (

        <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h3 className="m-0"><button className="btn btn-secondary" onClick={()=>abrirCerrarModalInsertar()}><img src="dist/img/colombia.png" width="45px" />Agregar ubicación</button></h3>
           

          </div>
          <div className="col-sm-6">
            
          </div>
        </div>
      </div>
    </div>

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

         
            <div>
              
            <MaterialTable

      
     
columns={columnas}
data={data}
title="UBICACIÓN"
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[
    
  {
   icon : 'delete',
   tooltip: 'Eliminar ubicación',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }
]}
options={{
  actionsColumnIndex: -1
}}
options={{
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
 },

 headerStyle: { backgroundColor: "rgb(175 174 173)",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white" }
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>

<Modal isOpen={modalInsertar}>
 <ModalHeader><img src="dist/img/gps.png" width="50px" /> AGREGAR UBICACIÓN</ModalHeader>
 <ModalBody>
   <div className="form-group">
     <label>Pais:</label>
     <br/>
     
     <input type="text" className="form-control" placeholder="Colombia" name="pais" onChange={handleChange}  readonly="readonly" />
     <br/>
     <label>Departamento:</label>
     <br/>
     <select name=""  className="form-control" onClick={handlerCargarArticulos} onChange={handleChange}>
              <option value={-1}>Seleccione una departamento: </option>
              {
                categorias.map((item,i)=>(
                  <option key={"categoria"+i} value={i}> {item.nombre}</option>
                ))
              }
             </select> 
     
     
     <br/>
     <label>Ciudad:</label>
     <br/>
     <select name="ciudad" className="form-control"  onChange={handleChange}>
     <option>Seleccione una ciudad: </option>
              {
                idArticulos > -1 && 
                (
                  categorias[idArticulos].articulos.map((item,i)=>(
                    <option key={"articulo"+i} value={item.articulos}>{item}</option>
                  ))
                )
              }
            </select>

     
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que deseas eliminar el pais {frameworkSeleccionado && frameworkSeleccionado.pais}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>

            </div>
       
          </div>

         
        </div>
      

        

      </div>
    </section>

    
    
  </div>
    )
}

export default Ubicacion
