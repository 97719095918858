import React, {useContext,useState} from 'react'
import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

function Jugadores_autorizados_guardar(props) {

    const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            deporte:'',
            email:'',
            password:'',
            pais:'',
            departamento:'',
            ciudad:'',
            tipo_usuario:'empresaj',
            apellidos:'',
            tdoc:'',
            documento:'',
            fechanacimiento:'',
            genero:'',
            telefono:'',
            comercio_natural:'',
            nacionalidad:'',
            direccion:'',
            ciudad_nacionalidad:'',
            emailc:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
        
            
              <div className="col-sm-12">
    
    
    
              <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">JUGADORES AUTORIZADOS EN CAMPO</h3>
                  </div>
                
                  <div class="card-body">
                  <form onSubmit={submitForm}>
                      <div class="row">
                     
    
                        <div class="col-sm-9">

                        <h6><b>Criterio de desempate.</b></h6> 

                          
            <div class="input-group " style={{top:"10px"}}>
      

      <label>♦ <font size="2">Cuantos Jugadores Mínimo en Cancha debe Tener Equipo Para Poder Participar: </font> &nbsp; </label><div class="col-sm-1" style={{top:"-5px"}}> <input name="puntos_ganador" class="form-control" style={{textAlign:"center"}}  value={props.getState('puntos_ganador', '')} onChange={onChangeValue} disabled />
                
     

</div>  

</div>


<div class="input-group " style={{top:"10px"}}>
<label>♦ <font size="2">Si Un Equipo se Presenta con  </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px", right:"1px"}}> <input name="puntos_empate" class="form-control" style={{textAlign:"center"}}  value={props.getState('puntos_empate', '')} onChange={onChangeValue} disabled />
                          

</div>  
<label><font size="2"> &nbsp;&nbsp;Pierde Por W  </font></label>
</div> 


<div class="input-group " style={{top:"10px"}}>
<label>♦ <font size="2">Si Un Equipo En Competencia, Durante el Juego se Queda Con </font>  </label><div class="col-sm-1" style={{top:"-5px", right:"-5px"}}> <input type="number" name="puntos_perdedor" class="form-control" style={{textAlign:"center"}} value={props.getState('puntos_perdedor', '')} onChange={onChangeValue} disabled />
                          
    

</div>  

<label><font size="2"> &nbsp;&nbsp;Jugadores Pierde Por W </font></label>
</div> 


<div class="input-group " style={{top:"10px"}}>
<label>♦ <font size="2">Cuantos Cambios de Jugadores Puede Hacer Cada Equipo Durante el Juego:  </font> &nbsp; </label><div class="col-sm-1" style={{top:"-2px", right:"4px"}}><input type="number" name="puntos_ganadosw" class="form-control" style={{textAlign:"center"}} value={props.getState('puntos_ganadosw', '')} onChange={onChangeValue} disabled />
                        
    

</div> 

<label><font size="2"> &nbsp;&nbsp;Sin Restricción o Libre </font></label>

</div> 

<br />


                  
                      
                        </div>
    
                        
                      
                      
                    
               
                        </div>
    
    
                        <div class="row">
                        
                    
                     
    
                       
                      </div>
    
                      
    
    
    
    
                       
    

                        <div class="col-sm-12">

                        
                       
{/*<p><b>{errorMsg}{successMsg}</b></p>*/}

   





 </div>
    
     

                        <button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                        <button className="btn btn-success rounded-circle float-right"><i class="fas fa-save fa-2x"></i></button>
                        
                        <br /><br />
                      
                        
    
                      
    
                      
    
    
                    </form>
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
         


    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Jugadores_autorizados_guardar

