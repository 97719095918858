import React, {useContext,useState} from 'react'
import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

function Puntos_juegos_guardar(props) {

    const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            deporte:'',
            email:'',
            password:'',
            pais:'',
            departamento:'',
            ciudad:'',
            tipo_usuario:'empresaj',
            apellidos:'',
            tdoc:'',
            documento:'',
            fechanacimiento:'',
            genero:'',
            telefono:'',
            comercio_natural:'',
            nacionalidad:'',
            direccion:'',
            ciudad_nacionalidad:'',
            emailc:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
        
            
              <div className="col-sm-12">
    
    
    
              <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">PUNTOS DE JUEGO - PUNTOS POR W</h3>
                  </div>
                
                  <div class="card-body">
                  <form onSubmit={submitForm}>
                      <div class="row">
                     
    
                        <div class="col-sm-4">

                        <h6><b>Puntos Por Juego Para Los Equipos</b></h6> 

                          
            <div class="input-group " style={{top:"10px"}}>
      

      <label><font size="2">Puntos Para el Equipo Ganador: </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px"}}> <select name="puntos_ganador" class="form-control rounded-circle" style={{textAlign:"center"}}  value={props.getState('puntos_ganador', '')} onChange={onChangeValue} disabled>
                <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
                 </select>
     

</div>  

</div>


<div class="input-group " style={{top:"10px"}}>
<label><font size="2">Puntos Por Juego Empatado: </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px", right:"-17px"}}> <select name="puntos_empate" class="form-control rounded-circle" style={{textAlign:"center"}}  value={props.getState('puntos_empate', '')} onChange={onChangeValue} disabled>
                          <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
                        
                      </select> 
    

</div>  
</div> 


<div class="input-group " style={{top:"10px"}}>
<label><font size="2">Puntos Por juego Perdido: </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px", right:"-35px"}}> <select name="puntos_perdedor" class="form-control rounded-circle" style={{textAlign:"center"}} value={props.getState('puntos_perdedor', '')} onChange={onChangeValue} disabled>
                          <option value='no' selected >Puntos al perdedor</option>
      <option value="-3">-3</option>
      <option value="-2">-2</option>
      <option value="-1">-1</option>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
                        
                      </select>  
    

</div>  
</div> 


<br />



                  
                      
                        </div>
    
                        <div class="col-sm-4">
                        <h6><b>Puntos y Goles Por W:</b></h6> 
                        <div class="input-group " style={{top:"10px"}}>
<label><font size="2">Puntos Ganados Por W: </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px", right:"-6px"}}> <select name="puntos_ganadosw" class="form-control rounded-circle" style={{textAlign:"center"}} value={props.getState('puntos_ganadosw', '')} onChange={onChangeValue} disabled>
                        <option value='no' selected >Puntos ganados</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
                          
                        </select>  
    

</div>  
</div> 




<div class="input-group " style={{top:"10px"}}>
<label><font size="2">Goles Ganados Por W: </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px", right:"-14px"}}> <select name="goles_ganadosw" class="form-control rounded-circle" style={{textAlign:"center"}} value={props.getState('goles_ganadosw', '')} onChange={onChangeValue} disabled>
                        <option value='no' selected >Goles a favor</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
                          
                        </select>  
    

</div>  
</div> 




<div class="input-group " style={{top:"10px"}}>
<label><font size="2" style={{color: "red"}}>Puntos Perdidos Por W:  </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px", right:"-6px"}}> <select name="puntos_perdidosw" class="form-control rounded-circle" style={{textAlign:"center", color:"red"}} value={props.getState('puntos_perdidosw', '')} onChange={onChangeValue} disabled>
                      <option value='no' selected >Puntos en contra</option>
      <option value="0">0</option>
      <option value="-1">-1</option>
      <option value="-2">-2</option>
      <option value="-3">-3</option>
      <option value="-4">-4</option>
      <option value="-5">-5</option>
                          
                        </select>  
    

</div>  
</div> 




<div class="input-group " style={{top:"10px"}}>
<label><font size="2" style={{color: "red"}}>Goles Perdidos Por W: </font> &nbsp; </label><div class="col-sm-2" style={{top:"-5px", right:"-14px"}}> <select name="goles_perdidosw" class="form-control rounded-circle" style={{textAlign:"center", color:"red"}} value={props.getState('goles_perdidosw', '')} onChange={onChangeValue} disabled>
                      <option value='no' selected >Goles en contra</option>
      <option value="0">0</option>
      <option value="-1">-1</option>
      <option value="-2">-2</option>
      <option value="-3">-3</option>
      <option value="-4">-4</option>
      <option value="-5">-5</option>
                          
                        </select>  
    

</div>  
</div> 




</div> 
                      
                      
                    
               
                        </div>
    
    
                        <div class="row">
                        
                    
                     
    
                       
                      </div>
    
                      
    
    
    
    
                       
    

                        <div class="col-sm-12">

                        
                       
{/*<p><b>{errorMsg}{successMsg}</b></p>*/}

   





 </div>
    
     

                        <button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                        <button className="btn btn-success rounded-circle float-right"><i class="fas fa-save fa-2x"></i></button>
                        
                        <br /><br />
                      
                        
    
                      
    
                      
    
    
                    </form>
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
         


    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Puntos_juegos_guardar

