import React, {useContext,useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

import LogoConfenalco from '../../img/confenalco.jpg';

import LogoBalon from '../../img/balon.png';

import Pdf from './AmigosDelPino2.pdf';

//logo
import LogoTorneo1 from '../../img/escudofull.png';

// Importing the Login & Register Componet
import Register from './Register'

import Foto from '../../img/foto.png';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
//import Torneo_pendiente5 from './Torneo_pendiente5';

//Agregar campo dinamico
const defaultState = {
  nombre: "",
};



//Agregar campo dinamico
function Row({ onChange, onRemove, datos_adicionales}) {
 
  return (
    <div>
      
      <div class="input-group">
      <input name="datos_adicionales"
        value={datos_adicionales}
        className="form-control" id="inputSuccess"
       
       /* style={{ "background-color": !input3 && "#D3F9FF" }}
        value={input3}
        onChange={({ target }) => setInput3(target.value)}*/
        placeholder="Datos adicionales"
      />
       
       <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" ><b>?</b></button>
                  </span>
     
       </div> 
   <br/>
     


    
    </div>
  );
}


 function Torneo_pendiente6(props) {

  //colores inputs y selects
  const [input, setInput] = useState();

  const [input2, setInput2] = useState();

// const [input3, setInput3] = useState();

const [select, setSelect] = useState();

const [select2, setSelect2] = useState();

const [select3, setSelect3] = useState();

const [select4, setSelect4] = useState();

const [select5, setSelect5] = useState();


 //agregar campo dinamico
 const [rows, setRows] = useState([defaultState]);

//Modal organizador
const [modalOrganizador, setModalOrganizador]=useState(false);

 //Modal
 const [modalNombreTorneo, setModalNombreTorneo]=useState(false);

 const [modalDatosAdicionales, setModalDatosAdicionales]=useState(false);

 

 //agregar campo dinamico
const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};


//Modal organizador

const abrirCerrarModalOrganizador=()=>{
  setModalOrganizador(!modalOrganizador);
}

//Modal

const abrirCerrarModalNombreTorneo=()=>{
  setModalNombreTorneo(!modalNombreTorneo);
}

const abrirCerrarModalDatosAdicionales=()=>{
  setModalDatosAdicionales(!modalDatosAdicionales);
}



 //agregar campo dinamico
const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  setRows(rows.concat(defaultState));
};
/*
 //agregar campo dinamico
const handleOnRemove = (index,e) => {
  e.preventDefault(); //esto previene que el form se mande.
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
*/

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {

    return (
        
     
             
      <div className="col-sm-12">
    
              <div class="card">
                  
              <div class="card-header text-center">
                    <h3 class="card-title"><img src={LogoConfenalco} width="45px" /><img src={LogoBalon} width="45px" /> <b>LOS AMIGOS DEL PINO </b>  - Colombia – Tolima – Ibagué – Futbol - F11 – G Natural – Masculino – Dici 2021 </h3>
                    
                    <br /><h3 class="card-title"> 
  </h3>
  
                  </div>  
                 
                
                  <div class="card-body">
                  <a href={Pdf} style={{textDecoration:"none"}} target = "_blank">Descargar <i class="fas fa-file-pdf" style={{color:"red"}}></i></a>
 
                      
                  <form>


<br/>
    <div class="row">

    <div class="col-sm-1">

    <div class="input-group">
      
    <h5>&nbsp;&nbsp;&nbsp;&nbsp;#</h5>
          
       </div> 

       </div> 
    
       <div class="col-sm-1">
     <div class="input-group">

     <div class="input-group">
      
      <h6>&nbsp;&nbsp;&nbsp;&nbsp;Foto</h6>
            
         </div> 
          
   
        </div>
      </div>


     <div class="col-sm-5">
     <div class="input-group">

    
     <div class="input-group">
      
      <h6>&nbsp;Nombres / ID PLOOY</h6>
            
         </div> 
          
        
    
   
        </div>
      </div>
    
    
      <div class="col-sm-1">
        
      <div class="input-group">
      <div class="input-group">
      
      <h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Posición</h6>
            
         </div> 
        </div>
      </div>


      <div class="col-sm-2">
        
      <div class="input-group">
      <div class="input-group">
      
      <h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Camiseta</h6>
            
         </div> 
        </div>
      </div>


      <div class="col-sm-2">
        
     
      <div class="input-group">
      
      <h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Edad</h6>
            
         </div> 
     
      </div>


     

   
      
      </div>

      <hr />

  



    <div class="row">

<div class="col-sm-1">

<div class="col-sm-8">
<div class="input-group">
  
<input 
   
 className="form-control" 
    
    placeholder="1"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
    
   
  />
      
   </div> 
  </div>



   </div> 


 <div class="col-sm-1">

 <div class="input-group">

<img src={Foto} width="80px" />

   </div>
 
  </div>


  <div class="col-sm-7">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="Guillermo solis"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      readOnly
     
    />
    </div>
  </div>


  <div class="col-sm-1">
                  
                  <div class="form-group">

          <select name="deporte" class="form-control" style={{fontSize:"13px"}} >
          <option value='no' selected>Volante</option>
          <option value="Arquero">Arquero</option>
          <option value="Central">Central</option>
          <option value="Lateral">Lateral</option>
          <option value="Volante">Volante</option>
          <option value="Delantero">Delantero</option>
           </select>     
                    </div>
                  </div>


  <div class="col-sm-2">
    
    <div class="col-sm-6">
  <div class="input-group">
  <input 
   
   className="form-control" 
      type="number"
      placeholder="6"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      
      
     
    />

<div class="col-sm-2">

<div class="col-sm-1">
<div class="input-group">
<input 
 
 className="form-control" 
    
    placeholder="80"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
   
  />
  </div>
  </div>
</div>

</div>

    </div>

    
  </div>


  


 

 

  </div>





  <div class="row">

<div class="col-sm-1">

<div class="col-sm-8">
<div class="input-group">
  
<input 
   
 className="form-control" 
    
    placeholder="2"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
    
   
  />
      
   </div> 
  </div>



   </div> 


 <div class="col-sm-1">

 <div class="input-group">

<img src={Foto} width="80px" />

   </div>
 
  </div>


  <div class="col-sm-7">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="Juan pablo angel"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      readOnly
     
    />
    </div>
  </div>


  <div class="col-sm-1">
                  
                  <div class="form-group">

          <select name="deporte" class="form-control" style={{fontSize:"13px"}} >
          <option value='no'>Volante</option>
          <option value="Arquero" selected>Arquero</option>
          <option value="Central">Central</option>
          <option value="Lateral">Lateral</option>
          <option value="Volante">Volante</option>
          <option value="Delantero">Delantero</option>
           </select>     
                    </div>
                  </div>


  <div class="col-sm-2">
    
    <div class="col-sm-6">
  <div class="input-group">
  <input 
   
   className="form-control" 
      type="number"
      placeholder="1"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      
      
     
    />

<div class="col-sm-2">

<div class="col-sm-1">
<div class="input-group">
<input 
 
 className="form-control" 
    
    placeholder="80"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
   
  />
  </div>
  </div>
</div>

</div>

    </div>

    
  </div>


  


 

 

  </div>



  <div class="row">

<div class="col-sm-1">

<div class="col-sm-8">
<div class="input-group">
  
<input 
   
 className="form-control" 
    
    placeholder="3"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
    
   
  />
      
   </div> 
  </div>



   </div> 


 <div class="col-sm-1">

 <div class="input-group">

<img src={Foto} width="80px" />

   </div>
 
  </div>


  <div class="col-sm-7">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="Pedro mejia"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      readOnly
     
    />
    </div>
  </div>


  <div class="col-sm-1">
                  
                  <div class="form-group">

          <select name="deporte" class="form-control" style={{fontSize:"13px"}} >
          <option value='no' >Volante</option>
          <option value="Arquero">Arquero</option>
          <option value="Central">Central</option>
          <option value="Lateral">Lateral</option>
          <option value="Volante">Volante</option>
          <option value="Delantero" selected>Delantero</option>
           </select>     
                    </div>
                  </div>


  <div class="col-sm-2">
    
    <div class="col-sm-6">
  <div class="input-group">
  <input 
   
   className="form-control" 
      type="number"
      placeholder="9"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      
      
     
    />

<div class="col-sm-2">

<div class="col-sm-1">
<div class="input-group">
<input 
 
 className="form-control" 
    
    placeholder="80"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
   
  />
  </div>
  </div>
</div>

</div>

    </div>

    
  </div>


  


 

 

  </div>




      <div class="row">

<div class="col-sm-1">

<div class="col-sm-8">
<div class="input-group">
  
<input 
   
 className="form-control" 
    
    placeholder="4"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
    
   
  />
      
   </div> 
  </div>



   </div> 


 <div class="col-sm-1">

 <div class="input-group">

<img src={Foto} width="80px" />

   </div>
 
  </div>


  <div class="col-sm-7">
    
  <div class="input-group">
  <input 
   
   className="form-control" 
      
      placeholder="Juan guillermo cuadrado"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      readOnly
     
    />
    </div>
  </div>


  <div class="col-sm-1">
                  
                  <div class="form-group">

          <select name="deporte" class="form-control" style={{fontSize:"13px"}} >
          <option value='no' >Volante</option>
          <option value="Arquero">Arquero</option>
          <option value="Central" selected>Central</option>
          <option value="Lateral">Lateral</option>
          <option value="Volante">Volante</option>
          <option value="Delantero">Delantero</option>
           </select>     
                    </div>
                  </div>


  <div class="col-sm-2">
    
    <div class="col-sm-6">
  <div class="input-group">
  <input 
   
   className="form-control" 
      type="number"
      placeholder="3"
      maxLength="50"
      style={{ "background-color": !input && "white" }}
      name="organizador"
      
      
     
    />

<div class="col-sm-2">

<div class="col-sm-1">
<div class="input-group">
<input 
 
 className="form-control" 
    
    placeholder="80"
    maxLength="50"
    style={{ "background-color": !input && "white" }}
    name="organizador"
    
    readOnly
   
  />
  </div>
  </div>
</div>

</div>

    </div>

    
  </div>


  


 

 

  </div>

      <div class="col-sm-12">
        
        <div class="input-group">
        <button className="btn btn-warning" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
        <button className="btn btn-success"   onClick={props.next} disabled>Guardar</button>
          </div>
        </div>


      <br />

         
<Modal isOpen={modalNombreTorneo}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Nombre del torneo</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Asigna Un Nombre al Torneo (No debe Contener Mas de 40 Caracteres)</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalNombreTorneo()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* organizador */}
<Modal isOpen={modalOrganizador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Organizador / Sucursal</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Sub Nombre o Sucursal, en el caso de que la empresa tenga un mismo torneo pero distintas sucursales
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalOrganizador()}>Aceptar</button>
 </ModalFooter>
</Modal>



<Modal isOpen={modalDatosAdicionales}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b> Datos adicionales </b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
   <h3><b>Para el caso de zonas geográficas no registradas en la base de datos.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalDatosAdicionales()}>Aceptar</button>
 </ModalFooter>
</Modal>

  </form>
                  </div>
                 
                </div>
    
    
                </div>

             

                
    
    
         
         

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
     
}
 }

export default Torneo_pendiente6