import React,{useState} from 'react'

function useToggles(init = {}) {
    const [states, setStates] = React.useState(init)
    return [
      states,
      key => event => setStates({...states, [key]: !Boolean(states[key])})
    ]
  }

function Pruebas3() {

    const [value2, setValue2] = useState("");

    const [checkboxes, toggle] = useToggles({
        uno: false,
        dos: false,
        tres: false
      })

  const handlerOnClick = (e) => {
    setValue2(e.target.value)
  }
  
  return (
    <div>
        <div className="col-sm-6">
        <div className="form-check">

        <input
            type="button"
            name="boton1"
            value="seleccionar"           
            onClick={handlerOnClick}
        /> 
        
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
          <input
            type="button"
            name="boton1"
            value="seleccionados"           
            onClick={handlerOnClick}
          /> 
        </div>
      </div>

      <div className="col-sm-7">
        {value2 === "seleccionar" && (
          <div className="card">
            <div className="card-body">
       

        <div>Div1<input type="checkbox" name="check_1"  checked={checkboxes.uno} onClick={toggle("uno")}/></div>
        <div>Div2<input type="checkbox" name="check_2"  checked={checkboxes.dos} onClick={toggle("dos")}/></div>
        <div>Div3<input type="checkbox" name="check_3"  checked={checkboxes.tres} onClick={toggle("tres")}/></div>
     
       <pre>{JSON.stringify(checkboxes)}</pre> 
            </div>
          </div>
        )}
      </div>

      <div className="col-sm-7">
        {value2 === "seleccionados" && (
          <div className="card">
            <div className="card-body">
              <h1>CHECK seleccionados</h1>

             { console.log(checkboxes.inStock)}
                {checkboxes.uno == true
                ?<div>Div1<input type="checkbox" name="check_1" /></div> : ""}
              
             {checkboxes.dos == true
                ?<div>Div2<input type="checkbox" name="check_2" /></div> : ""}

             {checkboxes.tres == true
                ?<div>Div3<input type="checkbox" name="check_3" /></div> : ""}
            </div>
          </div>
        )}
      </div>
      
    </div>
  )
}

export default Pruebas3