import React, {useContext,useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import Escudo6 from '../../img/escudo.jpg';

//Agregar campo dinamico
const defaultState = {
  nombre: "",
};



//Agregar campo dinamico
function Row({ onChange, onRemove, datos_adicionales}) {
 
  return (
    <div>
      
      <div class="input-group">
      <input name="datos_adicionales"
        value={datos_adicionales}
        className="form-control" id="inputSuccess"
       
       /* style={{ "background-color": !input3 && "#D3F9FF" }}
        value={input3}
        onChange={({ target }) => setInput3(target.value)}*/
        placeholder="Datos adicionales"
      />
       
       <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" ><b>?</b></button>
                  </span>
     
       </div> 
   <br/>
     


    
    </div>
  );
}


 function Grupos1(props) {

  //colores inputs y selects
  const [input, setInput] = useState();

  const [input2, setInput2] = useState();

// const [input3, setInput3] = useState();

const [select, setSelect] = useState();

const [select2, setSelect2] = useState();

const [select3, setSelect3] = useState();

const [select4, setSelect4] = useState();

const [select5, setSelect5] = useState();


 //agregar campo dinamico
 const [rows, setRows] = useState([defaultState]);

//Modal organizador
const [modalOrganizador, setModalOrganizador]=useState(false);

 //Modal
 const [modalNombreTorneo, setModalNombreTorneo]=useState(false);

 const [modalDatosAdicionales, setModalDatosAdicionales]=useState(false);

 

 //agregar campo dinamico
const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};


//Modal organizador

const abrirCerrarModalOrganizador=()=>{
  setModalOrganizador(!modalOrganizador);
}

//Modal

const abrirCerrarModalNombreTorneo=()=>{
  setModalNombreTorneo(!modalNombreTorneo);
}

const abrirCerrarModalDatosAdicionales=()=>{
  setModalDatosAdicionales(!modalDatosAdicionales);
}



 //agregar campo dinamico
const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  setRows(rows.concat(defaultState));
};
/*
 //agregar campo dinamico
const handleOnRemove = (index,e) => {
  e.preventDefault(); //esto previene que el form se mande.
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
*/

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {

    return (
        
     
             
      <div className="col-sm-12">
    
              <div class="card card-primary">
                

                  
                
                  <div class="card-body">

                      
                 <h6>Equipos Inscritos: 50 – Grupos: 10 – Equipos Por Grupo: 5
Clasificarán a Siguiente Fase: Los Mejores 2 Equipos De Cada Grupo / Los 2 Mejores Promedios de la Tercera Posición – El Mejor Promedio de la Cuarta Posición, Para Un Total de 23 Equipos.
</h6>
                      
                  <form>


<br/>

<div class="col-sm-3">

<div class="input-group " onClick={props.next} >
<h6> <font size="3"> Seleccionar todo</font></h6>&nbsp;  <input type="checkbox" style={{height:"25px", width:"25px", marginTop:"-3px"}}/>


</div> 

<br />

</div>
    <div class="row">

    <div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"40px", width:"40px", marginTop:"14px", marginRight:"11px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>
    

<div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"45px", width:"45px", marginTop:"30px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>
    
    
<div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"45px", width:"45px", marginTop:"30px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>

<div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"45px", width:"45px", marginTop:"30px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>



     
  
      </div>

<br />

<div class="row">

<div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"45px", width:"45px", marginTop:"30px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>


<div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"45px", width:"45px", marginTop:"30px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>


<div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"45px", width:"45px", marginTop:"30px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>

<div class="col-sm-3">

<div class="input-group border border-warning" onClick={props.next} style={{ borderRadius:"10px"}}>
<img src={Escudo6} width="50px" height="50px" />
<h6> <font size="3"> Los Amigos Del Pino</font></h6>  <input type="checkbox" style={{height:"45px", width:"45px", marginTop:"30px"}}/>
<div style={{paddingLeft:"80px"}}><font size="2"> C045454550</font> </div>


</div> 

</div>



 

  </div>

    <br />


    

<br/>



<button className="btn btn-success" disabled  onClick={props.next}>GUARDAR </button>

      

    
<Modal isOpen={modalNombreTorneo}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Nombre del torneo</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Asigna Un Nombre al Torneo (No debe Contener Mas de 40 Caracteres)</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalNombreTorneo()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* organizador */}
<Modal isOpen={modalOrganizador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Organizador / Sucursal</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Sub Nombre o Sucursal, en el caso de que la empresa tenga un mismo torneo pero distintas sucursales
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalOrganizador()}>Aceptar</button>
 </ModalFooter>
</Modal>



<Modal isOpen={modalDatosAdicionales}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b> Datos adicionales </b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
   <h3><b>Para el caso de zonas geográficas no registradas en la base de datos.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalDatosAdicionales()}>Aceptar</button>
 </ModalFooter>
</Modal>

  </form>
                  </div>
                 
                </div>
    
    
                </div>

             

                
    
    
         
         

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
     
}
 }

export default Grupos1