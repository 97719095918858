import React, {useContext,useState} from 'react'
import {MyContext} from '../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from './Register'

function Empresajuridicaresumen(props) {

    const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            email:'',
            password:'',
            pais:'',
            departamento:'',
            ciudad:'',
            tipo_usuario:'empresaj',
            apellidos:'',
            tdoc:'',
            documento:'',
            fechanacimiento:'',
            genero:'',
            telefono:'',
            comercio_natural:'',
            nacionalidad:'',
            direccion:'',
            ciudad_nacionalidad:'',
            emailc:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
        <div className="content-wrapper">
    
        <div className="content-header">
          <div className="container-fluid">
            
              <div className="col-sm-12">
    
    
    
              <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">PERSONA JURIDICA</h3>
                  </div>
                
                  <div class="card-body">
                  <form onSubmit={submitForm}>
                      <div class="row">
                      <label>En que lugar Prestas tus Servicios</label>
    
                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                <select name="pais" class="form-control" value={props.getState('pais', '')} onChange={onChangeValue} disabled>
                   <option value='no' selected>Pais</option>
                    <option value="Colombia">Colombia</option>
                 </select>     
                          </div>
                        </div>
    
    
                        <div class="col-sm-2">
                          <div class="form-group">
                            
                          <select name="departamento" class="form-control" value={props.getState('departamento', '')} onChange={onChangeValue} disabled>
         <option value='no' selected>Departamento</option>
                        <option value="Tolima">Tolima</option>
                        
                      </select>  
    
                          </div>
                        </div>
                      
                      
                        <div class="col-sm-2">
                          
                          <div class="form-group" >
                          <select name="ciudad" class="form-control" value={props.getState('ciudad', '')} onChange={onChangeValue} disabled >
         <option value='no' selected>Ciudad</option>
                        <option value="Ibague">Ibague</option>
                        
                      </select>  
                          </div>
                        </div>
    
    
                        <label>Datos de representante legal</label>
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
    
                       <input name="name" type="text" class="form-control" id="inputSuccess" placeholder="Nombres" value={props.getState('name', '')} onChange={onChangeValue} readOnly/>
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-3">
    
                        <div class="form-group">

                        <input name="surname" type="text" class="form-control" id="inputSuccess" placeholder="Apellidos" value={props.getState('surname', '')} onChange={onChangeValue} readOnly/>
                        
                      
                      </div>
                      </div>

                      
                      
                      <div class="col-sm-2">
                        <select name="tdocp" class="form-control" value={props.getState('tdocp', '')} onChange={onChangeValue} disabled>
                   <option value='no' selected>T.doc</option>
                    <option value="CEDULA">CEDULA</option>
                    <option value="DNI">DNI</option>
                    <option value="Tarjeta de extrangeria">Tarjeta de extrangeria</option>
                 </select>     
    
                        </div>


                        <div class="col-sm-3">
                        <input type="number" name="documento" class="form-control" id="inputSuccess" placeholder="N° documento" value={props.getState('documento', '')} onChange={onChangeValue} readOnly/>
    
                        </div>
                        </div>
    
    
                        <div class="row">
                        
                        <label>Datos de la empresa</label>
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
                        <input type="text" name="empresa" class="form-control" id="inputSuccess" placeholder="Nombre de la empresa" value={props.getState('empresa', '')} onChange={onChangeValue} readOnly />
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-3">
    
                        <div class="form-group">
                        
                        <input type="text" name="comercial" class="form-control" id="inputSuccess" placeholder="Nombre comercial" value={props.getState('comercial', '')} onChange={onChangeValue} readOnly/>
                      </div>
                      </div>

                      
                      
                      <div class="col-sm-2" >
                        <select name="tdoce" class="form-control" value={props.getState('tdoce', '')} onChange={props.handleChange} disabled >
                   <option value='no' selected>T.doc</option>
                    <option value="NIT">NIT</option>
                    <option value="DNI">DNI</option>
                 </select>     
    
                        </div>


                        <div class="col-sm-3">
                        <input type="number" name="identificacion" class="form-control" id="inputSuccess" placeholder="N° identificación" value={props.getState('identificacion', '')} onChange={onChangeValue} readOnly />
    
                        </div>

                        <div class="col-sm-1">
                        <input type="number"  name="cv" class="form-control" id="inputSuccess" placeholder="CV" value={props.getState('cv', '')} onChange={onChangeValue} readOnly/>
    
                        </div>
       
                        
    
                       
                      </div>
    
                      
    
    
                        <div class="row">
    
                        <div class="col-sm-5">
                  
                        <div class="form-group">
    
                        <input type="text" name="establecimiento" class="form-control" id="inputSuccess" placeholder="Nombre comercial o de establecimiento" value={props.getState('establecimiento', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>
    
    
                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <div class="input-group">
                          <div class="custom-file">
                            <input type="file" name="rut" class="custom-file-input" id="exampleInputFile" value={props.getState('rut', '')} onChange={onChangeValue} disabled />
                            <label class="custom-file-label" for="exampleInputFile">Adjuntar documento RUT</label>
                          </div>
                          <div class="input-group-append">
                           
                          </div>
                        </div>
                    </div>
                  </div>
    
    
                        </div>
    
    
                        <div class="row">
                      <label>Datos Para Validar la Cuenta, Recuperar contraseñas y Contactarte.</label>
    
                        <div class="col-sm-4">
                  
                        <div class="form-group">
    
                        <input type="number" name="celularp" class="form-control" id="inputSuccess" placeholder="N° celular principal" value={props.getState('celularp', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>
    
    
                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <input type="email" name="emailp" class="form-control" id="inputSuccess" placeholder="Email" value={props.getState('emailp', '')} onChange={onChangeValue} readOnly />
                    </div>
                  </div>
    
    
                        </div>
    
    
                        <div class="row">
                      
                  <div class="col-sm-3">
                  
                  <div class="form-group">
    
                  <div class="input-group">
                          <div class="custom-file">
                            <input type="file" name="arut" class="custom-file-input" id="exampleInputFile" value={props.getState('arut', '')} onChange={onChangeValue} disabled />
                            <label class="custom-file-label" for="exampleInputFile">Adjuntar RUT</label>
                          </div>
                          <div class="input-group-append">
                          </div>
                        </div>
                    </div>
                  </div>


                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <div class="input-group">
                          <div class="custom-file">
                            <input type="file" name="acomercio" class="custom-file-input" id="exampleInputFile" value={props.getState('acomercio', '')} onChange={onChangeValue} disabled />
                            <label class="custom-file-label" for="exampleInputFile">Adjuntar Cámara de Comercio</label>
                          </div>
                          <div class="input-group-append">
                          </div>
                        </div>
                    </div>
                  </div>

                  <div class="col-sm-5">
                  
                  <div class="form-group">
    
                  <input type="text" name="direccion" class="form-control" id="inputSuccess" placeholder="Dirección" value={props.getState('direccion', '')} onChange={onChangeValue} readOnly />
                    </div>
                  </div>
    
    
                        </div>
    
    
    
                        <div class="row">
                     
    
                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="ciudada" class="form-control" id="inputSuccess" placeholder="Ciudad"  value={props.getState('ciudada', '')} onChange={onChangeValue} readOnly/>
                          </div>
                        </div>


                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="codigoa" class="form-control" id="inputSuccess" placeholder="Codigo postal" value={props.getState('codigoa', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="number" name="telefono1a" class="form-control" id="inputSuccess" placeholder="N° teléfono 1" value={props.getState('telefono1a', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="number" name="telefono2a" class="form-control" id="inputSuccess" placeholder="N° teléfono 2" value={props.getState('telefono2a', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="email1a" class="form-control" id="inputSuccess" placeholder="Email 1" value={props.getState('email1a', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="text" name="email2a" class="form-control" id="inputSuccess" placeholder="Email 2" value={props.getState('email2a', '')} onChange={onChangeValue} readOnly/>
                          </div>
                        </div>
    
    
                  
    
    
                        </div>

                        <div class="row">
                      <label>Datos De la Persona De Contacto, Administrador de la APP.</label>
    
                      
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
    
                       <input name="email" type="text" class="form-control" id="inputSuccess" placeholder="Nombres" value={props.getState('email', '')} onChange={onChangeValue} readOnly />
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-3">
    
                        <div class="form-group">

                        <input name="phone" type="text" class="form-control" id="inputSuccess" placeholder="Apellidos" value={props.getState('phone', '')} onChange={onChangeValue} readOnly />
                        
                      
                      </div>
                      </div>

                      
                


                        <div class="col-sm-3">
                       
                        <input type="date" name="fechanac" class="form-control" id="inputSuccess" value={props.getState('fechanac', '')} onChange={onChangeValue} readOnly/> <h6>Fecha de nacimiento</h6>
    
                        </div>
                        </div>
    
    
                        <div class="row">
                        
                        <label>Datos de Contacto</label>
                        <div class="col-sm-3">
                        
    
                        <div class="form-group">
                        
                        <input type="number" name="celularc" class="form-control" id="inputSuccess" placeholder="N° celular"  value={props.getState('celularc', '')} onChange={onChangeValue} readOnly/>
                      </div>
                      </div>
    

      
                     
                      <div class="col-sm-4">
    
                        <div class="form-group">
                        
                        <input type="email" name="email" class="form-control" id="inputSuccess" placeholder="Email" value={props.getState('email', '')} onChange={onChangeValue} readOnly />
                        
                        
                      </div>
                      </div>

                      
                      
                        
    
                       
                      </div>
    
                      
    
    
                        <div class="row">

                            <label>N° Celular – Email Asociados a la Cuenta PLOOY</label>

                            <div class="col-sm-5">
                  
                        <div class="form-group">
    
                       <p>Datos Únicos Asociados a  tu Cuenta, Autorizados para Validar y Recuperar contraseñas PLOOY.</p>
                          </div>
                        </div>

    
                        <div class="col-sm-1">
                  
                        <div class="form-group">
    
                        <input type="number" name="indic" class="form-control" id="inputSuccess" placeholder="+57" value={props.getState('indic', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>

                        <div class="col-sm-2">
                  
                        <div class="form-group">
    
                        <input type="number" name="celulard" class="form-control" id="inputSuccess" placeholder="N° celular" value={props.getState('celulard', '')} onChange={onChangeValue} readOnly />
                          </div>
                        </div>

                        <div class="col-sm-4">
                  
                        <div class="form-group">
    
                        <input type="email" name="emaile" class="form-control" id="inputSuccess" placeholder="Email" value={props.getState('emaile', '')} onChange={onChangeValue} readOnly/>
                          </div>
                        </div>
    
    
                
    
    
                        </div>
    
    
                        <div class="row">
                     
    
                        <div class="col-sm-4">
                  
                        <div class="form-group">
                        <p><b>Crear Contraseña</b> Mínimo 6 Caracteres, 1 Letra Mayúscula, 1 Numero</p>
                        
                          </div>
                        </div>
    
    
                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <input type="password" name="password" class="form-control" id="inputSuccess" placeholder="Contraseña" value={props.getState('password', '')} onChange={onChangeValue} readOnly/>
                    </div>
                  </div>

                  <div class="col-sm-4">
                  
                  <div class="form-group">
    
                  <input type="password" name="contrasenan" class="form-control" id="inputSuccess" placeholder="Repetir contraseña" value={props.getState('contrasenan', '')} onChange={onChangeValue} readOnly />
                    </div>
                  </div>
    
    
                        </div>

                        <div class="col-sm-12">

                        
                       
<p><b>{errorMsg}{successMsg}</b></p>

   





 </div>
    
     

                        <button className="btn btn-primary" onClick={props.prev}><i class="fas fa-arrow-left"></i> Volver</button>&nbsp;
                        <button className="btn btn-success" type="submit"> <i class="far fa-save"></i> Guardar</button>
                        <br /><br />
                      
                        
    
                      
    
                      
    
    
                    </form>
                  </div>
                 
                </div>
    
    
                
    
          </div>
    
          </div>
          </div>
          </div>


    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Empresajuridicaresumen



  
       /* <div>
          <label>FORMULARIO FINAL</label>
<p>Name: {props.state.name}</p>
<p>Surname: {props.state.surname}</p>
<p>Email: {props.state.email}</p>
<p>Phone: {props.state.phone}</p>
<button onClick={props.prev}>Previous</button>
<button onClick={props.next}>Next</button>
        </div>*/
