import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

// Importing the Context Provider & Home Component
import MyContextProvider from './contexts/MyContext';
import Home from './components/Home';

import Ubicacion from './components/pagina/Ubicacion';

import Deporte from './components/pagina/Deporte';
import Subdeporte from './components/pagina/Subdeporte';

import Modulos from './components/pagina/Modulos';

import Administrador from './components/pagina/Administrador';

import Empresa from './components/pagina/Empresa';

import Register from './components/pagina/Register';

import Empresajuridica from './components/pagina/Empresajuridica';

import Ver_empresas from './components/pagina/Ver_empresas';
import Crear_torneo from './components/pagina/Crear_torneo';

import Puntos_de_juegos from './components/pagina/Puntos_de_juegos';

import Esquema_torneo from './components/pagina/Esquema_torneo';

import Puntos_juegos_guardar from './components/pagina/Puntos_juegos_guardar';

import Pruebas from './components/pagina/Pruebas';

import Pruebas2 from './components/pagina/Pruebas2';




import Torneo_pendiente from './components/pagina/Torneo_pendiente';

import Torneos_activos from './components/pagina/Torneos_activos';

import Torneos_finalizados from './components/pagina/Torneos_finalizados';

import Grupos from './components/pagina/Grupos';

import Torneo_activo7 from './components/pagina/Torneo_activo7';



/* borrar */

import Index_auditar_autogol from './components/pagina/auditar_autogol/Index_auditar_autogol';
import Index_auditar_asistencia from './components/pagina/auditar_asistencia/Index_auditar_asistencia';
import Index_auditar_tarjetas from './components/pagina/auditar_tarjetas/Index_auditar_tarjetas';
import Index_auditar_cambio from './components/pagina/auditar_cambio/Index_auditar_cambio';

import Deportistas from './components/pagina/Deportistas';
import Ver_deportistas from './components/pagina/Ver_deportistas';

//import Rutes from './Rutes';


function App(props) {
  return (
   <MyContextProvider>
      <Router>
      <Home/>
      

       <Switch>
           <Route path={'/Ubicacion'} component = {Ubicacion}></Route>
           <Route path={'/Deporte'} component = {Deporte}></Route>
           <Route path={'/Subdeporte'} component = {Subdeporte}></Route>
           <Route path={'/Modulos'} component = {Modulos}></Route>
           <Route path={'/Administrador'} component = {Administrador}></Route>
           <Route path={'/Crear_torneo'} component = {Crear_torneo}></Route>
           <Route path={'/Puntos_de_juegos'} component = {Puntos_de_juegos}></Route>
           <Route path={'/Esquema_torneo'} component = {Esquema_torneo}></Route>
           <Route path={'/Puntos_juegos_guardar'} component = {Puntos_juegos_guardar}></Route>
           <Route path={'/Torneo_activo7'} component = {Torneo_activo7}></Route>
           <Route path={'/Torneos_finalizados'} component = {Torneos_finalizados}></Route>

            {/* borrar */}

            <Route path={'/Index_auditar_autogol'} component = {Index_auditar_autogol}></Route>
            <Route path={'/Index_auditar_asistencia'} component = {Index_auditar_asistencia}></Route>
            <Route path={'/Index_auditar_tarjetas'} component = {Index_auditar_tarjetas}></Route>
            <Route path={'/Index_auditar_cambio'} component = {Index_auditar_cambio}></Route>

            <Route path={'/Deportistas'} component = {Deportistas}></Route>
            <Route path={'/Ver_deportistas'} component = {Ver_deportistas}></Route>
          {
          console.log(props.name)
}
         
          { props.name != ""
         
          ? <Route path={'/Empresa'}  component = {Empresa}></Route>
          :""
}  
           <Route path={'/Register'} component = {Register}></Route>
           <Route path={'/Empresajuridica'} component = {Empresajuridica}></Route>
           <Route path={'/Ver_empresas'} component = {Ver_empresas}></Route>
           <Route path={'/Crear_torneo'} component = {Crear_torneo}></Route>
           <Route path={'/Pruebas'} component = {Pruebas}></Route>
           <Route path={'/Pruebas2'} component = {Pruebas2}></Route>
           <Route path={'/Torneo_pendiente'} component = {Torneo_pendiente}></Route>
           <Route path={'/Torneos_activos'} component = {Torneos_activos}></Route>
           <Route path={'/Grupos'} component = {Grupos}></Route>

          

        
           <Route component={Ubicacion}/>
         </Switch>


      </Router>
    </MyContextProvider>
    
  );
}

export default App;
