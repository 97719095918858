import React, {useContext,useState} from 'react'

import {MyContext} from '../../contexts/MyContext'

import LogoConfenalco from '../../img/confenalco.jpg';

import LogoBalon from '../../img/balon.png';

import Pdf from './AmigosDelPino.pdf';

//logo
import LogoTorneo1 from '../../img/escudofull.png';


import Tabla from '../../img/tabla.png';

// Importing the Login & Register Componet
import Register from './Register';

import Foto from '../../img/foto.png';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
//import Torneo_pendiente5 from './Torneo_pendiente5';



//Agregar campo dinamico
const defaultState = {
  nombre: "",
};



//Agregar campo dinamico
function Row({ onChange, onRemove, datos_adicionales}) {
 
  return (
    <div>
      
      <div class="input-group">
      <input name="datos_adicionales"
        value={datos_adicionales}
        className="form-control" id="inputSuccess"
       
       /* style={{ "background-color": !input3 && "#D3F9FF" }}
        value={input3}
        onChange={({ target }) => setInput3(target.value)}*/
        placeholder="Datos adicionales"
      />
       
       <span class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" ><b>?</b></button>
                  </span>
     
       </div> 
   <br/>
     


    
    </div>
  );
}


 function Tabla_posiciones(props) {

  //colores inputs y selects
  const [input, setInput] = useState();

  const [input2, setInput2] = useState();

// const [input3, setInput3] = useState();

const [select, setSelect] = useState();

const [select2, setSelect2] = useState();

const [select3, setSelect3] = useState();

const [select4, setSelect4] = useState();

const [select5, setSelect5] = useState();


 //agregar campo dinamico
 const [rows, setRows] = useState([defaultState]);

//Modal organizador
const [modalOrganizador, setModalOrganizador]=useState(false);

 //Modal
 const [modalNombreTorneo, setModalNombreTorneo]=useState(false);

 const [modalDatosAdicionales, setModalDatosAdicionales]=useState(false);

 

 //agregar campo dinamico
const handleOnChange = (index, name, value) => {
  const copyRows = [...rows];
  copyRows[index] = {
    ...copyRows[index],
    [name]: value
  };
  setRows(copyRows);
};


//Modal organizador

const abrirCerrarModalOrganizador=()=>{
  setModalOrganizador(!modalOrganizador);
}

//Modal

const abrirCerrarModalNombreTorneo=()=>{
  setModalNombreTorneo(!modalNombreTorneo);
}

const abrirCerrarModalDatosAdicionales=()=>{
  setModalDatosAdicionales(!modalDatosAdicionales);
}



 //agregar campo dinamico
const handleOnAdd = e => {
  e.preventDefault(); //esto previene que el form se mande.
  setRows(rows.concat(defaultState));
};
/*
 //agregar campo dinamico
const handleOnRemove = (index,e) => {
  e.preventDefault(); //esto previene que el form se mande.
  const copyRows = [...rows];
  copyRows.splice(index, 1);
  setRows(copyRows);
};
*/

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    

    if(isAuth)
    {

    return (
        
     
             
      <div className="col-sm-12">
    
              <div class="card">
                  
              <div class="card-header text-center">
                    <h3 class="card-title"><img src={LogoConfenalco} width="45px" /><img src={LogoBalon} width="45px" /> <b>LOS AMIGOS DEL PINO </b>  - Colombia – Tolima – Ibagué – Futbol - F11 – G Natural – Masculino – Dici 2021 </h3>
                    
                    <br /><h3 class="card-title"> 
  </h3>
  
                  </div>  
                 
                
                  <div class="card-body">
               <center><h3>Tabla de posiciones</h3></center>
                   
                  <form>


<br/>
    <div class="row">

    <div class="col-sm-1">

    <div class="input-group">
      
    <h5>&nbsp;&nbsp;&nbsp;&nbsp;P</h5>
          
       </div> 

       </div> 
    
       <div class="col-sm-1">
     <div class="input-group">

     <div class="input-group">
      
      <h6>&nbsp;&nbsp;&nbsp;&nbsp;Logo</h6>
            
         </div> 
          
   
        </div>
      </div>


     <div class="col-sm-3">
     <div class="input-group">

    
     <div class="input-group">
      
      <h6>&nbsp;Equipo</h6>
            
         </div> 
          
        
    
   
        </div>
      </div>
    
    
      <div class="col-sm-1">
        
      <div class="input-group">
      <div class="input-group">
      
      <h6>&nbsp;Puntos</h6>
            
         </div> 
        </div>
      </div>


      <div class="col-sm-1">
        
      <div class="input-group">
      <div class="input-group">
      
      <h6>&nbsp;PJ</h6>
            
         </div> 
        </div>
      </div>


      <div class="col-sm-1">
        
     
      <div class="input-group">
      
      <h6>&nbsp;PE </h6>
            
         </div> 
     
      </div>



      <div class="col-sm-1">
        
     
      <div class="input-group">
      
      <h6>&nbsp;PP </h6>
            
         </div> 
     
      </div>



      <div class="col-sm-1">
        
     
        <div class="input-group">
        
        <h6>&nbsp;&nbsp;GF </h6>
              
           </div> 
       
        </div>


        <div class="col-sm-1">
        
     
        <div class="input-group">
        
        <h6>&nbsp;GC </h6>
              
           </div> 
       
        </div>


        <div class="col-sm-1">
        
     
        <div class="input-group">
        
        <h6>&nbsp;&nbsp;GD </h6>
              
           </div> 
       
        </div>
     

   
      
      </div>

      <hr />

  



    <div class="row">
    <div class="col-sm-12">
   <img src={Tabla} />
    </div>

  </div>

         
<Modal isOpen={modalNombreTorneo}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Nombre del torneo</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Asigna Un Nombre al Torneo (No debe Contener Mas de 40 Caracteres)</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalNombreTorneo()}>Aceptar</button>
 </ModalFooter>
</Modal>


{/* organizador */}
<Modal isOpen={modalOrganizador}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b>Organizador / Sucursal</b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
<h3><b>Sub Nombre o Sucursal, en el caso de que la empresa tenga un mismo torneo pero distintas sucursales
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalOrganizador()}>Aceptar</button>
 </ModalFooter>
</Modal>



<Modal isOpen={modalDatosAdicionales}>
 <ModalHeader><i style={{color:'red'}}>?</i> <b> Datos adicionales </b></ModalHeader>
 <ModalBody >
   <div className="form-group">

  
   <h3><b>Para el caso de zonas geográficas no registradas en la base de datos.
</b></h3>
       
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-warning" onClick={()=>abrirCerrarModalDatosAdicionales()}>Aceptar</button>
 </ModalFooter>
</Modal>

  </form>
                  </div>
                 
                </div>
    
    
                </div>

             

                
    
    
         
         

    )

}

else if(showLogin){
    return "";
}
else{
    return <Register/>;
        
     
}
 }

export default Tabla_posiciones